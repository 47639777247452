import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './CompanyEmailVerify.module.css';
import Header from '../../../components/layout/Header';
import Button from '../../../components/element/Button';
import TextField from '../../../components/element/TextField';
import { VerifyStatus } from '../../../constants/constants';
import authService from '../../../services/authService';
import useToast from '../../../hook/useToast';
import useGlobalStore from '../../../stores/globalStore';

import closeIcon from '../../../assets/images/ic_close.png';

interface CompanyEmailVerifyProps {
  currentEmail?: string;
  onVerify?: (companyName: string, email: string) => void;
  onCancel?: () => void;
}

const CompanyEmailVerify: React.FC<CompanyEmailVerifyProps> = ({ currentEmail, onVerify, onCancel }) => {
  const toast = useToast();
  const navigator = useNavigate();
  const { mate, setMate } = useGlobalStore();
  const [companyName, setCompanyName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [verifyStatus, setVerifyStatus] = useState<VerifyStatus>(VerifyStatus.NONE);
  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState<string | null>(null);

  useEffect(() => {
    setType(searchParams.get('type'));
  }, []);

  async function next() {
    const result: boolean | undefined = await checkEmailVerifyCode();

    if(result) {
      if(onVerify) { //onVerify 메서드가 있으면 우선순위.
        onVerify(companyName, email);
      }else if(type !== null && type === 'mate') { //메이트 프로필 설정 중인 경우.
        setMate({...mate!, company_email: email});
        //navigator('/mate/linkedinInput');
        navigator('/mate/timezone');
      }
    }
  }

  async function requestEmailVerify() {
    if(type == null && companyName === '') {
      toast('error', 'Enter your company', false);  
      return;
    }

    if(email === '') {
      toast('error', 'Enter your email', false);  
      return;
    }

    if(currentEmail !== undefined && email === currentEmail) {
      toast('error', 'The email address is the same as now.', false);  
      return;
    }

    //이메일 인증 번호 전송.
    await authService.requestEmailVerify(email);
    setVerifyStatus(VerifyStatus.WAIT);
    toast('success', 'Enter the verification code', false);  
  }

  async function checkEmailVerifyCode() {
    if(email === '') return;

    if(verifyCode === '') {
      toast('error', 'Enter the verification code', false);  
      return;
    }

    const result: boolean = await authService.checkVerifyCode(email, verifyCode);
    if(!result) {
      toast('error', 'Incorrect verification code', false);  
      return false;
    }

    //이메일 인증 완료.
    setVerifyStatus(VerifyStatus.COMPLETE);
    toast('success', 'Email Verification complete', false);  
    return true;
  }
  
  function onVerifyCancel() {
    setEmail('');
    setVerifyCode('');
    setVerifyStatus(VerifyStatus.NONE);
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => verifyStatus === VerifyStatus.NONE ? navigator(-1) : onVerifyCancel()}/>
      <div className={styles['title-text']}>
      {'Work Email Verification'}
      </div>
      {verifyStatus !== VerifyStatus.NONE && <div className={styles['description-text']}>
        {'Please enter the code within 3 minutes  '}
      </div>}
      <div style={{marginTop: verifyStatus == VerifyStatus.NONE ? '2.2rem' : '3.2rem'}}/>
      {type === null && verifyStatus === VerifyStatus.NONE && <div className={styles['label-container']}>
        <label className={styles['label']}>New company name</label>
      </div>}
      {type === null && verifyStatus === VerifyStatus.NONE && <div className={styles['input-field-container']}>
        <TextField 
          name='companyName'
          type='text' 
          onClear={() => setCompanyName('')}
          maxLength={50}
          value={companyName} 
          enable={true}
          placeholder='Enter your company name'
          onChange={(event) => setCompanyName(event.target.value)}
        />
      </div>}
      {verifyStatus === VerifyStatus.NONE && <div className={styles['label-container']}>
        <label className={styles['label']}>Email</label>
      </div>}
      {verifyStatus === VerifyStatus.NONE && <div className={styles['input-field-container']}>
        <TextField 
          name='email'
          type='email' 
          onClear={() => setEmail('')}
          maxLength={50}
          value={email} 
          enable={true}
          placeholder="Enter your email"
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>}
      {verifyStatus !== VerifyStatus.NONE && <div className={styles['label-container']}>
      Verification code
      </div>}
      {verifyStatus !== VerifyStatus.NONE && <div className={styles['input-field-container']}>
        <TextField 
          name='verifyCode'
          type='number' 
          value={verifyCode} 
          enable={verifyStatus !== VerifyStatus.COMPLETE}
          maxLength={15}
          placeholder='Enter the verification code' 
          onChange={(event) => setVerifyCode(event.target.value)}
          onClear={() => setVerifyCode('')}
        />
      </div>}
      <div style={{marginTop: '2.0rem'}}/>
      <Button marginHorizontalRem={2.0} active={true} onClick={() => verifyStatus == VerifyStatus.NONE ? requestEmailVerify() : next()}>{verifyStatus == VerifyStatus.NONE ? 'Send Verification email' : 'Next'}</Button>
      {verifyStatus !== VerifyStatus.NONE && <div className={styles['resend-text']} onClick={() => requestEmailVerify()}>Resend verification code</div>}
    </div>
    );
  }
  
  export default CompanyEmailVerify;
    