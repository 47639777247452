import React, { forwardRef, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import style from './CommentsFields.module.css';
import MdSendIcon from '../../../../assets/images/ic_send_message.png';
import useToast from '../../../../hook/useToast';
import communityService from '../../../../services/communityService';
import { useParams, useNavigate } from 'react-router-dom';
import { CommunityReply } from '@/models/communityReply';
import MdDeleteIcon from '../../../../assets/images/ic_clear.png';

type CommentsFieldsProps = {
  mentionUserId: number | null; // 멘션 닉네임
  setMentionUserId: (userId: number | null) => void; // 닉네임 초기화 함수
  addReplys: (newReply: CommunityReply) => void; // 새 댓글을 추가하는 함수
};

const CommentsFields = forwardRef(
  (
    { mentionUserId, setMentionUserId, addReplys }: CommentsFieldsProps,
    ref
  ) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { id } = useParams();
    const [comment, setComment] = useState<string>('');
    const [replies, setReplies] = useState<CommunityReply[]>([]); // 댓글 배열 상태
    const mentionRef = useRef<HTMLDivElement>(null); // 멘션된 입력란을 위한 ref
    const commentInputRef = useRef<HTMLTextAreaElement>(null); // 댓글 입력란에 대한 ref 정의

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        if (commentInputRef.current) {
          commentInputRef.current.focus();
        }
      },
    }));
    useEffect(() => {
      getCommunityList(Number(id));
    }, []); // id가 변경될 때마다 다시 데이터 요청

    async function getCommunityList(id: number) {
      try {
        const response = await communityService.getTargetList(id);
        if (response) {
          setReplies(response.reply || []);
        } else {
          console.log('No data found');
        }
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    }

    useEffect(() => {
      if (mentionUserId !== null && mentionRef.current) {
        mentionRef.current.focus();
        setMentionUserId(mentionUserId); // 멘션 후 닉네임 초기화
      }
    }, [mentionUserId, setMentionUserId]);

    const replyBtn = async () => {
      // replies 배열에서 가장 높은 숫자를 가진 user_nickname을 찾음
      const highestNicknameNumber = replies.reduce((max, reply) => {
        const match = reply.user_nickname.match(/Noname(\d+)/); // "Noname" 뒤의 숫자를 추출
        const number = match ? parseInt(match[1], 10) : 0; // 숫자가 없으면 0으로 처리
        return Math.max(max, number); // 가장 큰 숫자를 추적
      }, 0);
      const user_nickname = `Noname${highestNicknameNumber + 1}`;
      const mentionId = mentionUserId || undefined;
      const communityId = Number(id);
      const reply = comment;
      const mention_user_nickname = mentionUserId
        ? `Noname${mentionUserId}`
        : '';
      // 데이터 검증
      if (!communityId || !reply) {
        toast('error', 'Please fill in reply the fields.', false);
        return;
      }

      try {
        const response = await communityService.addReply(
          user_nickname,
          communityId,
          reply,
          mentionId,
          mention_user_nickname
        );
        if (response) {
          addReplys(response); // 새 댓글을 Page 컴포넌트의 상태에 추가
          setComment('');
          setMentionUserId(null); // 멘션 초기화
          toast('success', 'Add reply success!', false);
        } else {
          toast('error', `Can't Post published`, false);
        }
      } catch (error) {
        console.log('오류 발생:', error);
      }
    };
    const handleInputChange = (e: React.FormEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;
      const htmlContent = target.innerHTML; // HTML 콘텐츠 추출
      const textContent = htmlContent
        .replace(/<br>/g, '\n') // 줄바꿈 처리
        .replace(/<div>/g, '\n') // 블록 요소 처리
        .replace(/<\/div>/g, '') // 닫는 태그 제거
        .trim(); // 불필요한 공백 제거
      setComment(textContent); // 상태 업데이트
    };

    const handleDeleteMention = () => {
      setMentionUserId(null); // 멘션 초기화
      setComment(''); // 댓글 내용 초기화
    };

    return (
      <div className={style.commentContainer}>
        {/* <div className={style.commentFieldContainer}> */}
        {mentionUserId ? (
          <div className={style.commentMentionContainer}>
            {/* 콘텐트 에디터블 */}
            <span className={style.commentMention}>
              {`@Noname${mentionUserId}`}
              <span
                className={style.editableInput}
                contentEditable
                suppressContentEditableWarning={true}
                ref={mentionRef} // 멘션 입력란에 ref 추가
                onInput={handleInputChange}
              ></span>
            </span>
            <div className={style.deleteBtn} onClick={handleDeleteMention}>
              <img src={MdDeleteIcon} alt='' />
            </div>
          </div>
        ) : (
          <div className={style.commentFieldContainer}>
            <TextareaAutosize
              placeholder='Enter your comment'
              className={style.commentInput}
              ref={commentInputRef}
              value={comment} // 상태로 관리하는 댓글 내용
              onChange={(e) => setComment(e.target.value)} // 댓글 입력 변화 처리
            />
          </div>
        )}

        {/* </div> */}

        <div className={style.sendButton} onClick={() => replyBtn()}>
          <img src={MdSendIcon} alt='Send' className={style.sendIcon} />
        </div>
      </div>
    );
  }
);

export default CommentsFields;
