import styles from './Header.module.css';
import '../../styles/fonts/Font.css';

import backIcon from '../../assets/images/ic_back.png';
import languageIcon from '../../assets/images/ic_language.png';
import settingIcon from '../../assets/images/ic_settings.png'; // 설정 아이콘 추가
import menuIcon from '../../assets/images/ic_menu.png'; // 설정 아이콘 추가
import bookmarkOn from '../../assets/images/ic_bookmark_on.png';
import bookmarkOff from '../../assets/images/ic_bookmark_off.png';
import reportIcon from '../../assets/images/ic_report.png';
import shareIcon from '../../assets/images/ic_share.png';
import closeIcon from '../../assets/images/ic_close.png';
import { useState } from 'react';
import searchIcon from '../../assets/images/ic_search.png';

interface HeaderProps {
  type?: string;
  height?: string;
  title?: string;
  customTitle?: string;
  useLogoTitle?: boolean;
  useBgColor?: boolean;
  useBackButton?: boolean;
  useSearchButton?: boolean;
  useLanguageButton?: boolean;
  useSettingButton?: boolean; // 설정 버튼 사용 여부 추가
  useReportButton?: boolean; // 신고 버튼 사용 여부 추가
  useMenuButton?: boolean; // 신고 버튼 사용 여부 추가
  useCloseButton?: boolean; // 신고 버튼 사용 여부 추가
  useBookmarkButton?: boolean; // 북마크 버튼 사용 여부 추가
  useShareButton?: boolean; // 공유 버튼 사용 여부 추가
  actionType?: 'none' | 'signup' | 'signin' | 'icon' | 'publish';
  actionTitle?: any;
  secondaryTitle?: any;
  favorite?: boolean;

  onBack?(): void;
  onAction?(): void;
  onSearchAction?(): void;
  onSecondaryAction?(): void;
  onReportAction?(): void;
  onMenuAction?(): void;
  onSettingAction?(): void; // 설정 버튼 클릭 핸들러
}

function Header({
  type = 'home',
  height = '4.0rem',
  title = '',
  useLogoTitle = false,
  useBackButton = true,
  useBgColor = false,
  useMenuButton = false,
  useSearchButton = false,
  useReportButton = false,
  useBookmarkButton = false,
  useShareButton = false,
  favorite = false,
  useCloseButton = false,
  useSettingButton = false, // 기본값 false
  actionType = 'none',
  actionTitle,
  secondaryTitle = undefined,
  customTitle = undefined,
  onBack,
  onAction,
  onSearchAction,
  onReportAction,
  onMenuAction,
  onSecondaryAction,
  onSettingAction, // 설정 버튼 클릭 핸들러 추가
}: HeaderProps) {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const isBookmark = () => {
    setIsBookmarked(!isBookmarked); // 상태 토글
  };
  return (
    <header
      className={styles.header}
      style={{
        height: height,
        backgroundColor: useBgColor ? 'var(--color-white100)' : '',
      }}
    >
      {useBackButton === true && (
        <div className={styles['back-button']}>
          <img
            src={backIcon}
            alt=''
            onClick={onBack}
          />
        </div>
      )}
      {useCloseButton === true && (
        <div className={styles['back-button']}>
          <img
            src={closeIcon}
            alt=''
            onClick={onBack}
          />
        </div>
      )}
      {useLogoTitle === true && <div className={styles['logo']}>Nailedit</div>}
      {customTitle !== undefined && <div className={styles['customTitle']}>{customTitle}</div>}
      <div className={styles['title']}>{title}</div>
      <div style={{ marginLeft: 'auto' }} />
      {useSettingButton === true && ( // 설정 버튼 추가
        <div
          className={styles['language-button']}
          onClick={onSettingAction}
        >
          <img
            src={settingIcon}
            alt=''
          />
        </div>
      )}
      {useReportButton === true && ( // 신고 버튼 추가
        <div
          className={styles['language-button']}
          onClick={onReportAction}
        >
          <img
            src={reportIcon}
            alt=''
          />
        </div>
      )}
      {useBookmarkButton === true && ( // 북마크 버튼 추가
        <div
          className={styles['language-button']}
          onClick={isBookmark}
        >
          <img
            src={favorite ? bookmarkOn : bookmarkOff}
            alt='Bookmark'
          />
        </div>
      )}
      {useShareButton === true && ( // 공유 버튼 추가
        <div
          className={styles['language-button']}
          onClick={onSettingAction}
        >
          <img
            src={shareIcon}
            alt=''
          />
        </div>
      )}
      {useMenuButton === true && ( // 공유 버튼 추가
        <div
          className={styles['language-button']}
          onClick={onMenuAction}
        >
          <img
            src={menuIcon}
            alt=''
          />
        </div>
      )}

      {actionType === 'signup' && actionTitle !== '' && (
        <div
          className={styles['signup-button']}
          onClick={onAction}
        >
          {actionTitle}
        </div>
      )}
      {actionType === 'signin' && actionTitle !== '' && (
        <div
          className={styles['login-button']}
          onClick={onAction}
        >
          {actionTitle}
        </div>
      )}
      {actionType === 'icon' && actionTitle !== '' && (
        <div
          style={{ marginLeft: 'auto' }}
          onClick={onAction}
        >
          {actionTitle}
        </div>
      )}
      {actionType === 'publish' && actionTitle !== '' && (
        <div
          style={{ marginRight: '2rem', fontSize: '1.5rem', color: '#00B76A' }}
          onClick={onAction}
        >
          {actionTitle}
        </div>
      )}
      {secondaryTitle !== undefined && (
        <div
          style={{ marginLeft: '1.6rem', marginRight: '2.0rem' }}
          onClick={onSecondaryAction}
        >
          {secondaryTitle}
        </div>
      )}
      {useSearchButton === true && ( // 공유 버튼 추가
        <div
          className={styles['language-button']}
          onClick={onSearchAction}
        >
          <img
            src={searchIcon}
            alt=''
          />
        </div>
      )}
    </header>
  );
}

export default Header;
