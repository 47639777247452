import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './CalculationRequestAddCompleted.module.css';
import completedIcon from '../../assets/images/ic_paypal_upload.png';

function CalculationRequestAddCompleted() {
  const navigate = useNavigate();
  const location = useLocation();
  const withdrawlData = location.state;

  const toRequest = () => {
    navigate('/calculation/request');
  };

  const goCalculation = () => {
    navigate('/calculation');
  };
  // const toWtihdraw = () => {
  //   navigate('/calculation/request/withdraw')
  // }

  return (
    <>
      <div className={styles['container']}>
        <Header onBack={() => navigate(-1)} />
        <div className={styles['title']}>{`${
          (withdrawlData.withdrawal_method === 'PAYPAL' && 'PayPal') || 'Transfer'
        } account registered!`}</div>
        <div className={styles['text']}>{'You can now withdraw earnings to the account'}</div>

        <div className={styles['img-container']}>
          <img
            src={completedIcon}
            alt=''
          />
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={true}
          onClick={goCalculation}
          marginBottom={0.8}
          activeColor='white'
          activeTextColor='black'
          activeBorderColor='var(--color-black_transparent_12)'
        >
          Request a Withdrawal
        </Button>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={true}
          onClick={goCalculation}
          activeColor='white'
          activeTextColor='black'
          activeBorderColor='var(--color-black_transparent_12)'
        >
          Check My Earnings
        </Button>
      </div>
    </>
  );
}

export default CalculationRequestAddCompleted;
