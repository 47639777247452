'use client';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import useGlobalStore from '../../stores/globalStore';
import CommunityCategory from './components/CommunityCategory';
import CommunityTabs from './components/CommunityTabs';
import CommunityNotice from './components/CommunityNotice';
import Footer from '../../components/layout/Footer';
import CommunityPosts from './components/CommunityPosts';
import styles from './page.module.css'; // Importing the CSS module
import bellIcon from '../../assets/images/ic_bell.png';
import FloatingButton from './components/FloatingButton';
import userService from '../../services/userService';
import AuthService from '../../services/authService';

function Page() {
  const navigate = useNavigate();
  const [alarmCount, setAlarmCount] = useState(0); // 알림 갯수 상태 추가
  const { isAuthenticated, setAuthenticated, user, setUser, resetStore } = useGlobalStore();
  const [selectedTab, setSelectedTab] = useState(''); // 탭 상태 추가
  const [selectedCategory, setSelectedCategory] = useState(''); // 탭 상태 추가
  const toAlarm = () => {
    navigate('/my/notification');
  };

  useEffect(() => {
    loadNotificationCount();
    tokenCheck();
  }, []);

  async function loadNotificationCount() {
    const count: number = await userService.getNotificationCount();
    setAlarmCount(count);
  }

  async function tokenCheck() {
    //토큰 유효성 체크.
    const token = localStorage.getItem('authToken');
    if (token != undefined) {
      try {
        const userData = await AuthService.myprofile();
        if (userData && userData.user) {
          setAuthenticated(true);
          setUser(userData.user); // user를 상태로 설정
        } else {
          localStorage.setItem('authToken', '');
          resetStore();
        }
      } catch (err) {
        localStorage.setItem('authToken', '');
        resetStore();
      }
    } else {
      resetStore();
    }
  }

  return (
    <>
      <div
        className={styles.pageContainer}
        style={{ position: 'relative' }}
        id='container'
      >
        {/* Fixed header */}
        <Header
          useBackButton={false}
          customTitle='Community'
          actionType={isAuthenticated == true ? 'icon' : 'signin'}
          actionTitle={isAuthenticated == true ? '' : 'Sign In / Sign Up'}
          onAction={isAuthenticated == true ? undefined : () => navigate('/auth/signIn')}
          secondaryTitle={
            isAuthenticated == true ? (
              <div className={styles['alarm']}>
                <img
                  src={bellIcon}
                  alt='Notification'
                  style={{ margin: 0 }}
                />
                {alarmCount > 0 && <div className={styles['alarm-count']}>{alarmCount}</div>}
              </div>
            ) : undefined
          }
          onSecondaryAction={isAuthenticated == true ? toAlarm : undefined}
        />

        {/* Content section that scrolls */}
        <div className={styles.contentContainer}>
          <CommunityCategory
            selectedCategory={selectedCategory}
            onCategoryChange={setSelectedCategory}
          />
          <CommunityTabs
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
          />
          {/* <CommunityNotice /> 
        <CommunityNotice /> */}

          <div className={styles.postsContainer}>
            <CommunityPosts
              selectedCategory={selectedCategory}
              selectedTab={selectedTab}
            />
          </div>
        </div>
        <div className={styles.btnContainer}>{user !== null && <FloatingButton />}</div>

        {/* Fixed footer */}
      </div>
      <Footer />
    </>
  );
}

export default Page;
