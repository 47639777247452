import React, { useEffect, useState } from 'react';
import { Sheet } from 'react-modal-sheet';
import Header from '../../components/layout/Header';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../../assets/images/ic_close.png';
import styles from './CalculationOption.module.css';
import Button from '../../components/element/Button';
import arrowIcon from '../../assets/images/ic_arrow_right.png';
import { UserWithdrawalMethod } from '../../models/userWithdrawalMethod';
import settlementService from '../../services/settlementService';
import settlementsData from '../../assets/data/settlement.json';

function CalculationOption() {
  const navigate = useNavigate();
  const [methodList, setMethodList] = useState<UserWithdrawalMethod[]>([]);
  const [method, setMethod] = useState<string[]>([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState<number | null>(null);
  useEffect(() => {
    getWithdrawalMethodData();
  }, [method]);

  const deleteWithdrawalMethod = async (id: number) => {
    setOpenDelete(false);
    await settlementService.deleteWithdrawalMethod(id);
    loadMethod();
  };

  const loadMethod = async () => {
    setMethod(await settlementService.withdrawalMethodList());
  };

  const getWithdrawalMethodData = async () => {
    try {
      const withdrawData = await settlementService.withdrawalMethodList();
      if (withdrawData) {
        // settlementsData의 데이터를 withdrawalMethodList와 비교하여 필터링하고 변경
        const updatedMethods = withdrawData.map((method: UserWithdrawalMethod) => {
          const settlement = settlementsData.find(
            (settlement: any) => settlement.abb === method.country_code
          );

          if (settlement && method.country_code && method.withdrawal_method !== 'PAYPAL') {
            return {
              ...method,
              withdrawal_method: settlement.transferMethod,
            };
          }
          return method;
        });

        setMethodList(updatedMethods);
      } else {
        console.log('error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const toEdit = (id: number) => {
    navigate(`/calculation/request/select/${id}`);
  };
  const toAdd = () => {
    navigate('/calculation/request');
  };

  return (
    <div
      className={styles['container']}
      style={{ position: 'relative' }}
      id='container'
    >
      <Header onBack={() => navigate(-1)} />
      {/* <div
        className={styles['link']}
        onClick={toModify}
      >
        {'Modify'}
      </div> */}
      <div className={styles['title']}>{'Currently Registered Methods'}</div>
      {methodList?.map((method, index) => (
        <div
          className={styles['option-container']}
          key={index}
        >
          <div className={styles['option-item']}>
            <span>
              <span>
                <strong>{method.withdrawal_method}</strong>
              </span>
            </span>
            <div className={styles['option-state']}>
              {method.status === 'AVAILABLE' ? 'Available' : ''}
            </div>
          </div>
          <div className={styles['option-text']}>{method.account_number}</div>
          <div className={styles['option-btn']}>
            <div
              className={styles['btn']}
              onClick={() => {
                setSelectedMethodId(method.id || 0);
                setOpenDelete(true);
              }}
            >
              Delete
            </div>
            <div className={styles['line']}></div>
            <div
              className={styles['btn']}
              onClick={() => toEdit(method.id!)}
            >
              Edit
            </div>
          </div>
        </div>
      ))}

      <div className={styles['option-description']}>
        {
          'The default payout method is at the top.\r\nTo change the order, please click "Edit" at the top.'
        }
      </div>
      <div
        className={styles['option-add']}
        onClick={toAdd}
      >
        {'Add Payout Receiving Method'}
        <img
          src={arrowIcon}
          alt=''
        />
      </div>
      <div className={styles['option-description']}>
        {
          'By default, if the payout cannot be withdrawn using\r\nthe primary method, you agree that Nailedit will attempt\r\nto withdraw using other methods in the order listed above.'
        }
      </div>
      <Sheet
        isOpen={openDelete}
        detent='content-height'
        onClose={() => setOpenDelete(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '17.7rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Remove this payout method? </span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenDelete(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <div className={styles['text']}>
                {"You'll need to add it again later if removed."}
              </div>
              <div className={styles['button-container']}>
                <Button
                  width='100%'
                  inactiveTextColor='black'
                  height='4.8rem'
                  onClick={() => setOpenDelete(false)}
                >
                  Cancel
                </Button>
                <Button
                  active={true}
                  width='100%'
                  height='4.8rem'
                  onClick={() => deleteWithdrawalMethod(selectedMethodId!)}
                >
                  Delete
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenDelete(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
}

export default CalculationOption;
