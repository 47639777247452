import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './DeniedComplete.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';

import deny from '../../assets/images/ic_deny_meeting.png';

function DeniedComplete() {
  const navigate = useNavigate();
  const toast = useToast();
  const toMeetingList = () => {
    navigate('/meeting/myMeeting');
  };
  return (
    <>
      <div className={styles['container']}>
        <Header onBack={() => navigate(-1)} />
        <div className={styles['title-text']}>You've declined a meeting</div>
        <div className={styles['title-hint-text']}>
          {
            'We’ll let the applicant know that you’re unable\r\nto participate due to personal reasons.'
          }
        </div>
        <div className={styles['image']}>
          <img
            src={deny}
            alt=''
          />
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={700}
          active={true}
          onClick={toMeetingList}
        >
          View My Meeting
        </Button>
      </div>
    </>
  );
}

export default DeniedComplete;
