import React, { useState } from 'react';
import styles from './MeetingReviewCoffeechat.module.css';
import Button from '../../components/element/Button';

import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import ReviewSelect from './components/ReviewSelect';
import TextArea from '../../components/element/TextArea';
import { ProductReview } from '../../models/productReview';
import useToast from '../../hook/useToast'; // 토스트 훅 추가
import { Mate } from '../../models/mate';

function MeetingReviewCoffeechat() {
  const location = useLocation();
  const navigate = useNavigate();
  const [usefulAnswerReason1, setUsefulAnswerReason1] = useState<string>('');
  const [usefulAnswerReason2, setUsefulAnswerReason2] = useState<string>('');
  const [customTopic, setCustomTopic] = useState<string>('');
  const [selectedAnswer, setSelectedAnswer] = useState<string[]>([]); // 배열로 변경
  const toast = useToast(); // 토스트 훅 초기화

  const productReview = location.state.productReview;
  const meetingData = location.state.meetingData;

  const handleSelect = (text: string) => {
    setSelectedAnswer((prev) =>
      prev.includes(text) ? prev.filter((answer) => answer !== text) : [...prev, text]
    );
  };

  // 리뷰 작성 API 호출 없이 선택한 답변과 이유만 업데이트
  const submitReview = async () => {
    if (!selectedAnswer) {
      toast('error', 'Select topics for review', false);
      return;
    } // 선택되지 않으면 아무것도 안 함

    try {
      // productReview에서 필요한 데이터만 추출하고, 유용한 답변 및 이유 추가
      const updatedAnswer: ProductReview = {
        ...productReview, // 기존 리뷰 데이터 유지
        useful_answer: selectedAnswer,
        // useful_answer_selected_reason가 Json , string[] 으로 되지 않아서 임시로 join
        useful_answer_selected_reason: [usefulAnswerReason1, usefulAnswerReason2, customTopic],
      };
      // 다음 페이지로 업데이트된 데이터 전달
      toNext(updatedAnswer); // 상태 업데이트 후, 다음 페이지로 이동
    } catch (err) {
      toast('error', 'Something went wrong', false); // 실패 메시지 출력
    }
  };

  const toNext = (productUsefulAnswer: ProductReview) => {
    navigate('/meeting/review/last', { state: productUsefulAnswer });
  };

  return (
    <div>
      <div className={styles['container']}>
        <Header onBack={() => navigate(-1)} />

        <div className={styles['label-container']}>{'How was your Coffee Chat?'}</div>
        <div className={styles['text']}>
          {'Select which topic response was most helpful for you'}
        </div>
        <>
          <ReviewSelect
            text={meetingData.mate.chat_subject[0]}
            selected={selectedAnswer === meetingData.mate.chat_subject[0]}
            onSelect={(active, text, time) => handleSelect(text)}
          />
          {selectedAnswer.includes(meetingData.mate.chat_subject[0]) && (
            <>
              <div className={styles['subText']}>{'Tell us how this helped you'}</div>
              <TextArea
                placeholder='Share your thoughts'
                useBgColor={true}
                width='unset'
                marginLeft='1.6rem'
                marginRight='1.6rem'
                height='auto'
                radius={16}
                useCount={true}
                maxLength={500}
                value={usefulAnswerReason1}
                autoResize={true}
                onChange={(e) => setUsefulAnswerReason1(e.target.value)}
              />
            </>
          )}
          <ReviewSelect
            text={meetingData.mate.chat_subject[1]}
            selected={selectedAnswer === meetingData.mate.chat_subject[1]}
            onSelect={(active, text, time) => handleSelect(text)}
          />
          {selectedAnswer.includes(meetingData.mate.chat_subject[1]) && (
            <>
              <div className={styles['subText']}>{'Tell us how this helped you'}</div>
              <TextArea
                placeholder='Share your thoughts'
                useBgColor={true}
                width='unset'
                marginLeft='1.6rem'
                marginRight='1.6rem'
                height='auto'
                radius={16}
                useCount={true}
                maxLength={500}
                value={usefulAnswerReason2}
                autoResize={true}
                onChange={(e) => setUsefulAnswerReason2(e.target.value)}
              />
            </>
          )}
        </>
        <div onClick={() => handleSelect('Custom Topic')}>
          <TextArea
            placeholder='Custom Topic'
            useBgColor={true}
            width='unset'
            marginLeft='1.6rem'
            marginRight='1.6rem'
            height='auto'
            radius={16}
            selected={selectedAnswer.includes('Custom Topic')} // 수정된 부분
            useCount={true}
            maxLength={500}
            value={customTopic}
            onChange={(e) => setCustomTopic(e.target.value)}
            autoResize={true}
          />
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={1.6}
          active={!!selectedAnswer}
          onClick={submitReview}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default MeetingReviewCoffeechat;
