import React, { useState } from 'react';
import styles from './CommunityCategory.module.css'; // Ensure your CSS file is correctly imported
import categoryAllIcon from '../../../assets/images/ic_category_all.png'; // Replace with the actual path to the icon
import nationData from '../../../assets/data/nation.json';
import { Sheet } from 'react-modal-sheet';
import closeIcon from '../../../assets/images/ic_close.png';

interface CommunityCategoryProps {
  selectedCategory: string;
  onCategoryChange: (category: string) => void;
}

const CategoryBar: React.FC<CommunityCategoryProps> = ({ selectedCategory, onCategoryChange }) => {
  const categories = ['', 'US', 'JP', 'AT', 'CA', 'SG']; // Your categories
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false); // 바텀 시트
  const [selectedItems, setSelectedItems] = useState<string[]>([]); // 국가 리스트
  function handleCategoryClick(nation: string) {
    setSelectedItems(
      nationData
        .filter(
          (item) =>
            item.use === true &&
            item.disableWhenDuplicate === false &&
            item.showMeetingByLocation === true
        )
        .map((item) => item.abb)
    );
    setIsOpenBottomSheet(true);
  }

  const handleItemSelect = (category: string) => {
    onCategoryChange(category); // 카테고리 변경
    setIsOpenBottomSheet(false); // 바텀 시트 닫기
  };

  return (
    <div className={styles['category-bar']}>
      <img
        src={categoryAllIcon}
        alt='All Categories'
        onClick={() => handleCategoryClick('All')}
      />
      {categories.map((category) => (
        <div
          key={category}
          className={`${styles['category-item']} ${
            selectedCategory === category ? styles['active'] : ''
          }`}
          onClick={() => onCategoryChange(category)}
        >
          <div className={selectedCategory === category ? styles['category-item-active'] : ''}>
            {(category === '' && 'All') ||
              (category === 'US' && 'USA') ||
              (category === 'JP' && 'Japan') ||
              (category === 'AT' && 'Australia') ||
              (category === 'CA' && 'Canada') ||
              (category === 'SG' && 'Singapore')}
          </div>
          {selectedCategory === category && <div className={styles['category-active']}></div>}
        </div>
      ))}
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setIsOpenBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.6rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-terms-title']}>Select Country</div>
              <img
                src={closeIcon}
                alt='Close'
                onClick={() => setIsOpenBottomSheet(false)} // 닫기 버튼
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content-container']}>
                {/* 'All' 선택 항목 */}
                <div
                  className={`${styles['sheet-terms-content']} ${
                    selectedCategory === '' ? styles['circle-active'] : ''
                  }`}
                  onClick={() => handleItemSelect('')}
                >
                  <div className={styles['circle']}></div>
                  <span>All</span>
                </div>
                {/* 국가 목록 */}
                {selectedItems.map((abb, index) => {
                  // 국가 약어(abb) 값에 맞는 국가 이름을 찾는 과정
                  const country = nationData.find((item) => item.abb === abb);
                  return (
                    <div
                      key={index}
                      className={`${styles['sheet-terms-content']} ${
                        selectedCategory === abb ? styles['circle-active'] : ''
                      }`}
                      onClick={() => handleItemSelect(abb)} // abb 값으로 카테고리 변경
                    >
                      <div className={styles['circle']}></div>
                      <span>
                        {country ? country.nation : abb} {/* 국가 이름(nation) 표시 */}
                      </span>
                    </div>
                  );
                })}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setIsOpenBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
};

export default CategoryBar;
