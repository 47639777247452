import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchBar from '../../components/element/SearchBar';
import SelectBox from '../../components/element/SelectBox';
import styles from './SearchResult.module.css';
import { Sheet } from 'react-modal-sheet';
import { Product } from '../../models/product';
import userService from '../../services/userService';
import mateService from '../../services/mateService';
import useDebounce from '../../utils/debounce';
import { ProductType } from '../../constants/constants';
import categoryType from '../../assets/data/display_type_kr.json';
import nationData from '../../assets/data/nation.json';
import jobData from '../../assets/data/job_en.json';
import languagesData from '../../assets/data/languages.json';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';

import SearchResultCard from './components/SearchResultCard';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import useGlobalStore from '../../stores/globalStore';
import closeIcon from '../../assets/images/ic_close.png';
import { Mate } from '../../models/mate';

const highlightedText = (text: string, query: string) => {
  if (query !== '' && text.includes(query)) {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));

    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <span
              className={styles['search-highlight-text']}
              key={index}
            >
              {part}
            </span>
          ) : (
            <span className={styles['search-general-text']}>{part}</span>
          )
        )}
      </>
    );
  }

  return text;
};

function SearchResult() {
  const { mate } = useGlobalStore();
  const [isOpenNationBottomSheet, setOpenNationBottomSheet] = useState(false);
  const [isOpenJobBottomSheet, setOpenJobBottomSheet] = useState(false);
  const [isOpenSortBottomSheet, setOpenSortBottomSheet] = useState(false);
  const [isOpenLanguagesBottomSheet, setOpenLanguagesBottomSheet] = useState(false);
  const [selectedNation, setSelectedCountryCode] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<string | null>(null);
  const [selectedLanguages, setSelectedLanguages] = useState<string | null>(null);
  const [selectedSortOption, setSelectedSortOption] = useState<string | null>('review');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchText, setSearchText] = useState<string>('');
  const [searchCountry, setSearchCountry] = useState<string>('');
  const loaderRef = useRef<HTMLDivElement | null>(null); // 로딩 요소 참조
  const [currentProductTypeTab, setCurrentProductTypeTab] = useState<ProductType>(
    ProductType.COFFEECHAT
  );
  const [resultList, setResultList] = useState<string[]>([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const debounceText = useDebounce(searchText, 300);

  const [searchData, setSearchData] = useState<Product[]>([]);

  const [text, setText] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [count] = useState<number>(10);
  // 파라미터 language
  const [languages, setLanguages] = useState<string>('');
  const [sortType, setSortType] = useState<string>('');
  const [jobs, setJobs] = useState<string>('');
  // languages.json languages
  const [countryCode, setCountryCode] = useState<string>('');
  const [newDataLength, setNewDataLength] = useState(0); // 새로 추가된 데이터의 길이 상태

  useEffect(() => {
    if (searchData.length < 5) {
      return; // searchData의 길이가 5개 미만일 경우, observer 설정을 건너뜁니다.
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && page && newDataLength >= 10) {
            handlePage(page + 1); // 새로운 페이지의 데이터를 요청
          }
        });
      },
      {
        root: null,
        threshold: 0.8,
      }
    );

    const timer = setTimeout(() => {
      if (loaderRef.current) {
        observer.observe(loaderRef.current); // 로딩 요소를 관찰
      }
    }, 1000); // 1초 지연

    // clean-up
    return () => {
      clearTimeout(timer); // 타이머가 설정된 경우 클리어
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current); // observer 해제
      }
    };
  }, [page, newDataLength]);

  useEffect(() => {
    const text = queryParams.get('text') ?? '';
    const currentProductTypeTab =
      (queryParams.get('productType') as ProductType) ?? ProductType.COFFEECHAT;
    const sortType = queryParams.get('sortType') ?? 'review';
    const languages = queryParams.get('language') ?? '';
    const jobs = queryParams.get('job') ?? '';
    const countryCode = queryParams.get('countryCode') ?? '';
    setPage(1); // page 값을 초기화하여 처음에 1로 시작하게 설정
    searchResult(text, 1, count, currentProductTypeTab, countryCode, jobs, languages, sortType);
  }, [location.search]); // location.search가 변경될 때마다 실행

  async function searchResult(
    text: string,
    page: number,
    count: number,
    currentProductTypeTab: ProductType,
    countryCode: string,
    job: string,
    language: string,
    sortType: string
  ) {
    setCountryCode(countryCode);
    setCurrentProductTypeTab(currentProductTypeTab);
    setJobs(job);
    setLanguages(language);
    setSortType(sortType); // URL에서 받은 sortType을 state에 반영
    try {
      const searchList = await mateService.getSearchResult(
        text,
        page,
        count,
        currentProductTypeTab,
        countryCode,
        job,
        language,
        sortType
      );

      if (searchList && searchList.length > 0) {
        if (page === 1) {
          // 페이지 1일 때는 기존 데이터를 덮어쓰기
          setSearchData(searchList);
          setNewDataLength(searchList.length); // 첫 페이
        } else {
          // 중복 체크 후 기존 데이터에 새로운 데이터를 추가
          setSearchData((prevSearchData) => {
            const newResults = searchList.filter(
              (item: Product) => !prevSearchData.some((prevItem) => prevItem.id === item.id)
            );
            setNewDataLength(newResults.length); // 새로
            return [...prevSearchData, ...newResults];
          });
        }
      } else {
        setSearchData([]); // 데이터가 없을 경우 빈 배열 처리
      }
    } catch (err) {
      console.log('Search error:', err);
      setSearchData([]); // 에러 발생 시 빈 배열 처리
      setNewDataLength(0); // 추가된 데이터가 없으므로 길이 0 설정
    }
  }

  function keyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleText(searchText);
    }
  }
  function keyDownCountry(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
    }
  }

  async function goMateList(text: string) {
    // 히스토리 저장
    await userService.addHistory(text);
    alert(text); // 텍스트 표시
  }

  const handleText = (searchText: string) => {
    if (searchText !== text) {
      searchResult(
        searchText,
        1,
        count,
        currentProductTypeTab,
        countryCode,
        jobs,
        languages,
        sortType
      );
      setText(searchText);
    }
  };
  // 무한스크롤
  const handlePage = (newPage: number) => {
    if (newPage > 0 && newPage !== page) {
      // newPage가 유효한지 확인
      searchResult(
        text,
        page + 1,
        count,
        currentProductTypeTab,
        countryCode,
        jobs,
        languages,
        sortType
      );
      setPage(newPage);
    }
  };

  const handleLanguages = (lang: string) => {
    if (lang !== languages) {
      searchResult(text, 1, count, currentProductTypeTab, countryCode, jobs, lang, sortType);
      setSelectedLanguages(lang);
      setLanguages(lang);
    }
  };
  const handleJobs = (job: string) => {
    if (job !== jobs) {
      searchResult(text, 1, count, currentProductTypeTab, countryCode, job, languages, sortType);
      setJobs(job);
      setSelectedJob(job);
    }
  };
  const handleCountry = (country: string) => {
    if (country !== countryCode) {
      searchResult(text, 1, count, currentProductTypeTab, country, jobs, languages, sortType);
      setCountryCode(country);
      setSelectedCountryCode(country);
    }
    setOpenNationBottomSheet(false);
  };

  const handleCategoryClick = (productType: ProductType) => {
    searchResult(text, 1, count, productType, countryCode, jobs, languages, sortType);
    setCurrentProductTypeTab(productType); // 카테고리 클릭 시 상태 업데이트
  };

  const handleSortType = (option: string) => {
    if (option !== sortType) {
      // sortType이 이미 선택된 값이면 변경하지 않도록 처리

      searchResult(text, 1, count, currentProductTypeTab, countryCode, jobs, languages, option);
      setSelectedSortOption(option);
    }
  };

  const bookmarkBtn = (id: Mate) => {
    async function bookmark() {
      if (!id.user_id) {
        console.log('Cannot add bookmark: User ID not defined');
        return;
      }
      const result: boolean = isBookmarked
        ? await userService.deleteMateFavorite(id.id!)
        : await userService.addMateFavorite(id.user_id);

      if (result) {
        setSearchData((prevData) =>
          prevData.map((item) =>
            item.mate?.user_id === id.user_id
              ? { ...item, favorite: !isBookmarked } // 북마크가 추가되면 true, 삭제되면 false
              : item
          )
        );
        setIsBookmarked((prevState) => !prevState); // 북마크 상태 업데이트
      } else {
      }
    }

    bookmark(); // bookmark 함수 호출
  };

  const goDetail = (id: number | undefined) => {
    const mateDetailData = searchData.find((data) => data.id === id);
    navigate(`/mate/detail/${id}`);
  };
  const currentSortTitle =
    categoryType.find((item) => item.option === sortType)?.title || 'Most Reviewed';

  return (
    <div
      style={{ position: 'relative' }}
      id='container'
      className={styles['container']}
    >
      <SearchBar
        onBack={() => navigate(-1)}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onClear={() => setSearchText('')}
        onKeyDown={(e) => keyDownHandler(e)}
        placeholder='Enter your search'
      />

      <div className={styles['category-bar']}>
        {currentProductTypeTab === null && (
          <div className={styles['category-item']}>
            <div className={styles['category-item-active']}>Select a category</div>
          </div>
        )}
        <div
          className={styles['category-item']}
          onClick={() => handleCategoryClick(ProductType.COFFEECHAT)}
        >
          <div
            className={
              currentProductTypeTab === ProductType.COFFEECHAT ? styles['category-item-active'] : ''
            }
          >
            Coffee Chat
          </div>
          <div
            className={
              currentProductTypeTab === ProductType.COFFEECHAT ? styles['category-active'] : ''
            }
          />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => handleCategoryClick(ProductType.INTERVIEW)}
        >
          <div
            className={
              currentProductTypeTab === ProductType.INTERVIEW ? styles['category-item-active'] : ''
            }
          >
            Interview Practice
          </div>
          <div
            className={
              currentProductTypeTab === ProductType.INTERVIEW ? styles['category-active'] : ''
            }
          />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => handleCategoryClick(ProductType.REVIEW)}
        >
          <div
            className={
              currentProductTypeTab === ProductType.REVIEW ? styles['category-item-active'] : ''
            }
          >
            Resume Review
          </div>
          <div
            className={
              currentProductTypeTab === ProductType.REVIEW ? styles['category-active'] : ''
            }
          />
        </div>
      </div>
      <div className={styles['sort-bar']}>
        <div
          className={styles['sort-item']}
          onClick={() => setCurrentProductTypeTab(ProductType.COFFEECHAT)}
        >
          <SelectBox
            paddingLeft='1.2rem'
            paddingRight='0.6rem'
            borderRadius='6px'
            gap='6px'
            height={'3.3rem'}
            name='internationalNumber'
            type='text'
            value={countryCode ? countryCode : 'Country'}
            borderColor='#00000000'
            backgroundColor='#F1F2F5'
            fontSize='1.4rem'
            fontWeight={600}
            arrowSize='1.6rem'
            onAction={() => setOpenNationBottomSheet(true)}
          />
        </div>
        <div
          className={styles['sort-item']}
          onClick={() => setCurrentProductTypeTab(ProductType.COFFEECHAT)}
        >
          <SelectBox
            paddingLeft='1.2rem'
            paddingRight='0.6rem'
            borderRadius='6px'
            gap='6px'
            height={'3.3rem'}
            name='internationalNumber'
            type='text'
            value={jobs ? jobs : 'Job Categories'}
            borderColor='#00000000'
            backgroundColor='#F1F2F5'
            fontSize='1.4rem'
            fontWeight={600}
            arrowSize='1.6rem'
            onAction={() => setOpenJobBottomSheet(true)}
          />
        </div>
        <div
          className={styles['sort-item']}
          onClick={() => setCurrentProductTypeTab(ProductType.COFFEECHAT)}
        >
          <SelectBox
            paddingLeft='1.2rem'
            paddingRight='0.6rem'
            borderRadius='6px'
            gap='6px'
            height={'3.3rem'}
            name='internationalNumber'
            type='text'
            value={languages ? languages : 'Language'}
            borderColor='#00000000'
            backgroundColor='#F1F2F5'
            fontSize='1.4rem'
            fontWeight={600}
            arrowSize='1.6rem'
            onAction={() => setOpenLanguagesBottomSheet(true)}
          />
        </div>
        <div
          className={styles['sort-item']}
          onClick={() => setCurrentProductTypeTab(ProductType.COFFEECHAT)}
        >
          <SelectBox
            paddingLeft='1.2rem'
            paddingRight='0.6rem'
            borderRadius='6px'
            gap='16px'
            height={'3.3rem'}
            name='internationalNumber'
            type='text'
            value={currentSortTitle}
            borderColor='#00000000'
            backgroundColor='#F1F2F5'
            fontSize='1.4rem'
            fontWeight={600}
            arrowSize='1.6rem'
            onAction={() => setOpenSortBottomSheet(true)}
          />
        </div>
      </div>
      <div className={styles['result-list']}>
        {searchData.map((result) => (
          <>
            <SearchResultCard
              key={result.mate?.id}
              nickname={result.user!.nickname}
              annual={result.user!.annual}
              company={result.user!.company}
              job={result.user!.job ?? ''}
              profileImage={
                result.mate!.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${result.mate!.profile_image}`
                  : profileDefaultIcon
              }
              introduceMessage={result.user!.introduce_message || 'No introduction yet'}
              count_total={result.mate!.buyer_count_total!}
              count_coffeeChat={result.mate!.buyer_count_coffeechat!}
              count_interview={result.mate!.buyer_count_interview!}
              count_resume={result.mate!.buyer_count_resume!}
              price={result.price!}
              mateId={result.mate?.user_id}
              favorite={result.favorite}
              onClick={() => bookmarkBtn(result.mate!)} // mate 객체를 bookmarkBtn 함수에 전달
              goDetail={() => goDetail(result.mate?.id)}
            />
          </>
        ))}
        <div
          style={{ height: ' 1px' }}
          ref={loaderRef}
        ></div>
      </div>

      {resultList.length > 0 &&
        resultList.map((e) => (
          <div
            className={styles['search-result-item']}
            onClick={() => goMateList(e)}
          >
            {highlightedText(e, searchText)}
          </div>
        ))}

      {/* 모달 시트 */}
      <Sheet
        isOpen={isOpenNationBottomSheet}
        detent='content-height'
        onClose={() => setOpenNationBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '41.1rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Country</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenNationBottomSheet(false)}
              />
            </div>
            <SearchBar
              value={searchCountry}
              onChange={(e) => setSearchCountry(e.target.value)}
              onClear={() => setSearchCountry('')}
              placeholder='Search your country'
              onKeyDown={(e) => keyDownCountry(e)}
              useBackButton={false}
            />
            <Sheet.Scroller>
              {nationData
                .filter(
                  (e) =>
                    e.use === true &&
                    e.showMeetingByLocation === true &&
                    e.nation.toLowerCase().includes(searchCountry.toLowerCase())
                )
                .map((e) => (
                  <div
                    key={e.abb}
                    className={styles['sheet-terms-content']}
                    onClick={() => {
                      handleCountry(e.abb);
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={selectedNation === e.abb ? selectedIcon : unselectedIcon}
                        alt=''
                        style={{ marginRight: '0.5rem' }}
                      />
                      <div>{e.nation}</div>
                    </div>
                  </div>
                ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenNationBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
      {/* 직종 선택 시트 */}
      <Sheet
        isOpen={isOpenJobBottomSheet}
        detent='content-height'
        onClose={() => setOpenJobBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '41.1rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Job Categories</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenJobBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {jobData.map((categoryData, categoryIdx) => (
                <div
                  key={categoryIdx}
                  className={styles['sheet-title']}
                >
                  <span>{categoryData.category}</span> {/* 카테고리 표시 */}
                  {categoryData.items.map((job, jobIdx) => (
                    <div
                      key={jobIdx}
                      className={styles['sheet-terms-content']}
                      onClick={() => handleJobs(job)} // 개별 직무를 전달
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={selectedJob === job ? selectedIcon : unselectedIcon} // 선택 여부에 따른 아이콘 표시
                          alt=''
                          style={{ marginRight: '0.5rem' }}
                        />
                        <div>{job}</div> {/* 직무 이름 표시 */}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenJobBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
      {/* 언어 선택 시트 */}
      <Sheet
        isOpen={isOpenLanguagesBottomSheet}
        detent='content-height'
        onClose={() => setOpenLanguagesBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '41.1rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Language</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenLanguagesBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {languagesData.map((lang: string) => (
                <div
                  key={lang}
                  className={styles['sheet-terms-content']}
                  onClick={() => handleLanguages(lang)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={selectedLanguages === lang ? selectedIcon : unselectedIcon}
                      alt=''
                      style={{ marginRight: '0.5rem' }}
                    />
                    <div>{lang}</div>
                  </div>
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenLanguagesBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
      {/* 정렬 옵션 시트 */}
      <Sheet
        isOpen={isOpenSortBottomSheet}
        detent='content-height'
        onClose={() => setOpenSortBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '19.9rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Sort by</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenSortBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {categoryType.map((item) => (
                <div
                  key={item.option}
                  className={styles['sheet-terms-content']}
                  onClick={() => handleSortType(item.option)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={selectedSortOption === item.option ? selectedIcon : unselectedIcon}
                      alt=''
                      style={{ marginRight: '0.5rem' }}
                    />
                    <div>{item.title}</div>
                  </div>
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenSortBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
}

export default SearchResult;
