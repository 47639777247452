import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import timezones from 'timezones-list';
import logoImage from './assets/images/nailedit_logo.png'
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Loading from './pages/Loading';
import Main from './pages/Main';
import Home from './pages/Home';
import Menu from './pages/Menu';
import My from './pages/My';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import PasswordReset from './pages/auth/PasswordReset';
import ProfileInput from './pages/auth/ProfileInput';
import MyInfo from './pages/my/MyInfo';
import MyInfoAuth from './pages/my/MyInfoAuth';
import MyInfoModify from './pages/my/MyInfoModify';
import ProfileComplete from './pages/my/ProfileComplete';
import Notification from './pages/my/Notification';
import CompanyEmailVerify from './pages/my/components/CompanyEmailVerify';
import MateAgreement from './pages/mate/Agreement';
import MateBirthInput from './pages/mate/BirthInput';
import MateLinkedinInput from './pages/mate/LinkedinInput';
import MateTimezone from './pages/mate/Timezone';
import MateMeetingTime from './pages/mate/MeetingTime';
import MateIntroduce from './pages/mate/Introduce';
import MateProfileComplete from './pages/mate/ProfileComplete';
import ProductManage from './pages/product/Manage';
import ProductPrice from './pages/product/ProductPrice';
import ProductReject from './pages/product/ProductReject';
import AddComplete from './pages/product/AddComplete';
import AddProduct from './pages/product/AddProduct';
import MyMeeting from './pages/meeting/MyMeeting';
import MeetingConfirm from './pages/meeting/MeetingConfirm';
import MeetingDenied from './pages/meeting/Denied';
import MeetingDeniedComplete from './pages/meeting/DeniedComplete';
import MeetingResuggested from './pages/meeting/Resuggested';
import SettingNotification from './pages/setting/NotificationSetting';
import CustomerService from './pages/cs/CustomerService';
import Search from './pages/search/Index';
import useGlobalStore from './stores/globalStore';
import './App.css';
import ProductSuccess from './pages/product/ProductSuccess';
import ProductSuspend from './pages/product/ProductSuspend';

import Community from './pages/community/page';
import Posting from './pages/community/posting/page';
import Article from './pages/community/article/page';
import ResetEmail from './pages/auth/ResetEmail';
import EmailVerification from './pages/auth/EmailVerification';
import SetPassword from './pages/auth/SetPassword';
import FindId from './pages/auth/FindId';
import FindIdLogin from './pages/auth/FindIdLogin';
import MeetingReview from './pages/meeting/MeetingReview';
import MeetingReviewCoffeechat from './pages/meeting/MeetingReviewCoffeechat';
import MeetingReviewInterview from './pages/meeting/MeetingReviewInterview';
import MeetingReviewReview from './pages/meeting/MeetingReviewReview';
import MeetingReviewLast from './pages/meeting/MeetingReviewLast';
import MeetingReviewService from './pages/meeting/MeetingReviewService';
import MeetingMyReview from './pages/meeting/MeetingMyReview';
import MeetingMyReviewDetail from './pages/meeting/MeetingMyReviewDetail';
import MyMeetingPayment from './pages/meeting/MyMeetingPayment';
import MeetingConfirmDetail from './pages/meeting/MeetingConfirmDetail';
import MeetingSuggested from './pages/meeting/MeetingSuggested';
import MeetingConfirmComplete from './pages/meeting/MeetingConfirmComplete';
import SearchResult from './pages/search/SearchResult';
import SearchResultReview from './pages/search/SearchResultReview';
import SearchResultProduct from './pages/search/SearchResultProduct';
import SearchProductPayment from './pages/search/SearchProductPayment';
import PaymentCompleted from './pages/search/components/PaymentCompleted';
import CustomerServiceMailCompleted from './pages/cs/CustomerServiceMailCompleted';
import CustomerServiceFaq from './pages/cs/CustomerServiceFaq';
import MateProfile from './pages/mate/MateProfile';
import ReportCompleted from './pages/mate/ReportCompleted';
import Calculation from './pages/calculation/Calculation';
import CalculationDetail from './pages/calculation/CalculationDetail';
import WithdrawWaiting from './pages/calculation/components/WithdrawWaiting';
import CalculationOption from './pages/calculation/CalculationOption';
import CalculationOptionAdd from './pages/calculation/CalculationOptionAdd';
import CalculationRequest from './pages/calculation/CalculationRequest';
import CalculationRequestSelect from './pages/calculation/CalculationRequestSelect';
import CalculationRequestAdd from './pages/calculation/CalculationRequestAdd';
import CalculationRequestAddPaypal from './pages/calculation/CalculationRequsetAddPaypal';
import CalculationRequestAddCompleted from './pages/calculation/CalculationRequestAddCompleted';
import WithdrawRequest from './pages/calculation/WithdrawRequest';
import WithdrawRequestConfirm from './pages/calculation/WithdrawRequestConfirm';
import WithdrawRequestCompleted from './pages/calculation/WithdrawRequestCompleted';
import Setting from './pages/setting/Setting';
import NotificationSettingType from './pages/setting/NotificationSettingType';
import NotificationSettingTime from './pages/setting/NotificationSettingTime';
import NotificationSettingLanguages from './pages/setting/NotificationSettingLanguages';
import NotificationSettingTimeSearch from './pages/setting/NotificationSettingTimeSearch';
import TermsOfService from './pages/setting/TermsOfService';
import PersonalInfo from './pages/setting/PersonalInfo';
import PersonalInfoNumber from './pages/setting/PersonalInfoNumber';
import MateRegister from './pages/mate/MateRegister';
import MateDetail from './pages/mate/MateDetail';
import MyCoupon from './pages/my/MyCoupon';
import CalculationRequestAddInformation from './pages/calculation/CalculationRequestAddInformation';
import CalculationRequestAddAddress from './pages/calculation/CalculationRequestAddAddress';
import CalculationRequestAddMethod from './pages/calculation/CalculationRequestAddMethod';
import ListByJob from './pages/mate/ListByJob';
import ListByCountry from './pages/mate/ListByCountry';
import MeetingReviewUsefulAnswer from './pages/meeting/MeetingReviewUsefulAnswer';
import PurchaseConfirm from './pages/meeting/PurchaseConfirm';
import MyManageReview from './pages/my/MyManageReview';

//Nailedit 기준 해상도 375x667px
function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { isAuthenticated } = useGlobalStore();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='master'>
      <div className='logoBox'>
        <h1>Move Forward with New People</h1>
        <img src={logoImage} alt="mainLogo" />
      </div>
      <div className='naileditBox'>
      <BrowserRouter>
      <Routes>
        <Route path='/auth/signin' element={<SignIn />} />
        <Route path='/auth/signup' element={<SignUp />} />
        {/* 아이디찾기 */}
        <Route path='/auth/findId' element={<FindId />} />
        <Route path='/auth/findIdLogin' element={<FindIdLogin />} />

        {/* 비밀번호찾기 */}
        <Route path='/auth/passwordReset' element={<PasswordReset />} />
        <Route path='/auth/resetEmail' element={<ResetEmail />} />
        <Route path='/auth/emailVerification' element={<EmailVerification />} />
        <Route path='/auth/setPassword' element={<SetPassword />} />
        <Route path='/auth/profileInput' element={<ProfileInput />} />

        <Route path='/my/myInfo' element={<MyInfo />} />
        <Route path='/my/myInfoAuth' element={<MyInfoAuth />} />
        <Route path='/my/myInfoModify' element={<MyInfoModify />} />
        <Route path='/my/profileComplete' element={<ProfileComplete />} />
        <Route path='/my/notification' element={<Notification />} />
        <Route path='/my/coupon' element={<MyCoupon />} />
        <Route path='/my/manageReview' element={<MyManageReview />} />

        <Route path='/mate/register' element={<MateRegister />} />
        <Route path='/mate/agreement' element={<MateAgreement />} />
        <Route path='/mate/birthInput' element={<MateBirthInput />} />
        <Route path='/mate/emailInput' element={<CompanyEmailVerify />} />
        <Route path='/mate/linkedinInput' element={<MateLinkedinInput />} />
        <Route path='/mate/timezone' element={<MateTimezone />} />
        <Route path='/mate/meetingTime' element={<MateMeetingTime />} />
        <Route path='/mate/introduce/:id' element={<MateIntroduce />} />
        <Route path='/mate/introduce' element={<MateIntroduce />} />
        <Route path='/mate/profileComplete' element={<MateProfileComplete />} />
        <Route path='/mate/profile' element={<MateProfile />} />
        <Route path='/mate/detail/:id' element={<MateDetail />} />
        <Route path='/mate/reportComplete' element={<ReportCompleted />} />
        <Route path='/mate/listByJob' element={<ListByJob />} />
        <Route path='/mate/listByCountry' element={<ListByCountry />} />

        <Route path='/product/manage' element={<ProductManage />} />
        <Route path='/product/price/:type' element={<ProductPrice />} />
        <Route path='/product/:id/reject' element={<ProductReject />} />
        <Route path='/product/reject' element={<ProductReject />} />
        <Route path='/product/success' element={<ProductSuccess />} />
        <Route path='/product/:id/suspend' element={<ProductSuspend />} />
        <Route path='/product/addProduct' element={<AddProduct />} />
        <Route path='/product/addComplete' element={<AddComplete />} />
        {/* 리뷰 */}
        <Route path='/meeting/review' element={<MeetingReview />} />
        <Route path='/meeting/:id/review' element={<MeetingReview />} />
        <Route path='/meeting/review/coffeechat' element={<MeetingReviewCoffeechat />} />
        <Route path='/meeting/review/interview' element={<MeetingReviewInterview />} />
        <Route path='/meeting/review/review' element={<MeetingReviewReview />} />
        <Route path='/meeting/review/usefulAnswer' element={<MeetingReviewUsefulAnswer />} />
        <Route path='/meeting/review/last' element={<MeetingReviewLast />} />
        <Route path='/meeting/review/service' element={<MeetingReviewService />} />
        <Route path='/meeting/myReview' element={<MeetingMyReview />} />
        <Route path='/meeting/myReview/detail' element={<MeetingMyReviewDetail />} />
        {/* 미팅리스트 */}
        <Route path='/meeting/myMeeting' element={<MyMeeting />} />
        <Route path='/meeting/myMeeting/payment' element={<MyMeetingPayment />} />

        <Route path='/meeting/purchase/confirm' element={<PurchaseConfirm />} />
        <Route path='/meeting/confirm' element={<MeetingConfirm />} />
        <Route path='/meeting/confirmDetail/:id' element={<MeetingConfirmDetail />} />
        <Route path='/meeting/confirmDetail' element={<MeetingConfirmDetail />} />
        <Route path='/meeting/denied' element={<MeetingDenied />} />
        <Route
          path='/meeting/deniedComplete'
          element={<MeetingDeniedComplete />}
        />
        {/* 미팅제안 */}
        <Route path='/meeting/suggested' element={<MeetingSuggested />} />
        <Route path='/meeting/confirm/complete' element={<MeetingConfirmComplete />} />
        <Route path='/meeting/resuggested' element={<MeetingResuggested />} />
        {/* 미팅 알림창 */}
        {/* <Route path='/meeting/alarm' element={<MeetingAlarm />} /> */}


        <Route path='/setting/notification' element={<SettingNotification />} />
        <Route path='/setting/notification/type' element={<NotificationSettingType />} />
        <Route path='/setting/notification/time' element={<NotificationSettingTime />} />
        <Route path='/setting/notification/time/search' element={<NotificationSettingTimeSearch />} />
        <Route path='/setting/notification/languages' element={<NotificationSettingLanguages />} />
        <Route path='/setting/termsOfService' element={<TermsOfService />} />
        <Route path='/setting/personalInfo' element={<PersonalInfo />} />
        <Route path='/setting/personalInfo/number' element={<PersonalInfoNumber />} />
        <Route path='/setting' element={<Setting />} />

        {/* 김경민 community */}
        <Route path='/community' element={<Community />} />
        <Route path='/community/posting' element={<Posting />} />
        <Route path='/community/posting/:id' element={<Posting />} />
        <Route path="/community/article/:id" element={<Article />} />
        <Route path="/community/notice/:id" element={<Article />} />

        <Route path='/search' element={<Search />} />
        <Route path='/search/result' element={<SearchResult />} />
        <Route path='/searchResult/review/:id' element={<SearchResultReview />} />
        <Route path='/searchResult/product/:id' element={<SearchResultProduct />} />
        <Route path='/searchResult/product/:id/payment' element={<SearchProductPayment />} />

        {/* 구매 */}
        <Route path='/meeting/paymentComplete' element={<PaymentCompleted />} />
        {/* 정산 */}
        <Route path='/calculation' element={<Calculation />} />
        <Route path='/calculation/detail' element={<CalculationDetail />} />
        <Route path='/calculation/withdraw/waiting' element={<WithdrawWaiting />} />
        <Route path='/calculation/option' element={<CalculationOption />} />
        <Route path='/calculation/option/add' element={<CalculationOptionAdd />} />
        <Route path='/calculation/request/:id' element={<CalculationRequest />} />
        <Route path='/calculation/request' element={<CalculationRequest />} />
        <Route path='/calculation/request/withdraw' element={<WithdrawRequest />} />
        <Route path='/calculation/request/withdraw/confirm' element={<WithdrawRequestConfirm />} />
        <Route path='/calculation/request/withdraw/completed' element={<WithdrawRequestCompleted />} />
        <Route path='/calculation/request/select/:id' element={<CalculationRequestSelect />} />
        <Route path='/calculation/request/select' element={<CalculationRequestSelect />} />
        <Route path='/calculation/request/add' element={<CalculationRequestAdd />} />
        <Route path='/calculation/request/add/:id' element={<CalculationRequestAdd />} />
        <Route path='/calculation/request/information/:id' element={<CalculationRequestAddInformation />} />
        <Route path='/calculation/request/information/add' element={<CalculationRequestAddInformation />} />
        <Route path='/calculation/request/add/address' element={<CalculationRequestAddAddress />} />
        <Route path='/calculation/request/paypal/:id' element={<CalculationRequestAddPaypal />} />
        <Route path='/calculation/request/paypal/add' element={<CalculationRequestAddPaypal />} />
        <Route path='/calculation/request/method/:id' element={<CalculationRequestAddMethod />} />
        <Route path='/calculation/request/method/add' element={<CalculationRequestAddMethod />} />
        <Route path='/calculation/request/add/completed' element={<CalculationRequestAddCompleted />} />

        {/* 고객지원 */}
        <Route path='/cs/customerService' element={<CustomerService />} />
        <Route path='/cs/customerService/mailCompleted' element={<CustomerServiceMailCompleted />} />
        <Route path='/cs/customerService/faq' element={<CustomerServiceFaq />} />

        <Route path="/" element={<Home/>}/>
        <Route path='/menu' element={<Menu />} />
        {/*
        <Route
          path='/'
          element={
            <>
              <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/menu' element={<Menu />} />
                <Route path='/my' element={<My />} />
              </Routes>
              <Footer />
            </>
          }
        />
        */}
      </Routes>
    </BrowserRouter>
      </div>
    </div>
    
  );
}

export default App;