import React, { useState, useEffect } from 'react';
import styles from './ReviewSelect.module.css';
import uncheckedIcon from '../../../assets/images/ic_unchecked.png';
import checkedIcon from '../../../assets/images/ic_checked.png';
import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

interface ReviewSelectProps {
  text: string;
  time?: string; // 추가: time 입력
  isoTime?: string; // 추가: time 입력
  checked?: string; // 체크된 상태 아이콘
  unchecked?: string; // 체크되지 않은 상태 아이콘
  onChange?: (active: boolean) => void; // 선택 상태 변화 시 호출될 콜백
  onSelect?: (active: boolean, text: string, time: string) => void; // 선택 시 호출될 콜백 추가
  readOnly?: boolean; // 읽기 모드 추가
  active?: boolean; // active 모드 추가
  selected?: boolean; // selected 상태 추가
  useBgColor?: boolean;
}

const ReviewSelect: React.FC<ReviewSelectProps> = ({
  text,
  isoTime,
  time, // time 추가
  checked = checkedIcon,
  unchecked = uncheckedIcon,
  onChange,
  onSelect,
  readOnly = false, // 기본값을 false로 설정
  active = false, // 기본값을 false로 설정
  selected = false, // selected prop 추가
  useBgColor = false,
}) => {
  const [isActive, setIsActive] = useState<boolean>(selected); // 선택 상태 관리

  useEffect(() => {
    setIsActive(active || selected); // active나 selected가 변경될 때마다 isActive 업데이트
  }, [active, selected]);

  const handleToggle = () => {
    if (readOnly || active) return; // 읽기 모드일 경우 클릭 무시

    setIsActive((prevActive) => !prevActive); // 클릭 시 선택 상태 토글
    if (onChange) {
      onChange(!isActive); // 선택 상태 변화 시 부모 컴포넌트에 알림
    }
    if (onSelect) {
      onSelect(!isActive, text, time || '');
    }
  };
  function formatScheduleTime(isoString: string): string {
    const date = parseISO(isoString); // ISO 문자열을 Date 객체로 변환

    const month = date.getMonth() + 1; // 월 (0부터 시작하므로 +1)
    const day = date.getDate(); // 일
    const dayName = format(date, 'EEE'); // 요일 이름 (예: 'Mon')

    const formattedStartTime = format(date, 'hh:mm a'); // 시작 시간 (AM/PM 형식)

    // 30분 후 시간을 계산
    const endDate = new Date(date.getTime() + 30 * 60000); // 30분 추가
    const formattedEndTime = format(endDate, 'hh:mm a'); // 종료 시간 (AM/PM 형식)

    // 최종 포맷된 문자열 (기존 형식 유지)
    return `${month}/${day}(${dayName})\r\n${formattedStartTime} - ${formattedEndTime}`;
  }

  return (
    <div>
      <div
        className={`${styles['select-container']} ${isActive ? styles.active : styles.inactive} ${
          isActive ? styles['border-active'] : ''
        }`}
        onClick={handleToggle} // 클릭 이벤트 핸들러
        style={{
          backgroundColor: `${useBgColor ? 'var(--color-white1000)' : 'var(--color-white50)'}`,
          border: `${useBgColor ? '1px solid var(--color-black_transparent_12)' : 'inherit'}`,
        }}
      >
        <div className={styles['text']}>
          {text}
          {/* time가 있을 때만 보여주기 */}
          {time && <div className={styles['time']}>{time}</div>}
          {/* iso시간 */}
          {isoTime && <div className={styles['text']}>{formatScheduleTime(isoTime)} </div>}
        </div>
        {!readOnly && ( // 읽기 모드가 아닐 때만 체크 아이콘 렌더링
          <img
            src={isActive ? checked : unchecked} // 상태에 따라 아이콘 변경
            alt='체크아이콘'
            style={{ marginRight: '0.5rem' }}
          />
        )}
      </div>
    </div>
  );
};

export default ReviewSelect;
