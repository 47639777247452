import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import { ProductType } from '../../constants/constants';
import { Product } from '../../models/product';
import ProductService from '../../services/productService';
import styles from './ProductPrice.module.css';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import OutlineTextField from '../../components/element/OutlineTextField';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';

import closeIcon from '../../assets/images/ic_close.png';

export enum ProductPriceType {
  ADD,
  MODIFY,
}

function ProductPrice() {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const { type } = useParams();
  const [productName, setProductName] = useState<string>('');
  const [productType, setProductType] = useState<ProductType>(ProductType.COFFEECHAT);
  const [productId, setProductId] = useState<number>(0); //수정할 product id.
  const [productPriceType, setProductPriceType] = useState<ProductPriceType>(ProductPriceType.ADD);
  const [inputPrice, setInputPrice] = useState<string>('');
  const [average, setAverage] = useState<string>('');
  const [time, setTime] = useState<number>(30);
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const productType: ProductType =
      (query.get('productType')?.toUpperCase() as ProductType) ?? ProductType.COFFEECHAT;
    const productId: number = parseInt(query.get('id') as string) ?? 0;
    setProductType(productType);

    if (productType === ProductType.COFFEECHAT) {
      setProductName('Coffee Chat');
      setTime(30); //30분.
    } else if (productType === ProductType.INTERVIEW) {
      setProductName('Interview Practice');
      setTime(60); //60분.
    } else {
      //이력서/포폴리뷰.
      setProductName('Resume/Portfolio Review');
      setTime(60); //60분.
    }

    if (type === 'add') {
      setProductPriceType(ProductPriceType.ADD);

      //평균가 요청.
      ProductService.getAverage(productType).then((value) => {
        setAverage(value.average);
      });
    } else {
      setProductId(productId);
      setProductPriceType(ProductPriceType.MODIFY);
    }
  }, []);

  function priceCheck() {
    if (inputPrice === '') {
      toast('error', 'Set your price', false);
      return;
    }

    setOpenBottomSheet(true);
  }

  async function done() {
    if (productPriceType === ProductPriceType.ADD) {
      //상품 등록 요청.
      const product: Product = {
        type: productType,
        time: time,
        price: parseInt(inputPrice),
      };
      const newProduct: Product | undefined = await ProductService.addProduct(product);

      if (newProduct) {
        navigate('/product/addComplete');
      } else {
        toast('error', 'An error occurred while listing the item', false);
      }
    } else {
      //상품 가격 수정 요청.
      const newProduct: Product | undefined = await ProductService.updateProductPrice(
        productId,
        parseInt(inputPrice)
      );

      if (newProduct) {
        toast('success', 'Price updated successfully', false);
        navigate('/product/manage');
      } else {
        toast('error', 'An error occurred while updating the price', false);
      }
    }
  }

  function handleChange(event: any) {
    setInputPrice(event.target.value);
  }

  return (
    <div
      className={styles['conatiner']}
      style={{ position: 'relative' }}
      id='container'
    >
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
      />
      <div className={styles['title-text']}>Set a price for this item</div>
      <OutlineTextField
        name='price'
        type='number'
        prefix={inputPrice !== '' ? '$' : ''}
        value={`${inputPrice}`}
        radius={0}
        marginLeft='2.0rem'
        marginRight='2.0rem'
        fontSize='1.8rem'
        bottomLine={true}
        placeholder='Enter your price (USD)'
        onChange={(event) => handleChange(event)}
        onClear={() => setInputPrice('')}
      />
      {productPriceType == ProductPriceType.ADD && (
        <div className={styles['reference-container']}>
          <div className={styles['reference-reason-title']}>Note:</div>
          <div className={styles['reference-reason-content']}>
            <ul>
              <li>Nailedit takes a 30% service fee for operations.</li>
              <li>
                The amount of VAT/GST depends on the consumer's country/region. VAT/GST will be
                deducted from the price according to PG(Airwallex) system.
              </li>
              <li>
                Set your price considering the countries where you expect to have the most
                consumers.
              </li>
              <li>
                Recommended starting prices are Coffee Chat ($7–$10), Resume Review ($50), and Interview Practice ($100–$150)
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className={styles['button-container']}>
        <Button
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={600}
          active={true}
          onClick={() => priceCheck()}
        >
          Price set
        </Button>
      </div>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setOpenBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px' }}>
          <Sheet.Content style={{ marginBottom: '2.0rem' }}>
            <div className={styles['sheet-container']}>
              <div className={styles['sheet-title']}>Check your price</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-content-container']}>
                <div className={styles['sheet-content-text']}>
                  When the desired price is ${inputPrice}
                </div>
                <div className={styles['sheet-row']}>
                  <div>Sales price</div>
                  <div>${inputPrice}</div>
                </div>
                <div className={styles['sheet-row']}>
                  <div>Service Fee(30%)</div>
                  <div>${Math.round(parseInt(inputPrice) * 0.3)}</div>
                </div>
                <div className={styles['sheet-row']}>
                  <div>Mate’s Revenue (Before tax)</div>
                  <div>${parseInt(inputPrice) - Math.round(parseInt(inputPrice) * 0.3)}</div>
                </div>
                <div className={styles['sheet-content-divider']} />
                <div className={styles['sheet-content-result-text']}>
                  Revenue is subject to customer's local tax rates
                </div>
              </div>
              <div className={styles['sheet-content-description-text']}>
                {
                  "Nailedit charges a 30% service fee from sales price, with\r\nthe remaining 70% as Mate's earnings (tax, exchange,\r\nand transfer fees apply)"
                }
              </div>
              <div className={styles['button-container']}>
                <Button
                  height={'4.8rem'}
                  radius={10}
                  activeFontSize='1.5rem'
                  activeFontWeight={600}
                  active={true}
                  onClick={() => done()}
                >
                  Confirmed
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
}

export default ProductPrice;
