import styles from './SearchBar.module.css';

import arrowLeftIcon from '../../assets/images/ic_arrow_left.png';
import clearIcon from '../../assets/images/ic_clear.png';
import searchIcon from '../../assets/images/ic_search.png';

interface SearchBarProps {
  useBackButton?: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBack?: () => void;
  placeholder?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  useBackButton = true,
  value,
  onChange,
  onClear,
  onKeyDown,
  onBack,
  placeholder,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['search-bar-container']}>
        {useBackButton == true && (
          <img
            src={arrowLeftIcon}
            alt=''
            onClick={onBack}
          />
        )}
        <div className={styles['search-textfield']}>
          {value == '' && (
            <img
              className={styles['search-icon']}
              src={searchIcon}
              alt=''
            />
          )}
          <input
            className={styles['input-field']}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
          />
          {onClear !== undefined && value !== '' && (
            <img
              className={styles['clear-icon']}
              src={clearIcon}
              alt=''
              onClick={onClear}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
