import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Header from '../../components/layout/Header';
import styles from './SearchResultReview.module.css';
import starIcon from '../../assets/images/ic_star.png'; // Ratings 아이콘 경로 추가
import ReviewList from '../meeting/components/ReviewList';
import productService from '../../services/productService';
import { ProductReview } from '../../models/productReview';
import { Product } from '../../models/product';
import useGlobalStore from '../../stores/globalStore';

function SearchResultReview() {
  const { id } = useParams<{ id: string }>(); // URL에서 id를 추출
  const location = useLocation();
  const productsData = location.state.productType;
  const mateData = location.state.mateData;
  const [productReview, setProductReview] = useState<ProductReview[]>([]);
  useEffect(() => {
    productReviews(mateData.user_id);
  }, []);

  async function productReviews(id: number) {
    try {
      const productReviewList = await productService.getMateReviews(id, false);
      if (productReviewList && productReviewList.length > 0) {
        setProductReview(productReviewList);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const renderRating = (rating: number) => {
    return (
      <div className={styles['rating-container']}>
        {[...Array(rating)].map((_, index) => (
          <img
            key={index}
            src={starIcon}
            alt='Ratings'
            className={styles['star-icon']}
          />
        ))}
      </div>
    );
  };

  const navigate = useNavigate();

  const totalMeetings =
    (productsData?.[0]?.buyer_count_coffeechat || 0) +
    (productsData?.[1]?.buyer_count_interview || 0) +
    (productsData?.[2]?.buyer_count_resume || 0);
  const totalReview = (productsData || []).reduce((acc: number, product: Product) => {
    // review가 배열인지 확인하고, hide가 true인 리뷰는 제외
    const validReviewsCount = Array.isArray(product.review)
      ? product.review.filter((review) => !review.hide).length
      : 0;

    return acc + validReviewsCount;
  }, 0);
  // productReview 배열에서 score 값을 추출하여 총합을 구하기
  const totalScore = productReview.reduce((sum, review) => sum + (review.score || 0), 0);

  // 리뷰의 개수 구하기
  const numberOfScores = productReview.length; // 배열의 길이로 리뷰 개수 계산

  // 평균 점수 계산
  const averageScore = numberOfScores > 0 ? Math.floor(totalScore / numberOfScores) : 0;

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        title={'Reviews'}
        onBack={() => navigate(-1)}
      />
      <div className={styles['review-container']}>
        <div className={styles['review-number-container']}>
          <div className={styles['review-number-box']}>
            {totalMeetings}
            <div className={styles['review-number-type']}>
              {productsData.map((item: Product) => (
                <div key={item.id}>
                  <span>
                    {item.type === 'COFFEECHAT'
                      ? 'Chat'
                      : item.type === 'INTERVIEW'
                      ? 'Interview'
                      : item.type === 'REVIEW'
                      ? 'Resume'
                      : ''}
                    <span className={styles['type-line']}></span>
                  </span>
                </div>
              ))}
            </div>
            <div className={styles['review-number-text']}>{totalMeetings} Total Meetings</div>
          </div>
          <div className={styles['review-number-box']}>
            {averageScore}
            <div className={styles['review-number-rating']}>{renderRating(averageScore ?? 0)}</div>
            <div className={styles['review-number-text']}>{totalReview} Total Reviews</div>
          </div>
        </div>
        <ReviewList
          reviews={productReview}
          useTypeText={true}
          text='See More Reviews'
          padding={0}
          useInfo={true}
          borderColor={'none'}
          marginTop={2.4}
          marginBottom={2.4}
        />
      </div>
    </div>
  );
}

export default SearchResultReview;
