import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import styles from './Manage.module.css';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import useGlobalStore from '../../stores/globalStore';
import { ProductStatus } from '../../constants/constants';
import ProductStatusCard, { ActionStyle } from './components/ProductStatusCard';
import StatusTag from './components/StatusTag';

import { ProductType } from '../../constants/constants';
import { Product } from '../../models/product';
import ProductService from '../../services/productService';

import coffeeChatIcon from '../../assets/images/ic_coffeechat.png';
import interviewIcon from '../../assets/images/ic_interview.png';
import resumeIcon from '../../assets/images/ic_resume.png';
import menuIcon from '../../assets/images/ic_menu.png';
import closeIcon from '../../assets/images/ic_close.png';

function Manage() {
  const { setTempProduct, mate } = useGlobalStore();
  const navigate = useNavigate();
  const toast = useToast();
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);
  const [isOpenInactiveBottomSheet, setOpenInactiveBottomSheet] = useState(false);
  const [coffeeChatProduct, setCoffeeChatProduct] = useState<Product | undefined>(undefined);
  const [interviewProduct, setInterviewProduct] = useState<Product | undefined>(undefined);
  const [reviewProduct, setReviewProduct] = useState<Product | undefined>(undefined);

  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const [selectedButtonNumber, setSelectedButtonNumber] = useState<number>(-1);
  const [isLoaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    //상품 목록 요청.
    const list = await ProductService.getList();
    for (var item of list) {
      if (item.type === ProductType.COFFEECHAT) {
        setCoffeeChatProduct(item);
      } else if (item.type === ProductType.INTERVIEW) {
        setInterviewProduct(item);
      } else if (item.type === ProductType.REVIEW) {
        setReviewProduct(item);
      }
    }
    setLoaded(true);
  }

  function goActionProcess(product: Product, buttonNumber: number) {
    setSelectedProduct(product);

    if (product.status === ProductStatus.ACTIVE) {
      //상품 내리기 팝업.
      setOpenInactiveBottomSheet(true);
    } else if (product.status === ProductStatus.INACTIVE) {
      //다시 판매하기.
      changeProductActive();
    } else if (product.status === ProductStatus.REJECT) {
      if (buttonNumber === 0) {
        //실패사유 확인.
        setTempProduct(product);
        navigate('/product/reject');
      } else {
        //수정 후 재심사.
        navigate(`/product/price/modify?productType=${product.type}&id=${product.id}`);
      }
    }
  }

  async function changeProductActive() {
    //상품 판매중 요청.
    const result = await ProductService.updateProductStatus(
      selectedProduct?.id!,
      ProductStatus.ACTIVE
    );
    if (result) {
      await loadData();

      if (result === 'success') {
        toast(
          'success',
          `${
            selectedProduct?.type === ProductType.COFFEECHAT
              ? 'Coffee Chat'
              : selectedProduct?.type === ProductType.INTERVIEW
              ? 'Interview Practice'
              : 'Review'
          } is now live`
        );
      } else {
        toast('error', 'Unable to change the product status');
      }
    }
    setOpenInactiveBottomSheet(false);
  }

  async function requestInactiveProduct() {
    //상품 판매 중단 요청.
    const result = await ProductService.updateProductStatus(
      selectedProduct?.id!,
      ProductStatus.INACTIVE
    );
    if (result) {
      await loadData();
      if (result === 'success') {
        toast(
          'success',
          `${
            selectedProduct?.type === ProductType.COFFEECHAT
              ? 'Coffe Chat'
              : selectedProduct?.type === ProductType.INTERVIEW
              ? 'Interview Practice'
              : 'Review'
          } has been unlisted.`
        );
      } else if (result === 'need_cancel_meeting') {
        toast(
          'error',
          'Even if you unlist the product, scheduled\r\nmeetings must be canceled separately'
        );
      } else {
        toast('error', 'Failed to process the unlisting the product');
      }
    }
    setOpenInactiveBottomSheet(false);
  }

  function done() {
    toast('success', 'Sign In!', false);
  }

  return (
    <>
      <div
        className={styles['container']}
        style={{ position: 'relative' }}
        id='container'
      >
        <Header
          useBackButton={true}
          onBack={() => navigate(-1)}
          actionType='icon'
          actionTitle={
            <div className={styles['menu-icon']}>
              <img
                src={menuIcon}
                alt=''
              />
            </div>
          }
          onAction={() => setOpenBottomSheet(true)}
        />
        <div className={styles['list-container']}>
          <div className={styles['title-text']}>Manage my items</div>
          {coffeeChatProduct === undefined ? (
            <ProductStatusCard
              productName='Coffee Chat'
              icon={coffeeChatIcon}
              status={ProductStatus.NONE}
              onAction1={() => navigate('/product/price/add?productType=coffeechat')}
            />
          ) : (
            <ProductStatusCard
              productName='Coffee Chat'
              icon={coffeeChatIcon}
              status={coffeeChatProduct.status!}
              reviewCount={coffeeChatProduct.review_count}
              reviewScore={coffeeChatProduct.score}
              startDate={coffeeChatProduct.start_date}
              endDate={coffeeChatProduct.end_date}
              createdDate={coffeeChatProduct.created_date}
              buyerCount={mate?.buyer_count_coffeechat}
              price={coffeeChatProduct.price}
              onAction1={() => {
                goActionProcess(coffeeChatProduct, 0);
                //setSelectedButtonNumber(0);
                //setSelectedProduct(coffeeChatProduct);
              }}
              onAction2={() => {
                goActionProcess(coffeeChatProduct, 1);
                //setSelectedButtonNumber(1);
                //setSelectedProduct(coffeeChatProduct);
              }}
              onChangePriceAction={() =>
                navigate(
                  `/product/price/modify?productType=${coffeeChatProduct.type}&id=${coffeeChatProduct.id}`
                )
              }
            />
          )}
          {interviewProduct === undefined ? (
            <ProductStatusCard
              productName='Interview Practice'
              icon={interviewIcon}
              status={ProductStatus.NONE}
              onAction1={() => navigate('/product/price/add?productType=interview')}
            />
          ) : (
            <ProductStatusCard
              productName='Interview Practice'
              icon={interviewIcon}
              status={interviewProduct.status!}
              reviewCount={interviewProduct.review_count}
              reviewScore={interviewProduct.score}
              startDate={interviewProduct.start_date}
              endDate={interviewProduct.end_date}
              createdDate={interviewProduct.created_date}
              buyerCount={mate?.buyer_count_interview}
              price={interviewProduct.price}
              onAction1={() => {
                goActionProcess(interviewProduct, 0);
                //setSelectedButtonNumber(0);
                //setSelectedProduct(interviewProduct);
              }}
              onAction2={() => {
                goActionProcess(interviewProduct, 1);
                //setSelectedButtonNumber(1);
                //setSelectedProduct(interviewProduct);
              }}
              onChangePriceAction={() =>
                navigate(
                  `/product/price/modify?productType=${interviewProduct.type}&id=${interviewProduct.id}`
                )
              }
            />
          )}
          {reviewProduct === undefined ? (
            <ProductStatusCard
              productName='Resume/Portpolio Review'
              icon={resumeIcon}
              status={ProductStatus.NONE}
              onAction1={() => navigate('/product/price/add?productType=review')}
            />
          ) : (
            <ProductStatusCard
              productName='Resume/Portpolio Review'
              icon={resumeIcon}
              status={reviewProduct.status!}
              reviewCount={reviewProduct.review_count}
              reviewScore={reviewProduct.score}
              startDate={reviewProduct.start_date}
              endDate={reviewProduct.end_date}
              createdDate={reviewProduct.created_date}
              buyerCount={mate?.buyer_count_resume}
              price={reviewProduct.price}
              onAction1={() => {
                goActionProcess(reviewProduct, 0);
                //setSelectedButtonNumber(0);
                //setSelectedProduct(reviewProduct);
              }}
              onAction2={() => {
                goActionProcess(reviewProduct, 1);
                //setSelectedButtonNumber(1);
                //setSelectedProduct(reviewProduct);
              }}
              onChangePriceAction={() =>
                navigate(
                  `/product/price/modify?productType=${reviewProduct.type}&id=${reviewProduct.id}`
                )
              }
            />
          )}
        </div>
        <Sheet
          mountPoint={document.getElementById('container')!}
          style={{ position: 'absolute' }}
          isOpen={isOpenBottomSheet}
          detent='content-height'
          onClose={() => setOpenBottomSheet(false)}
        >
          <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px' }}>
            <Sheet.Content style={{ marginBottom: '1.4rem' }}>
              <div className={styles['sheet-terms-container']}>
                <div className={styles['sheet-terms-title']}>Product Status</div>
                <img
                  src={closeIcon}
                  alt=''
                  onClick={() => setOpenBottomSheet(false)}
                />
              </div>
              <Sheet.Scroller>
                <div className={styles['sheet-terms-content']}>
                  <div className={styles['sheet-card-row']}>
                    <StatusTag
                      fixedSize={true}
                      status={ProductStatus.IN_REVIEW}
                    />
                    Item is under review
                  </div>
                  <div className={styles['sheet-card-row']}>
                    <StatusTag
                      fixedSize={true}
                      status={ProductStatus.ACTIVE}
                    />
                    Item is live for sale
                  </div>
                  <div className={styles['sheet-card-row']}>
                    <StatusTag
                      fixedSize={true}
                      status={ProductStatus.SUSPENSION}
                    />
                    Item is temporarily unavailable
                  </div>
                  <div className={styles['sheet-card-row']}>
                    <StatusTag
                      fixedSize={true}
                      status={ProductStatus.INACTIVE}
                    />
                    Item has been removed from listing
                  </div>
                  <div className={styles['sheet-card-row']}>
                    <StatusTag
                      fixedSize={true}
                      status={ProductStatus.REJECT}
                    />
                    Listing failed (edit and try again)
                  </div>
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            onTap={() => setOpenBottomSheet(false)}
            style={{ position: 'relative' }}
          />
        </Sheet>
        <Sheet
          mountPoint={document.getElementById('container')!}
          style={{ position: 'absolute' }}
          isOpen={isOpenInactiveBottomSheet}
          detent='content-height'
          onClose={() => setOpenInactiveBottomSheet(false)}
        >
          <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px' }}>
            <Sheet.Content style={{ marginBottom: '2.0rem' }}>
              <div className={styles['sheet-container']}>
                <div className={styles['sheet-title']}>Pause sale temporarily?</div>
                <img
                  src={closeIcon}
                  alt=''
                  onClick={() => setOpenInactiveBottomSheet(false)}
                />
              </div>
              <Sheet.Scroller>
                <div className={styles['sheet-content-container']}>
                  <div className={styles['sheet-content']}>{'You can relist it at any time.'}</div>
                </div>
                <div className={styles['sheet-button-container']}>
                  <Button
                    width={'16.9rem'}
                    height={'4.8rem'}
                    radius={10}
                    activeFontSize='1.5rem'
                    activeFontWeight={600}
                    activeTextColor='#343437'
                    active={true}
                    activeColor={'#EBEDF0'}
                    onClick={() => setOpenInactiveBottomSheet(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    width={'16.9rem'}
                    height={'4.8rem'}
                    radius={10}
                    activeFontSize='1.5rem'
                    activeFontWeight={600}
                    activeTextColor='#FFFFFF'
                    active={true}
                    onClick={() => requestInactiveProduct()}
                  >
                    Confirm
                  </Button>
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            onTap={() => setOpenInactiveBottomSheet(false)}
            style={{ position: 'relative' }}
          />
        </Sheet>
      </div>
      <Footer />
    </>
  );
}

export default Manage;
