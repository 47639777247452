import { useEffect, useState, useRef } from 'react';
import { Sheet } from 'react-modal-sheet';
import styles from './CountryVerify.module.css';
import Header from '../../../components/layout/Header';
import Button from '../../../components/element/Button';
import SelectBox from '../../../components/element/SelectBox';
import TextField from '../../../components/element/TextField';
import CountryVerifyConfirm from './CountryVerifyConfirm';
import { VerifyStatus } from '../../../constants/constants';
import authService from '../../../services/authService';
import useToast from '../../../hook/useToast';
import nationData from '../../../assets/data/nation.json';

import closeIcon from '../../../assets/images/ic_close.png';

interface CountryVerifyProps {
  onVerify: (countryCode: string, countryName: string) => void;
  onCancel: () => void;
}

const CountryVerify: React.FC<CountryVerifyProps> = ({ onVerify, onCancel }) => {
  const toast = useToast();

  const [internationalNumber, setInternationalNumber] = useState<string>('+1');
  const [cellPhone, setCellPhone] = useState<string>('');
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [verifyStatus, setVerifyStatus] = useState<VerifyStatus>(VerifyStatus.NONE);
  const [showConfirmVerify, setShowConfirmVerify] = useState(false);
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);

  async function next() {
    const result: boolean | undefined = await checkCellPhoneVerifyCode();
    if(result) {
      setShowConfirmVerify(true);
    }
  }

  function selectInternationalNumber(code: string) {
    setInternationalNumber('+' + code);
    setOpenBottomSheet(false);
  }

  async function requestCellPhoneVerify() {
    if(internationalNumber === '') {
      toast('error', 'Please select a international number.', false);  
      return;
    }

    if(cellPhone === '') {
      toast('error', '휴대폰 번호를 입력해주세요', false);  
      return;
    }
    
    //휴대폰 인증 번호 전송.
    await authService.requestCellPhoneVerify(internationalNumber, cellPhone);
    setVerifyStatus(VerifyStatus.WAIT);
    toast('success', '인증번호를 보내드렸어요. 휴대폰을 확인해주세요', false);  
  }

  async function checkCellPhoneVerifyCode() {
    if(cellPhone === '' || verifyStatus === VerifyStatus.COMPLETE) return;

    if(verifyCode === '') {
      toast('error', '인증번호를 입력해주세요', false);  
      return;
    }

    const result: boolean = await authService.checkVerifyCode(internationalNumber + cellPhone, verifyCode);
    if(!result) {
      toast('error', '인증번호가 유효하지 않습니다', false);  
      return false;
    }

    //휴대폰 인증 완료.
    setVerifyStatus(VerifyStatus.COMPLETE);
    toast('success', '휴대폰 인증에 성공했어요 !', false);  
    return true;
  }
  
  function onVerifyConfirm(nationData: any) {
    setShowConfirmVerify(false);
    onVerify(nationData.abb, nationData.nation);
  }
  
  function onVerifyCancel() {
    setInternationalNumber('');
    setCellPhone('');
    setVerifyCode('');
    setVerifyStatus(VerifyStatus.NONE);
    setShowConfirmVerify(false);
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => onCancel()}/>
      <div className={styles['title-text']}>
      {'Please verify your current location\r\nwith phone number '}
      </div>
      <div className={styles['label-container']}>
        <label className={styles['label']}>Phone number</label>
      </div>
      <div className={styles['input-field-container']}>
        <SelectBox 
          width={'11.6rem'}
          name='internationalNumber'
          type='text' 
          value={internationalNumber} 
          placeholder=''
          onAction={() => setOpenBottomSheet(true)}
        />
        <TextField 
          name='cellPhone'
          type='text' 
          onClear={() => setCellPhone('')}
          maxLength={15}
          value={cellPhone} 
          enable={true}
          placeholder='Enter without dashes(-)'
          onChange={(event) => setCellPhone(event.target.value)}
        />
      </div>
      {verifyStatus !== VerifyStatus.NONE && <div className={styles['label-container']}>
        Verification code
      </div>}
      {verifyStatus !== VerifyStatus.NONE && <div className={styles['input-field-container']}>
        <TextField 
          name='verifyCode'
          type='number' 
          value={verifyCode} 
          enable={verifyStatus !== VerifyStatus.COMPLETE}
          maxLength={15}
          placeholder='' 
          onChange={(event) => setVerifyCode(event.target.value)}
          onClear={() => setVerifyCode('')}
        />
      </div>}
      <div style={{marginTop: '2.0rem'}}/>
      <Button marginHorizontalRem={2.0} active={true} onClick={() => verifyStatus == VerifyStatus.NONE ? requestCellPhoneVerify() : next()}>{verifyStatus == VerifyStatus.NONE ? 'Receive verification code' : 'Next'}</Button>
      <div className={styles['resend-text']} onClick={() => requestCellPhoneVerify()}>Resend verification code</div>
      {showConfirmVerify == true && 
        <div style={{position: 'absolute', backgroundColor: 'white', width: '100vw'}}>
          <CountryVerifyConfirm 
            countryName={nationData.find((e) => '+' + e.code == internationalNumber)!.nation}
            onConfirm={() => onVerifyConfirm(nationData.find((e) => '+' + e.code == internationalNumber)!)} 
            onCancel={() => onVerifyCancel()}
          />
        </div>
      }
      <Sheet isOpen={isOpenBottomSheet} detent='content-height' onClose={() => setOpenBottomSheet(false)}>
        <Sheet.Container style={{borderRadius: '12px 12px 0px 0px', height: '32.3rem'}}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <img src={closeIcon} alt='' onClick={() => setOpenBottomSheet(false)}/>
            </div>
            <Sheet.Scroller>  
              {nationData.filter((e) => e.use === true).map((e) => 
                <div className={styles['sheet-terms-content']} onClick={() => selectInternationalNumber(e.code)}>
                  <div>{e.text}</div>
                </div>
              )}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheet(false)}/>
      </Sheet>
    </div>
    );
  }
  
  export default CountryVerify;
    