import React, { useState } from 'react';
import styles from './CommunityTabs.module.css'; // Import the CSS module

interface CommunityTabsProps {
  selectedTab: string;
  onTabChange: (tab: string) => void;
}

const TabsComponent: React.FC<CommunityTabsProps> = ({ selectedTab, onTabChange }) => {
  const tabs = [
    '',
    'Job Search',
    'Lifestyle',
    'Field/Role',
    'Studying Abroad',
    'Events',
    'Bookmark',
  ]; // Tabs

  return (
    <div className={styles['tabs']}>
      <div className={styles['frame']}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles['chip']} ${selectedTab === tab ? styles['activeChip'] : ''}`}
            onClick={() => onTabChange(tab)}
          >
            <span className={selectedTab === tab ? styles.activeText : styles.inactiveText}>
              {(tab === '' && 'HOT') || tab}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabsComponent;
