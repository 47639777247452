import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import styles from './CalculationDetail.module.css';
import { UserIncome } from '../../models/userIncome';
import settlementService from '../../services/settlementService';
import { UserWithdrawal } from '../../models/userWithdrawal';
import { format } from 'date-fns';
import taxData from '../../assets/data/tax.json';

function CalculationDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [incomeData, setIncomeData] = useState<UserIncome | null>(null);
  const [withdrawData, setWithdrawData] = useState<UserWithdrawal | null>(null);
  const incomeId = location.state.incomeId;
  const withdrawId = location.state.withdrawId;
  useEffect(() => {
    getIncomeData(incomeId);
    getWithdrawalData(withdrawId);
  }, [incomeId, withdrawId]);

  const getWithdrawalData = async (id: number) => {
    try {
      const withdrawData = await settlementService.getWithdrawalData(id);
      if (withdrawData) {
        setWithdrawData(withdrawData);
      } else {
        console.log('error');
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getIncomeData = async (id: number) => {
    try {
      const incomeData = await settlementService.getIncomeData(id);
      if (incomeData) {
        setIncomeData(incomeData);
      } else {
        console.log('error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
        title='Earnings Details'
      />

      {!incomeData || !incomeData.purchase_history ? (
        <>
          <p className={styles['error-message']}>Not found Data :(</p>
        </>
      ) : (
        <>
          <div className={styles['title']}>
            {`Earnings from`} {format(new Date(incomeData.created_date), 'MMM  dd, yyyy')}
          </div>
          <div className={styles['detail-container']}>
            <div className={styles['detail-title']}>
              <span>Recipient</span>
              <div className={styles['detail-text']}>
                <span>{incomeData?.buyer_user_nickname}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Item</span>
              <div className={styles['detail-text']}>
                <span>
                  {(incomeData?.product.type === 'COFFEECHAT' && 'Coffee Chat') ||
                    (incomeData?.product.type === 'INTERVIEW' && 'Interview Practice') ||
                    (incomeData?.product.type === 'REVIEW' && 'Resume/Portfolio Review')}
                </span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Service Time</span>
              <div className={styles['detail-text']}>
                <span>{format(new Date(incomeData.service_date), 'MMM dd, yyyy, hh:mm a')}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Date of Sale</span>
              <div className={styles['detail-text']}>
                <span>
                  {format(new Date(incomeData.created_date), 'MMM dd, yyyy, hh:mm a')}
                </span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Invoice Number</span>
              <div className={styles['detail-text']}>
                <span>{incomeData?.invoice_number.toUpperCase()}</span>
              </div>
            </div>
          </div>
          <div className={styles['title']}>{'Sales Item'}</div>
          <div className={styles['sale-container']}>
            <div className={styles['detail-title']}>
              <span>{incomeData?.product.type}</span>
              <div className={styles['detail-text']}>
                <span>${incomeData?.purchase_history.product_price}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              {/* 쿠폰 사용유무 확인 불리언 */}
              <span>Coupon</span>
              <div className={styles['detail-text']}>
                <span>${incomeData.purchase_history.discount_price}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Payment Amount </span>
              <div className={styles['detail-text']}>
                <span>{`$${incomeData.purchase_history.final_price}`}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Quantity</span>
              <div className={styles['detail-text']}>
                <span>1</span>
              </div>
            </div>
          </div>
          <div className={styles['title']}>{'Earnings'}</div>
          <div className={styles['profit-container']}>
            <div className={styles['detail-title']}>
              <span>Payout Amount</span>
              <div className={styles['detail-text']}>
                <span>${incomeData!.final_price}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Total Revenue</span>
              <div className={styles['detail-text']}>
                <span>${incomeData!.price}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>
                {`Tax-${incomeData!.user.country_name.split("(")[0]}`}
                {(() => {
                  const taxInfo = taxData.find(tax => tax.label === incomeData!.user.timezone);
                  return taxInfo?.tax_rate ? ` (${taxInfo.tax_rate}%)` : ' (0%)';
                })()}
              </span>
              <div className={styles['detail-text']}>
                <span>${incomeData!.tax}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Nailedit Service Fee</span>
              <div className={styles['detail-text']}>
                <span>${incomeData!.fee}</span>
              </div>
            </div>
            {`*Note : The Payout Amount is the amount before\r\nexchange and remittance fees are deducted. Since\r\ntax policies such as VAT/GST vary by the\r\nconsumer's country, the final price may differ even\r\nfor the same product.`}
          </div>
        </>
      )}
    </div>
  );
}

export default CalculationDetail;
