import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Header from '../../components/layout/Header';
import styles from './MeetingMyReview.module.css';
import starIcon from '../../assets/images/ic_star.png'; // Ratings 아이콘 경로 추가
import { useNavigate } from 'react-router-dom';
import { ProductType } from '../../constants/constants';
import { ProductReview } from '../../models/productReview';
import Button from '../../components/element/Button';
import productService from '../../services/productService';
import default_profile from '../../assets/images/ic_profile_default.png';
import useGlobalStore from '../../stores/globalStore';
import timezoneData from '../../assets/data/timezone.json';

function MeetingMyReview() {
  const [reviews, setReviews] = useState<ProductReview[]>([]);
  const navigate = useNavigate();
  const { user } = useGlobalStore();

  const transformTimezone = (tzCode: string): string => {
    if (!tzCode.includes(', ')) return tzCode;
    const parts = tzCode.split(', ');
    return `${parts[1]}/${parts[0]}`;
  };

  const matchedUserTimezone = timezoneData.find((tz) => tz.label === user?.timezone)!;
  const transUserTimezone = transformTimezone(matchedUserTimezone.tzCode);

  const reviewsData = async () => {
    try {
      const reviewData = await productService.getReview();
      if (reviewData && reviewData.length > 0) {
        setReviews(reviewData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    reviewsData(); // 컴포넌트 마운트 시 데이터 가져오기
  }, []);

  function getProductTypeName(type?: ProductType) {
    if (type === ProductType.COFFEECHAT) {
      return 'Coffee Chat';
    } else if (type === ProductType.INTERVIEW) {
      return 'Interview Practive';
    } else if (type === ProductType.REVIEW) {
      return 'Resume';
    }

    return '';
  }

  const renderRating = (rating: number) => {
    return (
      <div className={styles['rating-container']}>
        {[...Array(rating)].map((_, index) => (
          <img
            key={index}
            src={starIcon}
            alt='Ratings'
            className={styles['star-icon']}
          />
        ))}
      </div>
    );
  };

  // 각 리뷰의 ID에 따라 리뷰 상세 페이지로 이동, state로 review객체 전달
  const toReview = (review: ProductReview) => {
    navigate(`/meeting/myReview/detail`, { state: { review } });
  };

  console.log(reviews);

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>{'My Reviews'}</div>
      <div className={styles['review-container']}>
        {reviews.length >= 1 ? (
          <div>
            {reviews.map((review) => (
              <div
                key={review.id}
                className={styles['review-item']}
              >
                <div className={styles['container-flex']}>
                  <img
                    src={
                      review.user?.profile_image
                        ? `${process.env.REACT_APP_STORE_ADDRESS}/${review.user.profile_image}`
                        : default_profile
                    }
                    alt=''
                    className={styles['profile-image']}
                  />
                  <div className={styles['nickname']}>
                    {getProductTypeName(review.product?.type)} with {review.user?.nickname}
                  </div>
                </div>
                <div className={styles['review-rating']}>
                  <div className={styles['container-flex']}>
                    {renderRating(review.score)}
                    {review.score}
                  </div>
                </div>
                <div className={styles['review-date']}>
                  <>
                    Meeting Date :{' '}
                    {dayjs(review.meeting?.meeting_date)
                      .tz(transUserTimezone)
                      .format('MMM D, YYYY, hh:mm A')}
                  </>
                </div>
                <Button
                  marginHorizontalRem={-0.6}
                  marginTop={2}
                  marginBottom={1}
                  height='4rem'
                  radius={8}
                  activeBorderColor={'var(--color-grey100)'}
                  bgColor={'#fff'}
                  activeTextColor={'var(--color-black1000)'}
                  active
                  onClick={() => toReview(review)}
                >
                  View My Review
                </Button>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <h1>No reviews yet</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default MeetingMyReview;
