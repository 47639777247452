import React from 'react';
import styles from './TextEditor.module.css';

interface TextEditorProps {
  title: string;
  content: string;
  onTitleChange: (value: string) => void;
  onContentChange: (value: string) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({
  title,
  content,
  onTitleChange,
  onContentChange,
}) => {
  return (
    <div className={styles.inputContainer}>
      <input
        id='titleInput'
        type='text'
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        className={styles.titleInput}
        placeholder='Enter title'
      />
      <textarea
        id='contentInput'
        value={content}
        onChange={(e) => onContentChange(e.target.value)}
        className={styles.contentTextarea}
        placeholder='Enter content'
      ></textarea>
    </div>
  );
};

export default TextEditor;
