import React, { useState } from 'react';
import styles from './MeetingReviewInterview.module.css';

import { useLocation, useNavigate } from 'react-router-dom';

import Header from '../../components/layout/Header';
import ReviewSelect from './components/ReviewSelect';
import Button from '../../components/element/Button';
import TextArea from '../../components/element/TextArea';
import { ProductReview } from '../../models/productReview';
import useToast from '../../hook/useToast'; // 토스트 훅 추가

function MeetingReviewReview() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast(); // 토스트 훅 초기화
  const [selectedAnswer, setSelectedAnswer] = useState<string[]>([]); // string[] 배열로 선언
  const [usefulAnswerReason, setUsefulAnswerReason] = useState<string>('');

  const productReview = location.state; // 전달된 state 데이터를 받음

  const handleSelect = (text: string) => {
    // 현재 선택된 리뷰가 클릭된 경우 선택 해제
    if (selectedAnswer.includes(text)) {
      setSelectedAnswer((prev) => prev.filter((item) => item !== text)); // 선택된 항목 제거
    } else {
      setSelectedAnswer((prev) => [...prev, text]); // 새로운 항목 추가
    }
  };

  const submitReview = async () => {
    const usefulAnswerCount = usefulAnswerReason ? 1 : 0; // 값이 있으면 1, 없으면 0
    const totalLength = selectedAnswer.length + usefulAnswerCount;
    if (!selectedAnswer.length) {
      toast('error', 'Select aspects you want to review', false);

      return;
    } else if (totalLength < 3) {
      toast('error', 'Please select up to 3', false);

      return;
    } else if (totalLength > 3) {
      toast('error', 'Please select only 3.', false);

      return;
    }
    try {
      const updatedAnswer: ProductReview = {
        ...productReview, // 기존 리뷰 데이터 유지
        useful_answer: [...selectedAnswer, usefulAnswerReason], // selectedAnswer와 usefulAnswerReason을 결합
      };
      // 다음 페이지로 업데이트된 데이터 전달
      toNext(updatedAnswer); // 상태 업데이트 후, 다음 페이지로 이동
    } catch (err) {
      toast('error', 'Something went wrong', false); // 실패 메시지 출력
    }
  };

  const toNext = (productUsefulAnswer: ProductReview) => {
    navigate('/meeting/review/usefulAnswer', { state: productUsefulAnswer });
  };

  return (
    <>
      <div className={styles['container']}>
        <Header onBack={() => navigate(-1)} />
        <div className={styles['label-container']}>{'How was Resume/Portfolio Review?'}</div>
        <div className={styles['text']}>{'What aspects were most helpful?'}</div>
        <ReviewSelect
          text='Professional wording and expressions'
          onSelect={(active, text) => handleSelect(text)}
        />
        <ReviewSelect
          text='Clear analysis of strengths and improvement areas'
          onSelect={(active, text) => handleSelect(text)}
        />
        <ReviewSelect
          text='Strategic highlighting of achievements'
          onSelect={(active, text) => handleSelect(text)}
        />
        <ReviewSelect
          text='Role and company-specific feedback'
          onSelect={(active, text) => handleSelect(text)}
        />
        <ReviewSelect
          text='Personal branding and value points'
          onSelect={(active, text) => handleSelect(text)}
        />
        <ReviewSelect
          text='Comprehensive review'
          onSelect={(active, text) => handleSelect(text)}
        />
        <ReviewSelect
          text='Document organization and flow advice'
          onSelect={(active, text) => handleSelect(text)}
        />
        <ReviewSelect
          text='Industry examples and references'
          onSelect={(active, text) => handleSelect(text)}
        />
        <TextArea
          placeholder='Additional feedback'
          useBgColor={true}
          width='unset'
          marginLeft='1.6rem'
          marginRight='1.6rem'
          height='auto'
          autoResize={true}
          radius={16}
          useCount={true}
          maxLength={500}
          value={usefulAnswerReason}
          onChange={(e) => setUsefulAnswerReason(e.target.value)}
        />
      </div>
      <div className={styles['button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={1.6}
          active={selectedAnswer.length > 0} // selectedAnswer가 하나 이상일 때만 활성화
          onClick={submitReview}
        >
          Next
        </Button>
      </div>
    </>
  );
}

export default MeetingReviewReview;
