import styles from './MeetingStatusCard.module.css';
import Button from '../../../components/element/Button';
import dayjs from 'dayjs';
import dateIcon from '../../../assets/images/ic_date.png';
import profileIcon from '../../../assets/images/ic_profile_default.png';
import { MeetingStatus } from '../../../constants/constants';
import { addMinutes, format } from 'date-fns';
import timezoneData from '../../../assets/data/timezone.json';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useGlobalStore from '../../../stores/globalStore';
interface MeetingStatusCardProps {
  productName: string;
  statusText?: MeetingStatus;
  nickName: string;
  companyName: string;
  meetingTime?: string | Date | null;
  useButtonCount: number;
  profileImage: string;
  mateTimezone: string;
  // 닉네임 밑줄 on off
  useUnderLine?: boolean;
  useBgColor?: boolean;
  useBorder?: boolean;
  actionTitle1?: string;
  actionTitle2?: string;
  productTime: number;
  actionTitle3?: string;
  onAction1?: () => void;
  onAction2?: () => void;
  onAction3?: () => void;
}

const MeetingStatusCard: React.FC<MeetingStatusCardProps> = ({
  productName = '',
  statusText = '',
  nickName = '',
  companyName = '',
  meetingTime,
  mateTimezone,
  productTime,
  profileImage,
  useButtonCount = 1,
  useUnderLine = true,
  useBgColor = false,
  actionTitle1 = '',
  actionTitle2 = '',
  actionTitle3 = '',
  onAction1,
  onAction2,
  onAction3,
}) => {
  const {user} = useGlobalStore()

  const newDate = addMinutes(new Date(meetingTime || '0').getTime(), productTime || 30);

  const formattedDate = format(newDate, 'HH:mm a'); // 'HH:mm'으로 분을 표시

  const transformTimezone = (tzCode: string): string => {
    if (!tzCode.includes(', ')) return tzCode;
    const parts = tzCode.split(', ');
    return `${parts[1]}/${parts[0]}`;
  };

  const matchedUserTimezone = timezoneData.find((tz) => tz.label === user?.timezone)!;
  const transUserTimezone = transformTimezone(matchedUserTimezone.tzCode);

  return (
    <div>
      <div
        className={`${styles['card-container']} ${
          useBgColor ? styles['bgColor'] : styles['no-bgColor']
        }`}
      >
        <div className={styles['card-row']}>
          <img
            className={styles['card-thumbnail']}
            src={profileImage === '' ? profileIcon : profileImage}
            alt=''
            width='5.6rem'
            height='5.6rem'
          />
          <div className={styles['card-meet-info-container']}>
            <div className={styles['card-row']}>
              <div className={styles['card-meet-product-name']}>
                {productName === 'COFFEECHAT' ? (productTime = 30) : productTime}min {productName}
              </div>
              {/* statusText가 있을 때만 렌더링 */}
              {statusText && <div className={styles['card-tag']}>{statusText}</div>}
            </div>
            <div
              className={styles['card-row']}
              style={{ marginBottom: '0.6rem' }}
            >
              <div
                className={`${styles['card-profile-name']} ${
                  useUnderLine ? styles['underline'] : styles['no-underline']
                }`}
              >
                {nickName}
              </div>
              <div className={styles['card-company']}>{companyName}</div>
            </div>
            <div className={styles['card-row']}>
              <img
                src={dateIcon}
                alt='달력아이콘'
              />
              <div className={styles['card-description']}>
                {!meetingTime
                  ? "Waiting for Mate's response"
                  : `${dayjs(meetingTime)
                      .tz(transUserTimezone)
                      .format('MMM DD (ddd) HH:mm A')} ~ ${dayjs(meetingTime)
                      .tz(transUserTimezone)
                      .add(productTime, 'minute')
                      .format('HH:mm A')}`}
              </div>
            </div>
          </div>
        </div>
        {useButtonCount >= 1 && (
          <div
            className={styles['card-row']}
            style={{ marginTop: '1.9rem', paddingLeft: '1.2rem', paddingRight: '1.2rem' }}
          >
            <Button
              width={'100vw'}
              height={'4rem'}
              activeBorderColor='#C9CDDA'
              activeColor='#FFFFFF'
              activeTextColor='#000000'
              activeFontSize='1.4rem'
              radius={10}
              activeFontWeight={600}
              active={true}
              onClick={onAction1}
            >
              {actionTitle1}
            </Button>
            {useButtonCount >= 2 && <div style={{ marginLeft: '0.5rem' }} />}
            {useButtonCount >= 2 && (
              <Button
                width={'100vw'}
                height={'4rem'}
                activeBorderColor='#C9CDDA'
                activeColor='#FFFFFF'
                activeTextColor='#000000'
                activeFontSize='1.4rem'
                radius={10}
                activeFontWeight={600}
                active={true}
                onClick={onAction2}
              >
                {actionTitle2}
              </Button>
            )}
          </div>
        )}
        {useButtonCount >= 3 && (
          <div
            className={styles['card-row']}
            style={{ marginTop: '0.5rem', paddingLeft: '1.2rem', paddingRight: '1.2rem' }}
          >
            <Button
              width={'100vw'}
              height={'4rem'}
              activeBorderColor='#C9CDDA'
              activeColor='#FFFFFF'
              activeTextColor='#000000'
              activeFontSize='1.4rem'
              radius={10}
              activeFontWeight={600}
              active={true}
              onClick={onAction3}
            >
              {actionTitle3}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingStatusCard;
