import React, { useState } from 'react';
import styles from './MeetingReviewService.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import TextArea from '../../components/element/TextArea';
import { ProductReview } from '../../models/productReview';
import useToast from '../../hook/useToast'; // 토스트 훅 추가
import { ToNailedit } from '../../models/toNailedit';
import etcService from '../../services/etcService';

function MeetingReviewService() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast(); // 토스트 훅 초기화
  const [naileditMsg, setNaileditMsg] = useState<string>('');
  const toNext = (productReview: ProductReview) => {
    // 리뷰 전송 로직 추가 (예: API 호출)
    // 리뷰가 성공적으로 전송되면 navigate 호출
    navigate('/meeting/myMeeting', { state: { productReview, reviewSubmitted: true } });
  };
  const productReview = location.state; // 전달된 state 데이터를 받음

  const submitNailedit = async () => {
    if (naileditMsg.length < 5) {
      toast('error', 'Please write at least 5 words', false);
      return;
    }
    try {
      const { user_id, created_date } = productReview;
      const toNailedit: ToNailedit = {
        user_id: user_id,
        to_nailedit_message: naileditMsg,
        created_date: created_date,
      };

      const response = await etcService.toNailedit(toNailedit);
      if (response) {
        toNext(productReview); // 상태 업데이트 후, 다음 페이지로 이동
        toast('success', 'Review successfully submitted', false); // 실패 메시지 출력
      }
    } catch (err) {
      toast('error', 'Something went wrong', false); // 실패 메시지 출력
    }
  };

  return (
    <>
      <div className={styles['container']}>
        <Header onBack={() => navigate(-1)} />
        <div className={styles['label-container']}>{'How was your experience?'}</div>
        <div className={styles['text']}>{'Share your experience to help us improve'}</div>

        <TextArea
          placeholder='Share your thought'
          useBgColor={true}
          width='unset'
          marginLeft='1.6rem'
          marginRight='1.6rem'
          height='auto'
          autoResize={true}
          radius={16}
          useCount={true}
          maxLength={500}
          value={naileditMsg}
          onChange={(e) => setNaileditMsg(e.target.value)}
        />
        <div className={styles['bottom-text']}>{'Submitting review confirms your purchase'}</div>
      </div>
      <div className={styles['button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={1.6}
          active={naileditMsg.length > 5}
          onClick={submitNailedit}
        >
          Submit Review
        </Button>
      </div>
    </>
  );
}

export default MeetingReviewService;
