// src/components/CommunityNotice.tsx

import React from 'react';
import styles from './CommunityNotice.module.css'; // Importing the CSS module
import { ReactComponent as MegaphoneIcon } from '../../../assets/images/ic_notice.svg'; // Importing the SVG
import { useNavigate } from 'react-router-dom';

interface CommunityNoticeProps {
  id: number;
  text: string; // text를 prop으로 받도록 인터페이스 정의
}

function CommunityNotice({ text, id }: CommunityNoticeProps) {
  const navigate = useNavigate();

  const handlePostClick = () => {
    navigate(`/community/notice/${id}`);
  };
  return (
    <div
      className={styles.centeredContainer}
      onClick={handlePostClick}
    >
      <div className={styles.noticeContainer}>
        <div className={styles.iconContainer}>
          {/* Using the imported SVG as a React component */}
          <MegaphoneIcon className={styles.icon} />
        </div>
        <span className={styles.text}>{text}</span> {/* 받은 text를 표시 */}
      </div>
    </div>
  );
}

export default CommunityNotice;
