import { useEffect, useRef, useState } from 'react';
import Header from '../../components/layout/Header';
import styles from './ListByCountry.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import categoryAllIcon from '../../assets/images/ic_category_all.png';
import nationData from '../../assets/data/nation.json';
import MateService from '../../services/mateService';
import { Mate } from '../../models/mate';
import UserService from '../../services/userService';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import bookmarkOnIcon from '../../assets/images/ic_bookmark_on.png';
import bookmarkOffIcon from '../../assets/images/ic_bookmark_off.png';
import { Sheet } from 'react-modal-sheet';
import closeIcon from '../../assets/images/ic_close.png';

const ListByCountry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const { type, search } = location.state || {}; // state에서 type과 search 값을 받아
  const [selectedTitleCountry, setSelectedTitleCountry] = useState<string>('All');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const profileListContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedNation, setSelectedNation] = useState<string>('');
  const [isLastPage, setLastPage] = useState<boolean>(false);
  const [mateProfileList, setMateProfileList] = useState<Mate[]>([]);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false); // 국제번호 바텀시트.
  const [newDataLength, setNewDataLength] = useState(0); // 새로 추가된 데이터의 길이 상태
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const loaderRef = useRef<HTMLDivElement | null>(null); // 로딩 요소 참조

  useEffect(() => {
    // 탭이 변경될 때마다 새로 데이터를 로드
    if (selectedTitleCountry) {
      loadMateProfileList(1, count, 'nation', selectedTitleCountry);
      setPage(1); // 페이지 번호를 초기화
    }
  }, [selectedTitleCountry]);

  useEffect(() => {
    if (mateProfileList.length < 5) return; // 데이터가 적을 때는 관찰 설정을 생략
    if (!loaderRef.current) return; // 로딩 참조가 없을 경우 생략

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && page && newDataLength >= 10) {
            handlePage(page + 1); // 새로운 페이지 로드
          }
        });
      },
      { root: null, threshold: 0.8 }
    );
    const timer = setTimeout(() => {
      if (loaderRef.current) {
        observer.observe(loaderRef.current); // 로딩 요소를 관찰
      }
    }, 1000); // 1초 지연

    const target = loaderRef.current;
    observer.observe(target);

    return () => {
      clearTimeout(timer); // 타이머가 설정된 경우 클리어
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current); // observer 해제
      }
    };
  }, [page, newDataLength]);

  useEffect(() => {
    if (type && search) {
      loadMateProfileList(page, count, type, search); // type과 search 값으로 데이터를 로드
      setSelectedTitleCountry(search);
    } else {
      loadMateProfileList(page, count, 'nation', 'All'); // type과 search 값으로 데이터를 로드
    }
  }, [type, search]);

  const handlePage = (newPage: number) => {
    if (newPage > 0 && newPage !== page) {
      // newPage가 유효한지 확인
      if (type && search) {
        loadMateProfileList(page + 1, count, type, search);
      } else {
        loadMateProfileList(page, count, 'nation', 'All'); // type과 search 값으로 데이터를 로드
      }
      setPage(newPage);
    }
  };

  function handleCategoryClick(nation: string) {
    // nation 클릭 시 전체 nation 데이터를 전달
    setSelectedItems(
      nationData
        .filter(
          (item) =>
            item.use === true &&
            item.disableWhenDuplicate === false &&
            item.showMeetingByLocation === true
        )
        .map((item) => item.nation)
    );
    setSelectedCategory(nation);
    setIsOpenBottomSheet(true);
  }

  function selectCountry(nation: string) {
    setSelectedNation(nation);
    loadMateProfileList(page, count, 'nation', nation);
    setSelectedTitleCountry(nation);
  }
  function handleProfileListScroll() {
    if (profileListContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = profileListContainerRef.current;
      const scrollMax = scrollWidth - clientWidth;

      if (scrollLeft >= scrollMax * 0.8) {
        if (isLastPage == false) {
          loadMateProfileList(page, count, 'nation', selectedTitleCountry);
        } else {
        }
      }
    }
  }

  async function loadMateProfileList(page: number, count: number, type: string, search: string) {
    try {
      const list = await MateService.searchBy(page, count, type, search);

      if (list && list.length > 0) {
        let updatedList = list;

        // 'All'이 아닌 경우에만 필터링
        if (search !== 'All') {
          updatedList = list.filter((item: Mate) => item.user?.country_name === search);
        }

        // 페이지별 데이터 처리
        if (page === 1) {
          setMateProfileList(updatedList);
        } else {
          setMateProfileList((prevData) => [
            ...prevData,
            ...updatedList.filter(
              (newItem: Mate) => !prevData.some((prevItem) => prevItem.id === newItem.id)
            ),
          ]);
        }

        // 새로운 데이터 길이 업데이트
        setNewDataLength(updatedList.length);
      } else {
        // 데이터가 없는 경우 처리
        setNewDataLength(0);
      }
    } catch (err) {
      console.error('Error loading mate profiles:', err);
    }
  }

  function getProfileImageFile(mate: Mate) {
    if (mate.profile_image !== '') {
      //메이트 프로필 이미지가 존재하는 경우.
      return mate.profile_image
        ? `${process.env.REACT_APP_STORE_ADDRESS}/${mate.profile_image}`
        : profileDefaultIcon;
    } else if (mate.user?.profile_image !== '') {
      //유저 프로필 이미지가 존재하는 경우.
      return mate.user?.profile_image
        ? `${process.env.REACT_APP_STORE_ADDRESS}/${mate.user?.profile_image}`
        : profileDefaultIcon;
    }

    return undefined;
  }

  function goMateProfilePage(id: number) {
    navigate(`/mate/detail/${id}`);
  }

  async function toggleBookmark(mate: Mate) {
    if (mate.favorite === true) {
      await UserService.deleteMateFavorite(mate.id!);
      mate.favorite = false;
    } else {
      await UserService.addMateFavorite(mate.id!);
      mate.favorite = true;
    }
    const newMateProfileList = [...mateProfileList];
    newMateProfileList.find((item) => item.id === mate.id)!.favorite = mate.favorite;
    setMateProfileList(newMateProfileList);
  }

  return (
    <div
      className={styles['container']}
      style={{ position: 'relative' }}
      id='container'
    >
      <Header
        useBackButton={true}
        title='Meetings by Location'
        onBack={() => navigate(-1)}
      />
      <div className={styles['category-bar']}>
        <img
          src={categoryAllIcon}
          alt=''
          onClick={() => handleCategoryClick('All')}
        />
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('All')}
        >
          <div className={selectedTitleCountry === 'All' ? styles['category-item-active'] : ''}>
            {'All'}
          </div>
          <div className={selectedTitleCountry === 'All' ? styles['category-active'] : ''} />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('United States')}
        >
          <div
            className={
              selectedTitleCountry === 'United States' ? styles['category-item-active'] : ''
            }
          >
            {'USA'}
          </div>
          <div
            className={selectedTitleCountry === 'United States' ? styles['category-active'] : ''}
          />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('Japan (日本)')}
        >
          <div
            className={
              selectedTitleCountry === 'Japan (日本)' ? styles['category-item-active'] : ''
            }
          >
            {'Japan'}
          </div>
          <div
            className={selectedTitleCountry === 'Japan (日本)' ? styles['category-active'] : ''}
          />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('Austraila')}
        >
          <div
            className={selectedTitleCountry === 'Austraila' ? styles['category-item-active'] : ''}
          >
            {'Austraila'}
          </div>
          <div className={selectedTitleCountry === 'Austraila' ? styles['category-active'] : ''} />
        </div>
        <div
          className={styles['category-item']}
          onClick={() => selectCountry('Singapore')}
        >
          <div
            className={selectedTitleCountry === 'Singapore' ? styles['category-item-active'] : ''}
          >
            {'Singapore'}
          </div>
          <div className={selectedTitleCountry === 'Singapore' ? styles['category-active'] : ''} />
        </div>
      </div>
      <div
        className={styles['profile-list-container']}
        ref={profileListContainerRef}
        onScroll={handleProfileListScroll}
      >
        {mateProfileList.map((e) => (
          <div
            className={styles['profile-list-item']}
            onClick={(ev) => {
              ev.stopPropagation();
              goMateProfilePage(e.id!);
            }}
          >
            <div className={styles['profile-item-thumbnail']}>
              <img
                src={getProfileImageFile(e) ?? profileDefaultIcon}
                alt=''
              />
            </div>
            <div className={styles['profile-item-container']}>
              <div className={styles['flex']}>
                <div className={styles['profile-item-tag']}>{e.user?.annual}years</div>
                <div className={styles['profile-item-tag']}>{e.user?.job}</div>

                <div className={styles['profile-item-tag']}>{e.user?.country_name}</div>
              </div>

              <div className={styles['profile-item-nickname']}>{e.user?.nickname}</div>
              <div className={styles['profile-item-company']}>{e.user?.company}</div>
              <div className={styles['profile-item-message']}>{e.user?.introduce_message}</div>
            </div>
            <div className={styles['profile-item-bookmark']}>
              <img
                src={e.favorite === true ? bookmarkOnIcon : bookmarkOffIcon}
                alt=''
                onClick={(ev) => {
                  ev.stopPropagation();
                  toggleBookmark(e);
                }}
              />
            </div>
          </div>
        ))}
        <div
          style={{ height: '0.1rem' }}
          ref={loaderRef}
        ></div>
      </div>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setIsOpenBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.6rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-terms-title']}>Select Country</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setIsOpenBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content-container']}>
                <div
                  className={`${styles['sheet-terms-content']} ${
                    selectedTitleCountry === 'All' ? styles['active'] : ''
                  }`}
                  onClick={() => selectCountry('All')}
                >
                  <div className={styles['circle']}></div>

                  <span>{'All'}</span>
                </div>
                {selectedItems.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles['sheet-terms-content']} ${
                      selectedTitleCountry === item ? styles['active'] : ''
                    }`}
                    onClick={() => selectCountry(item)}
                  >
                    <div className={styles['circle']}></div>

                    <span>{item || 'All'}</span>
                  </div>
                ))}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setIsOpenBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
};

export default ListByCountry;
