import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './WithdrawRequestConfirm.module.css';
import infoIcon from '../../assets/images/ic_info.png';
import { UserWithdrawal } from '../../models/userWithdrawal';
import tzData from '../../assets/data/timezone.json';
import { UserWithdrawalStatus, WithDrawalMethod } from '../../constants/constants';
import settlementService from '../../services/settlementService';
import { addMonths, endOfMonth, format } from 'date-fns';
import useGlobalStore from '../../stores/globalStore';

function WithdrawRequestConfirm() {
  const navigate = useNavigate();
  const location = useLocation();
  const amount = location.state.amount;
  const withdrawList = location.state.method;
  const { user, setUser } = useGlobalStore();

  const userTimezoneData = tzData.find((tz) => tz['label'] === user?.timezone);

  const toCompleted = () => {
    navigate('/calculation/request/withdraw/completed');
  };

  const date = new Date();
  const requestDate = format(new Date(), 'MMM dd, yyyy');

  // 신청일 기준 Payment Period 계산
  const currentDay = date.getDate();
  const isCurrentMonth = currentDay <= 20;
  const paymentMonth = isCurrentMonth ? date : addMonths(date, 1);

  const paymentStartDate = format(
    new Date(paymentMonth.getFullYear(), paymentMonth.getMonth(), 25),
    'MMM dd yyyy'
  );
  const paymentEndDate = format(endOfMonth(paymentMonth), 'MMM dd, yyyy');
  const paymentPeriod = `${paymentStartDate.split(' ')[0]} ${paymentStartDate.split(' ')[1]}-${
    paymentEndDate.split(' ')[1]
  } ${paymentStartDate.split(' ')[2]}`;

  const requestWithdrawal = async () => {
    try {
      const userWithdrawal: UserWithdrawal = {
        user_id: 1,
        name: withdrawList.recipient_name || withdrawList.account_name,
        email: withdrawList.email,
        invoice_number: withdrawList.invoice_number,
        currency: withdrawList.currency,
        country_name: withdrawList.nationality,
        amount: amount,
        withdrawal_method: withdrawList.withdrawal_method,
        swift_transfer_option: withdrawList.swift_transfer_fee_option,
        exchange_rate: 0,
        transfer_tax_rate: 0,
        transfer_fee: withdrawList.transfer_fee || 0,
        other_expenses: 0,
        // 인출한 결과로 나오는 거라서
        transaction_id: '',
        status: UserWithdrawalStatus.APPROVING,
        status_message: '',
        user_withdrawal_method_id: withdrawList.user_withdrawl_method_id,
        expected_deposit_start_date: new Date(),
        expected_deposit_end_date: new Date(),
        transaction_date: null, // null 허용
        request_date: new Date(),
        created_date: new Date(),
      };
      const response = await settlementService.requestWithdrawal(userWithdrawal);
      if (response) {
        navigate('/calculation/request/withdraw/completed');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className={styles['container']}>
        <Header onBack={() => navigate(-1)} />
        <div className={styles['text']}>{'Review and confirm'}</div>

        <div className={styles['confirm-container']}>
          <div className={styles['confirm-title']}>
            <span>Amount (before fees)</span>
            <span>Date requested</span>
            <span>Payment period</span>
            <span>Payout method</span>
            <span>Currency</span>
            <span>Recipient Name</span>
            <span>Email</span>
          </div>
          <div className={styles['confirm-data']}>
            <span>{`$${amount}`}</span>
            <span>
              {requestDate} {userTimezoneData?.tz}
            </span>
            <span>{paymentPeriod}</span>
            <span>{withdrawList.withdrawal_method}</span>
            <span>{withdrawList.currency || 'PAYPAL'}</span>
            <span>{withdrawList.recipient_name}</span>
            <span>{withdrawList.email}</span>
          </div>
        </div>
      </div>
      <div className={styles['button-container']}>
        <div className={styles['info-container']}>
          <img
            src={infoIcon}
            alt=''
          />
          <ul>
            <li>PayPal Transfer fee: Charged to recipient</li>
            <li>Verify recipient details</li>
            <li>Submit by the 20th (UTC+9:00)</li>
          </ul>
        </div>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={true}
          onClick={requestWithdrawal}
        >
          Get your earnings
        </Button>
      </div>
    </>
  );
}

export default WithdrawRequestConfirm;
