import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './CalculationRequestAddInformation.module.css';
import infoIcon from '../../assets/images/ic_info.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import RecipientInformationCard from './components/RecipientInformationCard';
import RecipientInformation from './components/RecipientInformation';
import { UserWithdrawalMethod } from '../../models/userWithdrawalMethod';
import useGlobalStore from '../../stores/globalStore';
import useToast from '../../hook/useToast';

function CalculationRequestAddInformation() {
  const [recipientData, setRecipientData] = useState<UserWithdrawalMethod>({});
  const location = useLocation();
  const { methodData, selectedSettlement } = location.state || {};
  const { tempUserWithdrawalMethod, setTempUserWithdrawalMethod } = useGlobalStore();
  const [errors, setErrors] = useState<string[]>([]);
  // selectedType을 사용하여 필요한 작업 수행
  const navigate = useNavigate();
  const toast = useToast(); // 토스트 훅 초기화
  const { id } = useParams<{ id?: string }>();
  // useEffect(() => {
  //   console.log('Selected ID:', id);
  //   // ID를 기반으로 데이터 로드
  // }, [id]);

  const toRequest = () => {
    if (!id && errors.length === 0) {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        user_id: methodData.id,
        country_code: methodData.country_code,
        nationality: methodData.nationality,
        withdrawal_method: methodData.withdrawal_method,
        email: recipientData.email,
        mobile_number: recipientData.mobile_number,
        bank_code: recipientData.bank_code,
        account_number: recipientData.account_number,
        account_name: recipientData.account_name,
        currency: recipientData.currency,
        recipient_type: recipientData.recipient_type,
        iban: recipientData.iban,
        country_region: recipientData.country_region,
        street_address: recipientData.street_address,
        city: recipientData.city,
        state_province: recipientData.state_province,
        post_code: recipientData.post_code,
        swift_code_bic: recipientData.swift_code_bic,
        branch_code: recipientData.branch_code,
        id_type: recipientData.id_type,
        id_number: recipientData.id_number,
        account_type: recipientData.account_type,
        bsb_routing_number: recipientData.bsb_routing_number,
        ifsc_code: recipientData.ifsc_code,
        ach_routing_number: recipientData.ach_routing_number,
        sort_code: recipientData.sort_code,
        financial_institution_number: recipientData.financial_institution_number,
        transit_number: recipientData.transit_number,
        tax_rate: recipientData.tax_rate,
        swift_code: recipientData.swift_code,
        swift_transfer_fee_option: recipientData.swift_transfer_fee_option,
        recipient_name: recipientData.account_name,

        created_date: new Date(),
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });
      navigate('/calculation/request/method/add', {
        state: { methodData: tempUserWithdrawalMethod, selectedSettlement },
      });
    } else if (id && errors.length === 0) {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        user_id: methodData.id,
        country_code: methodData.country_code,
        nationality: methodData.nationality,
        withdrawal_method: methodData.withdrawal_method,
        email: recipientData.email,
        mobile_number: recipientData.mobile_number,
        bank_code: recipientData.bank_code,
        account_number: recipientData.account_number,
        account_name: recipientData.account_name,
        currency: recipientData.currency,
        recipient_type: recipientData.recipient_type,
        iban: recipientData.iban,
        country_region: recipientData.country_region,
        street_address: recipientData.street_address,
        city: recipientData.city,
        state_province: recipientData.state_province,
        post_code: recipientData.post_code,
        swift_code_bic: recipientData.swift_code_bic,
        branch_code: recipientData.branch_code,
        id_type: recipientData.id_type,
        id_number: recipientData.id_number,
        account_type: recipientData.account_type,
        bsb_routing_number: recipientData.bsb_routing_number,
        ifsc_code: recipientData.ifsc_code,
        ach_routing_number: recipientData.ach_routing_number,
        sort_code: recipientData.sort_code,
        financial_institution_number: recipientData.financial_institution_number,
        transit_number: recipientData.transit_number,
        tax_rate: recipientData.tax_rate,
        swift_code: recipientData.swift_code,
        swift_transfer_fee_option: recipientData.swift_transfer_fee_option,
        recipient_name: recipientData.account_name,

        created_date: new Date(),
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });
      navigate(`/calculation/request/method/${id}`, {
        state: { methodData: tempUserWithdrawalMethod, selectedSettlement },
      });
    } else {
      toast('error', 'Please fill in all required fields.', false);
    }
  };

  return (
    <>
      <div className={styles['container']}>
        <Header onBack={() => navigate(-1)} />
        <div className={styles['text']}>
          {selectedSettlement.subTemplate}
          <br />
        </div>
        <div className={styles['title']}>{'Recipient Information'}</div>
        <RecipientInformation
          onRecipientDataChange={(data, validationErrors) => {
            setRecipientData(data);
            setErrors(validationErrors);
          }}
        />
        <div className={styles['info']}>
          {'Please check to ensure all the information is correct.'}
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          width='unset'
          active={errors.length === 0}
          onClick={toRequest}
        >
          Register
        </Button>
      </div>
    </>
  );
}

export default CalculationRequestAddInformation;
