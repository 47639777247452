import React from 'react';
import styles from './FloatingButton.module.css';
import { MdAdd } from 'react-icons/md'; // IconType 인터페이스 가져오기
import { Link } from 'react-router-dom';
import addIcon from '../../../assets/images/ic_add2.png';

const FloatingButton: React.FC = () => {
  return (
    <Link
      to='/community/posting'
      style={{ textDecoration: 'none' }}
    >
      <button className={styles.fab}>
        <img
          src={addIcon}
          alt=''
        />
      </button>
    </Link>
  );
};

export default FloatingButton;
