import React, { useState, useEffect, useRef } from 'react';
import styles from './TextArea.module.css';

interface TextAreaProps {
  enable?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
  placeholder?: string;
  radius?: number;
  width?: string;
  disable?: boolean;
  border?: string;
  height?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  fontSize?: string;
  fontWeight?: number;
  useBgColor?: boolean;
  useCount?: boolean; // 추가된 카운터
  error?: boolean;
  autoResize?: boolean; // 자동 조절 모드 여부
  useFocus?: boolean; // 포커스 모드 추가
  [key: string]: any; // 기타 props를 처리할 수 있도록 추가.
}

const TextArea: React.FC<TextAreaProps> = ({
  enable = true,
  value,
  onChange,
  maxLength,
  placeholder,
  radius = 12,
  width,
  border,
  disable = false,
  height = 'auto',
  marginLeft = '0rem',
  marginRight = '0rem',
  paddingLeft = '1.6rem',
  paddingRight = '1.6rem',
  paddingTop = '1.4rem',
  paddingBottom = '1.4rem',
  fontSize = '1.5rem',
  fontWeight = 500,
  useBgColor = false,
  error = false,
  useCount = false,
  autoResize = false, // 기본값은 false
  useFocus = false, // 기본값은 false
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event);
    }
    if (autoResize) {
      resizeTextarea(); // 자동 조절 모드일 때만 높이 조정
    }
  };

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // 초기 높이를 auto로 설정하여 크기를 재조정
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 내용에 따라 높이 조절
    }
  };

  useEffect(() => {
    if (autoResize) {
      resizeTextarea(); // 초기 렌더링 시 높이 조정
    }
  }, [value, autoResize]);

  return (
    <div>
      <div
        className={`${styles['container']} ${
          error ? styles['container-error'] : isFocused ? styles['container-focused'] : ''
        }`}
        style={{
          borderRadius: `${radius}px`,
          height: height,
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
          paddingTop: paddingTop,
          paddingBottom: paddingBottom,
          marginLeft: marginLeft,
          marginRight: marginRight,
          width: width,
          border: useFocus && isFocused ? '1.5px solid var(--color-green300)' : border,
          backgroundColor: useBgColor ? 'var(--color-white50)' : '',
        }}
      >
        <textarea
          ref={textareaRef}
          readOnly={!enable} // 모드에 따라 읽기 전용 설정
          className={styles['input-field']}
          style={{
            fontSize: fontSize,
            fontWeight: fontWeight,
            backgroundColor: useBgColor ? 'var(--color-white50)' : '',
            overflow: autoResize ? 'hidden' : 'auto', // 자동 조절 모드일 때 스크롤 방지
          }}
          value={value != undefined ? value : ''}
          rows={1} // 기본 행 수를 1로 설정
          maxLength={maxLength}
          onChange={handleInputChange}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          placeholder={placeholder}
          {...rest}
          disabled={disable ? true : false}
        />
      </div>
      {useCount && maxLength && (
        <div className={styles['count-container']}>
          <div className={styles['count']}>
            <div className={styles['value']}>{value ? value.length : 0}</div>/ {maxLength}
          </div>
        </div>
      )}
    </div>
  );
};

export default TextArea;
