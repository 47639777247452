import React, { useEffect, useState } from 'react';
import { Sheet } from 'react-modal-sheet';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import closeIcon from '../../assets/images/ic_close.png';
import Header from '../../components/layout/Header';
import styles from './MateDetail.module.css';
import SearchResultCard from '../search/components/SearchResultCard';
import DiscountCoupon from '../search/components/DiscountCoupon';
import ReviewSelect from '../meeting/components/ReviewSelect';
import Button from '../../components/element/Button';
import starIcon from '../../assets/images/ic_star.png'; // Ratings 아이콘 경로 추가
import ReviewList from '../meeting/components/ReviewList';
import ProductCard from '../search/components/ProductCard';
import productService from '../../services/productService';
import { ProductReview } from '../../models/productReview';
import { Coupon } from '../../models/coupon';
import userService from '../../services/userService';
import { Product } from '../../models/product';
import meetingService from '../../services/meetingService';
import useToast from '../../hook/useToast'; // 토스트 훅 추가
import { Meeting } from '../../models/meeting';
import { Mate } from '../../models/mate';
import mateService from '../../services/mateService';
import recommendImg from '../../assets/images/ic_positive.png';
import notRecommendImg from '../../assets/images/ic_negative.png';
import checkImg from '../../assets/images/ic_check_default.png';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import { User } from '../../models/user';
import useGlobalStore from '../../stores/globalStore';

function MateDetail() {
  const { id } = useParams<{ id: string }>(); // URL에서 id를 추출
  const navigate = useNavigate();
  const toast = useToast();
  const [openMyCoupon, setOpenMyCoupon] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [openModify, setOpenModify] = useState(false);
  const [activeTab, setActiveTab] = useState('info'); // 기본적으로 'info' 탭 활성화
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [productReview, setProductReview] = useState<ProductReview[]>([]);
  const [couponData, setCouponData] = useState<Coupon[]>([]);
  const [meetingList, setMeetingList] = useState<Meeting[]>([]);
  const [mateData, setMateData] = useState<Mate | null>(null);
  const { user, setUser } = useGlobalStore();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // 화면 크기 변경 감지
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // 초기 상태 설정
    handleResize();

    // 리스너 등록
    window.addEventListener('resize', handleResize);

    // 리스너 정리
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    meetingDatas(Number(id));
    mateList(Number(id)); // 컴포넌트 마운트 시 데이터 가져오기
  }, []);

  useEffect(() => {
    if (mateData?.user_id) {
      productReviews(mateData.user_id);
    }
  }, [mateData]);

  const mateList = async (mateId: number) => {
    try {
      const mateListData = await mateService.profile(mateId);
      if (mateListData && mateListData.id) {
        setMateData(mateListData);
      }
    } catch (err) {
      console.log(err);
      toast('error', 'Something went wrong.');
    }
  };

  const goModify = (id: number) => {
    navigate(`/mate/introduce/${id}`);
  };
  const goTimeslot = () => {
    navigate('/mate/meetingTime');
  };

  const meetingDatas = async (id: number) => {
    try {
      const meetingList = await meetingService.getMeetingByMate(Number(id));
      if (meetingList && meetingList.length > 0) {
        setMeetingList(meetingList);
      }
    } catch (err) {
      console.log(err);
      toast('error', 'Something went wrong.');
    }
  };

  async function productReviews(id: number) {
    try {
      const productReviewList = await productService.getMateReviews(id, false);
      if (productReviewList && productReviewList.length > 0) {
        setProductReview(productReviewList);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function couponList(type: string) {
    try {
      const couponListData = await userService.couponList(type);
      if (couponListData && couponListData.length > 0) {
        setCouponData(couponListData);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    couponList('price');
  }, []);

  const activeBtn = (id: number, mateData: Mate, meetingList: Meeting[], productType: string) => {
    const selected = mateData.products.find((product: Product) => product.type === productType);

    if (!selected) {
      toast('error', `You already have a ${productType} meeting`, false);
    } else {
      setSelectedProduct(selected.id);
      navigate(`/searchResult/product/${mateData.user_id!}`, {
        state: {
          mateData,
          meetingList,
          couponData,
          selectedProduct: selected, // Selected product추가
          // mateData,
        },
      });
    }
  };

  const openMyCouponSheet = () => {
    setOpenMyCoupon(!openMyCoupon); // 현재 상태의 반대로 변경
  };
  const openProductSheet = () => {
    setOpenProduct(!openProduct); // 현재 상태의 반대로 변경
  };

  const useCoupon = () => {
    // navigate(`/my/coupon`);
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab); // 클릭한 탭으로 상태 변경
  };
  const renderRating = (rating: number) => {
    const roundedRating = Math.round(rating); // 별점 개수를 위한 반올림
    return (
      <div className={styles['rating-container']}>
        {[...Array(roundedRating)].map((_, index) => (
          <img
            key={index}
            src={starIcon}
            alt='Ratings'
            className={styles['star-icon']}
          />
        ))}
      </div>
    );
  };

  const totalMeetings =
    (mateData?.buyer_count_coffeechat || 0) +
    (mateData?.buyer_count_interview || 0) +
    (mateData?.buyer_count_resume || 0);

  const totalReview = (mateData?.products || []).reduce((acc: number, product: Product) => {
    // review가 배열인지 확인하고, hide가 true인 리뷰는 제외
    const validReviewsCount = Array.isArray(product.review)
      ? product.review.filter((review) => !review.hide).length
      : 0;

    return acc + validReviewsCount;
  }, 0);

  // productReview 배열에서 score 값을 추출하여 총합을 구하기
  const totalScore = productReview.reduce((sum, review) => sum + (review.score || 0), 0);

  // 리뷰의 개수 구하기
  const numberOfScores = productReview.length; // 배열의 길이로 리뷰 개수 계산

  // 평균 점수 계산
  const averageScore = numberOfScores > 0 ? Math.round((totalScore / numberOfScores) * 10) / 10 : 0;
  const productType = mateData?.products;

  const goReview = () => {
    navigate(`/searchResult/review/${id}`, {
      state: { productReview, productType, mateData },
    });
  };

  const goProfile = (mate: Mate) => {
    navigate(`/mate/profile`, {
      state: mate,
    });
  };

  return (
    <div
      className={styles['container']}
      style={{ position: 'relative' }}
      id='container'
    >
      <Header
        useBackButton={true}
        title={"Mate's Profile"}
        useReportButton={true}
        useBookmarkButton={true}
        useShareButton={false}
        onBack={() => navigate(-1)}
        onReportAction={() => goProfile(mateData!)}
        favorite={mateData?.favorite}
      />
      <SearchResultCard
        nickname={mateData?.user?.nickname!} // 메이트의 닉네임
        annual={mateData?.user?.annual}
        company={mateData?.user?.company!} // 회사명
        job={mateData?.user?.job!} // 직무 및 경력
        profileImage={
          mateData?.profile_image
            ? `${process.env.REACT_APP_STORE_ADDRESS}/${mateData?.profile_image}`
            : mateData?.user?.profile_image
            ? `${process.env.REACT_APP_STORE_ADDRESS}/${mateData?.user.profile_image}`
            : profileDefaultIcon
        }
        nation={mateData?.user?.country_name}
        introduceMessage={mateData?.user?.introduce_message || ''}
        tag={mateData?.hash_tag}
        count_total={mateData?.products?.buyer_count_total ?? 0} // 기본값 제공
        count_coffeeChat={mateData?.buyer_count_coffeechat ?? 0} // 첫 번째 인덱스 접근 시 기본값 설정
        count_interview={mateData?.buyer_count_interview ?? 0} // 두 번째 인덱스 접근 시 기본값 설정
        count_resume={mateData?.buyer_count_resume ?? 0} // 세 번째 인덱스 접근 시 기본값 설정
        price={mateData?.products?.final_price ?? 0}
        rating={averageScore}
        review={totalReview}
        favorite={mateData?.products?.favorite}
        useRating={true}
        useFontColor={true}
        useBorder={false}
        useTypeBox={true}
        nicknameFontSize={1.8}
        companyFontSize={1.6}
        goReview={goReview}
      />

      <DiscountCoupon
        text={couponData.length > 0 ? couponData[0].name : 'Coupon Box Empty'}
        discount={couponData.length > 0 ? couponData[0].discount_rate : 0}
        start={couponData.length > 0 ? couponData[0].start_date : null}
        end={couponData.length > 0 ? couponData[0].end_date : null}
        onClick={openMyCouponSheet}
        useBgColor={true}
        deadline={false}
      />
      <div className={styles['tab']}>
        <div
          className={`${styles['title']} ${
            activeTab === 'info' ? styles['active'] : styles['inactive']
          }`}
          onClick={() => handleTabClick('info')}
        >
          {'Mate Profile'}
        </div>
        <div
          className={`${styles['title']} ${
            activeTab === 'review' ? styles['active'] : styles['inactive']
          }`}
          onClick={() => handleTabClick('review')}
        >
          {'Reviews'}
        </div>
      </div>
      <div className={styles['content-container']}>
        {/* 탭에 따라 조건부 렌더링 */}
        {activeTab === 'info' && (
          <div className={styles['info-container']}>
            <div className={styles['text']}>{mateData?.introduce_message}</div>
            <div className={styles['info-sub-container']}>
              <div className={styles['sub-text']}>{mateData?.my_career_message}</div>
              {mateData?.donate_user === true && (
                <div className={styles['info-subject-container']}>
                  <img
                    src={checkImg}
                    alt=''
                  />
                  <span>{'This mate donates their profit to Nailedit'}</span>
                </div>
              )}
            </div>

            <div className={styles['info-meeting-container']}>
              <div className={styles['text']}>{'Suggested Topics'}</div>
              <div className={styles['sub-text']}>
                {'Feel free to ask about other subjects or add your own'}
              </div>
              {mateData?.chat_subject.map((subject: string, index: number) => (
                <div
                  className={styles['info-subject-container']}
                  key={index}
                >
                  <img
                    src={checkImg}
                    alt=''
                  />
                  <span>{subject}</span>
                </div>
              ))}
            </div>
            <div className={styles['info-help-container']}>
              <div className={styles['text']}>{'Who would benefit from this'}</div>
              <div className={styles['info-recommend-container']}>
                <img
                  src={recommendImg}
                  alt='recommendImg'
                />
                {mateData?.recommend_message}
              </div>

              {/*
            <div className={styles['text']}>{'Who might not benefit from this'}</div>
            <div className={styles['info-recommend-container']}>
              <img
                src={notRecommendImg}
                alt='recommendImg'
              />
              {mateData?.not_recommend_reason_message}
            </div>
            */}
            </div>
            <div className={styles['info-notice-container']}>
              <div className={styles['text']}>{'Meeting Guide'}</div>
              <ul className={styles['list-title']}>
                {'With Nailedit, you can meet mates through:'}
                <li>
                  {
                    'Coffee Chat: Discuss overseas employment or living topics the mate is knowledgeable about, or ask any questions you have.'
                  }
                </li>
                <li>
                  {
                    'Interview Practice: Connect with a professional in your field, share your resume and situation, and participate in a practice interview with feedback.'
                  }
                </li>
                <li>
                  {
                    'Resume/Portfolio Review: Get your resume/portfolio reviewed by a professional to align it with your goals and receive improvement suggestions.'
                  }
                </li>
              </ul>
              <ul className={styles['list-title']}>
                {'Duration Time & Note:'}
                <li>{'Coffee Chat(30 mins)'}</li>
                <li>{'Interview Practice & Resume Review(1 hr)'}</li>
                <li>{'Actual duration may vary based on session progress.'}</li>
                <li>
                  {'Note that feedback may vary based on individual experiences and perspectives.'}
                </li>
              </ul>
            </div>
          </div>
        )}

        {activeTab === 'review' && (
          <div className={styles['review-container']}>
            <div className={styles['review-number-container']}>
              <div className={styles['review-number-box']}>
                {totalMeetings}
                <div className={styles['review-number-type']}>
                  {mateData?.products.map((item: Product) => (
                    <div key={item.id}>
                      <span>
                        {item.type === 'COFFEECHAT'
                          ? 'Chat'
                          : item.type === 'INTERVIEW'
                          ? 'Interview'
                          : item.type === 'REVIEW'
                          ? 'Resume'
                          : ''}
                        <span className={styles['type-line']}></span>
                      </span>
                    </div>
                  ))}
                </div>
                <div className={styles['review-number-text']}>{totalMeetings} Total Meetings</div>
              </div>
              <div className={styles['review-number-box']}>
                {averageScore}
                <div className={styles['review-number-rating']}>
                  {renderRating(averageScore ?? 0)}
                </div>
                <div className={styles['review-number-text']}>{totalReview} Total Reviews</div>
              </div>
            </div>
            <ReviewList
              reviews={productReview}
              useTypeText={true}
              text='See More Reviews'
              padding={0}
              useInfo={true}
              borderColor={'none'}
              marginTop={2.4}
              marginBottom={2.4}
            />
          </div>
        )}
      </div>

      {user === null ? (
        ''
      ) : (
        <>
          {user?.id === mateData?.user_id ? (
            <>
              <div className={styles['mate-button-container']}>
                <Button
                  width='100%'
                  active={true}
                  onClick={() => goModify(mateData?.id!)}
                  marginLeft={1.6}
                >
                  Modify profile
                </Button>
                <Button
                  width='100%'
                  active={true}
                  onClick={goTimeslot}
                  marginRight={1.6}
                >
                  Modify timeslot
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles['button-container']}>
                <Button
                  width='100%'
                  marginHorizontalRem={2}
                  active={true}
                  onClick={openProductSheet}
                >
                  Propose a Meeting
                </Button>
              </div>
            </>
          )}
        </>
      )}

      <Sheet
        isOpen={openMyCoupon}
        detent='content-height'
        onClose={() => setOpenMyCoupon(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '41.1rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>My Coupons</span>
              <img
                src={closeIcon}
                alt='close Icon'
                onClick={() => setOpenMyCoupon(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              {couponData.map((coupon: Coupon) => (
                <DiscountCoupon
                  text={coupon.name}
                  discount={coupon.discount_rate}
                  start={coupon.start_date}
                  end={coupon.end_date}
                  onClick={useCoupon}
                  // useCoupon={true}
                  deadline={true}
                />
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenMyCoupon(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
      <Sheet
        isOpen={openProduct}
        detent='content-height'
        onClose={() => setOpenProduct(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '41.1rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Available Meetings</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenProduct(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              {mateData?.products.map((product: Product, index: number) => (
                <ProductCard
                  key={index}
                  nickname={mateData?.user?.nickname || ''}
                  type={product.type}
                  minute={product.time}
                  price={product.price}
                  review={product.review_count!}
                  rating={product.score!}
                  coupon={product.coupon ? true : false}
                  couponPrice={product.price}
                  onClick={() =>
                    activeBtn(mateData?.user?.id!, mateData, meetingList, product.type)
                  }
                  active={product.id === selectedProduct}
                />
              ))}
              {/* {productList.map((product: Product) => (
                <ProductCard
                  key={product.id}
                  nickname={mateData.nickname}
                  type={product.type}
                  minute={product.time}
                  price={product.price}
                  review={product.review_count!}
                  rating={product.score!}
                  coupon={product.coupon ? true : false}
                  couponPrice={product.price}
                  onClick={() =>
                    activeBtn(
                      mateData.user.nickname,
                      product,
                      meetingList,
                      product.type
                    )
                  }
                  active={selectedProduct === product.id}
                />
              ))} */}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenProduct(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
}

export default MateDetail;
