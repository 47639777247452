import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import styles from './CalculationRequest.module.css';
import notice from '../../assets/images/ic_notice.png';
function CalculationRequest() {
  const navigate = useNavigate();
  const toSelect = () => {
    navigate('/calculation/request/select');
  };

  return (
    <>
      <div className={styles['container']}>
        <Header onBack={() => navigate(-1)} />
        <div className={styles['title']}>{'Get your earnings'}</div>
        <div className={styles['text']}>{'Add a payout method to withdraw earnings.'}</div>
        <div className={styles['info-box']}>
          <img
            src={notice}
            alt=''
          />
          <div className={styles['info-title']}>{'Pre-Withdrawal Notes:'}</div>
          <div className={styles['info-text']}>
            {
              'Tax information:\r\n- Your Nailedit earnings count as income\r\n- All earnings need to be reported for taxes\r\n- Tax rates depend on your total income\r\n'
            }
          </div>
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          width='unset'
          marginHorizontalRem={1.6}
          active={true}
          onClick={toSelect}
        >
          Add a payout method to start
        </Button>
      </div>
    </>
  );
}

export default CalculationRequest;
