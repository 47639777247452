import React from 'react';
import profile from '../../../assets/images/ic_profile_default.png';
import starIcon from '../../../assets/images/ic_star.png';
import Button from '../../../components/element/Button';
import { useNavigate } from 'react-router-dom';
import styles from './ReviewList.module.css';
import { ProductReview } from '../../../models/productReview';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import useGlobalStore from '../../../stores/globalStore';
import timezoneData from '../../../assets/data/timezone.json';
interface ReviewListProps {
  text: string;
  reviews: ProductReview[];
  marginLeft?: number;
  marginRight?: number;
  borderColor?: string;
  borderRadius?: number;
  padding?: number;
  marginTop?: number;
  marginBottom?: number;
  profileImageSize?: number;
  useTypeText?: boolean;
  fontSize?: number;
  ratingFontSize?: number;
  useInfo?: boolean; // 새로 추가된 prop
}

const ReviewList: React.FC<ReviewListProps> = ({
  text,
  reviews,
  useTypeText = false,
  marginLeft = 1.6,
  marginRight = 1.6,
  borderColor = 'var(--color-grey100)',
  borderRadius = 14,
  padding = 1.6,
  marginTop = 1,
  marginBottom = 1.2,
  profileImageSize = 2.4,
  fontSize = 1.4,
  ratingFontSize = 1.2,
  useInfo = false, // 기본값을 true로 설정
}) => {
  const navigate = useNavigate();
  const { user } = useGlobalStore();

  const transformTimezone = (tzCode: string): string => {
    if (!tzCode.includes(', ')) return tzCode;
    const parts = tzCode.split(', ');
    return `${parts[1]}/${parts[0]}`;
  };

  const matchedUserTimezone = timezoneData.find((tz) => tz.label === user?.timezone)!;
  const transUserTimezone = transformTimezone(matchedUserTimezone.tzCode);

  const renderRating = (rating: number) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: `${ratingFontSize}rem`,
        fontWeight: 600,
      }}
    >
      {[...Array(rating)].map((_, index) => (
        <img
          key={index}
          src={starIcon}
          alt='Ratings'
          style={{
            width: `${ratingFontSize}rem`,
            height: `${ratingFontSize}rem`,
            marginRight: '0.2rem',
          }}
        />
      ))}
      {rating}
    </div>
  );

  const toReview = (review: ProductReview) => {
    navigate(`/meeting/myReview/detail`, { state: { review } });
  };

  const typeBoxStyle = {
    display: useTypeText ? 'block' : 'none',
  };

  return (
    <div
      style={{
        marginLeft: `${marginLeft}rem`,
        marginRight: `${marginRight}rem`,
      }}
    >
      {reviews.map((review) => (
        <div
          key={review.id}
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: `1px solid ${borderColor}`,
            borderRadius: `${borderRadius}px`,
            padding: `${padding}rem`,
            marginTop: `${marginTop}rem`,
            marginBottom: `${marginBottom}rem`,
          }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className={styles['image-container']}
          >
            <img
              // src={review.user!.profile_image || profile}
              src={
                review.user?.profile_image
                  ? `${process.env.REACT_APP_STORE_ADDRESS}/${review.user!.profile_image}`
                  : profile
              }
              alt='프로필 아이콘'
              style={{
                width: `${profileImageSize}rem`,
                height: `${profileImageSize}rem`,
                marginRight: '0.4rem',
              }}
            />
            <div
              style={{
                fontSize: `${fontSize}rem`,
                fontWeight: 600,
                marginLeft: '0.4rem',
              }}
            >
              {review.product!.type} with {review.user!.nickname}
            </div>
          </div>
          <div
            style={{ marginTop: '0.7rem', marginBottom: '0.7rem' }}
            className={styles['rating-container']}
          >
            <span style={typeBoxStyle}>{review.product!.type}</span>
            {renderRating(review.score ? review.score : 0)}
          </div>
          <div style={{ fontSize: '1.4rem', color: 'var(--color-black500)' }}>
            Meeting Date : {dayjs(review.meeting?.meeting_date)
            .tz(transUserTimezone)
            .format('MMM D, YYYY, hh:mm A')}
          </div>

          {/* useInfo가 true일 때만 info-container와 info-item-container를 렌더링 */}
          {useInfo && (
            <>
              {/* <div className={styles['info-container']}>
                {review.useful_answer_selected_reason}
              </div> */}
              {Array.isArray(review.useful_answer_selected_reason) ? (
                review.useful_answer_selected_reason.length === 1 ? (
                  <div className={styles['info-container']}>
                    {review.useful_answer_selected_reason[0]}
                  </div>
                ) : (
                  review.useful_answer_selected_reason.map((item: string, index: number) =>
                    item ? (
                      <div
                        key={index}
                        className={styles['info-container']}
                      >
                        {item}
                      </div>
                    ) : (
                      ''
                    )
                  )
                )
              ) : (
                <div className={styles['info-container']}>
                  {review.useful_answer_selected_reason}
                </div>
              )}
              <div className={styles['info-item-container']}>
                {review.good_reason.map((item: string, index: number) => (
                  <div
                    key={index}
                    className={styles['info-item']}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </>
          )}

          <Button
            marginTop={2}
            height='4rem'
            radius={8}
            activeBorderColor={'var(--color-grey100)'}
            bgColor='#fff'
            activeTextColor={'var(--color-black1000)'}
            active
            onClick={() => toReview(review)}
          >
            {text}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default ReviewList;
