import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import useGlobalStore from '../../../stores/globalStore';
import { useLocation } from 'react-router-dom';
import styles from './page.module.css';
import Popup from '../../../components/popup/Popup';
import Comment from './components/Comment';
import CommentsFields from './components/CommentsFields';
import { format } from 'date-fns';
import communityService from '../../../services/communityService';
import { Community } from '../../../models/community';
import { Sheet } from 'react-modal-sheet';
import Button from '../../../components/element/Button';
import closeIcon from '../../../assets/images/ic_close.png';
import useToast from '../../../hook/useToast';
import MdShare from '../../../assets/images/ic_share.svg';
import MdReport from '../../../assets/images/ic_report.svg';
import MdBookmark_on from '../../../assets/images/ic_bookmark_on.png';
import MdBookmark_off from '../../../assets/images/ic_bookmark_off.svg';
import replyIcon from '../../../assets/images/ic_reply_green.png';
import check from '../../../assets/images/ic_checked.png';
import unCheck from '../../../assets/images/ic_unchecked.png';
import reportCompleted from '../../../assets/images/ic_reportCompleted.png';
import { ReportType } from '../../../constants/constants';
import { CommunityReply } from '../../../models/communityReply';

const reasons = ['Offensive content', 'Disclosure of personal info', 'Unauthorized ads/spam'];

function Page() {
  const toast = useToast();
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [list, setList] = useState<Community | null>(null); // 초기값을 null로 변경
  const navigate = useNavigate();
  const [replies, setReplies] = useState<CommunityReply[]>([]);
  const [mentionUserId, setMentionUserId] = useState<number | null>(null);
  const { id } = useParams();
  const { user } = useGlobalStore();
  const [openDelete, setOpenDelete] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const commentInputRef = useRef<HTMLInputElement | null>(null); // useRef로 입력 필드 참조
  const focusOnCommentInput = () => {
    if (commentInputRef.current) {
      commentInputRef.current.focus();
    }
  };

  useEffect(() => {
    // 화면 크기 변경 감지
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // 초기 상태 설정
    handleResize();

    // 리스너 등록
    window.addEventListener('resize', handleResize);

    // 리스너 정리
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getCommunityData(Number(id));
  }, []); // id가 변경될 때마다 다시 데이터 요청

  useEffect(() => {
    setIsBookmarked(list?.favorite ? true : false);
  }, [list?.favorite]);

  const toggleReason = (reason: string) => {
    setSelectedReasons((prev) =>
      prev.includes(reason) ? prev.filter((r) => r !== reason) : [...prev, reason]
    );
  };

  const toggleBookmark = async (id: number) => {
    try {
      if (!isBookmarked) {
        await communityService.addFavorite(id);
        toast('success', 'Bookmark On', false);
      } else {
        await communityService.deleteFavorite(id);
        toast('success', 'Bookmark Off', false);
      }
      // 상태 변경
      setIsBookmarked((prev) => !prev);
    } catch (err) {
      console.error('북마크 토글 오류:', err);
    }
  };

  async function getCommunityData(id: number) {
    try {
      const response = await communityService.getTargetList(id);
      if (response) {
        setList(response);
        setReplies(response.reply || []);
      } else {
        console.log('No data found');
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }

  const goModify = () => {
    navigate(`/community/posting/${id}`);
  };

  // 커뮤니티 게시물 삭제
  const deletePostBtn = async () => {
    if (!id) return; // id가 없으면 삭제하지 않음

    try {
      const response = await communityService.deletePost(Number(id));
      if (response) {
        toast('success', 'Post deleted successfully', false);
        navigate(-1); // 삭제 후 이전 페이지로 돌아가기
        setOpenDelete(false);
      } else {
        toast('error', 'Failed to delete post', false);
      }
    } catch (error) {
      console.error('Failed to delete post:', error);
      toast('error', 'An error occurred while deleting the post', false);
    }
  };

  // 신고 처리 함수
  const handleReport = async (reportType: ReportType, referenceId: number) => {
    if (selectedReasons.length === 0) {
      toast('error', 'Please select a reason for reporting.', false);
      return;
    }

    try {
      const response = await communityService.report(reportType, referenceId, selectedReasons);
      if (response) {
        toast('success', 'Your report has been submitted.', false);
        setOpenReport(false);
        setShowPopup(true);
      } else {
        toast('error', 'This is a duplicate report.', false);
      }
    } catch (error) {
      console.error('Error while reporting:', error);
    }
  };

  return (
    <>
      <div
        className={styles.pageContainer}
        style={{ position: 'relative' }}
        id='container'
      >
        {/* Fixed header */}
        <Header
          useBackButton={true}
          onBack={() => navigate(-1)}
          useLanguageButton={true}
          title={list?.type === 'NOTICE' ? 'Notice' : 'Post'}
        />
        <section className={styles.title}>
          {list?.subject ? list?.subject : 'Article Title'}
        </section>
        <section className={styles.article}>
          <article>{list?.content}</article>
        </section>
        <section className={styles.group}>
          <div>
            <span className={styles.information}>
              {' '}
              {list?.type === 'NOTICE' ? 'Master' : 'Poster'}
            </span>

            <span className={styles.information}>
              {list?.created_date
                ? format(new Date(list.created_date), 'MMM dd, yyyy')
                : 'Invalid Date'}
            </span>

            <span className={styles.information}> Views {list?.count ? list?.count : 0}</span>
            <span className={styles.information}> {list?.category ? list?.category : ''}</span>
            {/* <p>Replies: {replies ? replies : 0}</p> */}
          </div>
        </section>

        {/* <section className={styles.group}>
          <div className={styles.additional}>
            <span className={styles.information}> Translate to my language </span>
          </div>
        </section> */}

        <>
          {user?.id === list?.user.id && <div className={styles.divider} />}

          <div className={styles.frame}>
            {user?.id === list?.user.id && (
              <>
                {list?.type !== 'NOTICE' && (
                  <>
                    <span
                      className={styles.text_wrapper}
                      onClick={() => goModify()}
                    >
                      Edit
                    </span>
                    <span
                      className={styles.text_wrapper}
                      onClick={() => setOpenDelete(true)}
                    >
                      Delete
                    </span>
                  </>
                )}
              </>
            )}
            {user === null && (
              <>
                {list?.type !== 'NOTICE' && (
                  <>
                    <span
                      className={styles.text_wrapper}
                      onClick={() => navigate('/auth/signin')}
                    >
                      Sign in
                    </span>
                    <span
                      className={styles.text_wrapper}
                      onClick={() => navigate('/auth/signup')}
                    >
                      Sign up
                    </span>
                  </>
                )}
              </>
            )}
          </div>
        </>
        {list?.type !== 'NOTICE' && (
          <>
            <div className={styles.divider} />
            <>
              <div className={styles.frame}>
                <div className={styles.commentsContainer}>
                  <div className={styles.commentText}>
                    {replies.length} Comments
                    {user !== null && (
                      <div
                        className={styles.commentAdd}
                        onClick={() => focusOnCommentInput()}
                      >
                        <img
                          src={replyIcon}
                          alt=''
                        />
                        Add a Comment
                      </div>
                    )}
                  </div>

                  <div className={styles.iconContainer}>
                    {user !== null && (
                      <>
                        <img
                          src={MdReport}
                          alt='Report Icon'
                          className={styles.icon}
                          onClick={() => setOpenReport(true)}
                        />
                        <img
                          src={isBookmarked ? MdBookmark_on : MdBookmark_off}
                          alt='Bookmark Icon'
                          className={styles.icon}
                          onClick={() => toggleBookmark(Number(id))}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.divider} />
            </>
            <div className={styles.commentContainer}>
              <Comment
                replies={replies}
                setMentionUserId={setMentionUserId}
                deleteReply={(deletedReplyId) =>
                  setReplies(
                    (prevReplies) => prevReplies.filter((reply) => reply.id !== deletedReplyId) // 삭제된 댓글 제외
                  )
                }
              />
            </div>

            {/* 존재하는게 있을시 내용 댓글 추가 */}

            <>
              <div
                className={`${styles.frame} ${
                  user !== null ? styles.floatingCommentsFields : styles.activeCommentsFields
                }`}
              >
                <CommentsFields
                  ref={commentInputRef} // ref 전달
                  mentionUserId={mentionUserId}
                  setMentionUserId={setMentionUserId}
                  addReplys={(newReply) => setReplies((prevReplies) => [...prevReplies, newReply])}
                />
              </div>
            </>
          </>
        )}
      </div>

      <Sheet
        isOpen={openDelete}
        detent='content-height'
        onClose={() => setOpenDelete(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '17.7rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Want to delete the post?</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenDelete(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <div className={styles['text']}>{'Deleted posts cannot be edited.'}</div>

              <div className={styles['button-container']}>
                <Button
                  width='100%'
                  inactiveTextColor='black'
                  height='4.8rem'
                  onClick={() => setOpenDelete(false)}
                >
                  Cancel
                </Button>
                <Button
                  active={true}
                  width='100%'
                  height='4.8rem'
                  onClick={() => deletePostBtn()}
                >
                  Delete
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenDelete(false)} />
      </Sheet>
      <Sheet
        isOpen={openReport}
        detent='content-height'
        onClose={() => setOpenReport(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '30.5rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Block this post?</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenReport(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <div className={styles['text']}>{'Choose a reason (multiple choices allowed)'}</div>
              {reasons.map((reason) => (
                <div
                  key={reason}
                  className={styles['report-select']}
                  onClick={() => toggleReason(reason)}
                >
                  <img
                    src={selectedReasons.includes(reason) ? check : unCheck}
                    alt=''
                  />
                  {reason}
                </div>
              ))}
              <div className={styles['button-container']}>
                <Button
                  width='100%'
                  inactiveTextColor='black'
                  height='4.8rem'
                  onClick={() => setOpenReport(false)}
                >
                  Cancel
                </Button>
                <Button
                  active={true}
                  width='100%'
                  height='4.8rem'
                  // onClick={() => deletePostBtn()}
                  onClick={() => handleReport(ReportType.COMMUNITY, Number(id))}
                >
                  Report and Block
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenReport(false)} />
      </Sheet>
      <Popup
        show={showPopup}
        width={'27.4rem'}
        height={'34rem'} /*onClose={() => setShowPopup(false)}*/
      >
        <div className={styles['popup-container']}>
          <img
            className={styles['popup-image']}
            src={reportCompleted}
            alt=''
          />
          <div className={styles['popup-title']}>Reported</div>
          <div className={styles['popup-content']}>
            {
              'Repeated reports of the same issue\r\nwill hide the post and may ban the\r\nuser according to community rules.'
            }
          </div>
          <div
            className={styles['popup-button']}
            onClick={() => setShowPopup(false)}
          >
            Okay
          </div>
        </div>
      </Popup>
    </>
  );
}

export default Page;
