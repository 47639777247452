import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobalStore from '../stores/globalStore';
import useToast from '../hook/useToast';
import styles from './Menu.module.css';
import { MateVerifyStatus } from '../constants/constants';
import { Sheet } from 'react-modal-sheet';
import Footer from '../components/layout/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import arrowRightIcon from '../assets/images/ic_arrow_right.png';
import profileDefaultIcon from '../assets/images/ic_profile_default.png';
import menuMateProfileIcon from '../assets/images/ic_menu_mate_profile.png';
import menuMyProductIcon from '../assets/images/ic_menu_my_product.png';
import menuReviewManageIcon from '../assets/images/ic_menu_review_manage.png';
import menuSettlementIcon from '../assets/images/ic_menu_settlement.png';
import menuMyProfileIcon from '../assets/images/ic_menu_my_profile.png';
import menuMyReviewIcon from '../assets/images/ic_menu_my_review.png';
import menuPurchaseHistoryIcon from '../assets/images/ic_menu_purchase_history.png';
import menuFaqIcon from '../assets/images/ic_menu_faq.png';
import { Coupon } from '../models/coupon';
import userService from '../services/userService';
import { Meeting } from '../models/meeting';
import MeetingService from '../services/meetingService';
import closeIcon from '../assets/images/ic_close.png';
import Button from '../components/element/Button';

import nationData from '../assets/data/nation.json';

function Menu() {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { user, mate, setMate } = useGlobalStore();
  const [couponData, setCouponData] = useState<Coupon[]>([]);
  const [meetingCount, setMeetingCount] = useState<number>(0);
  const [suggestCount, setSuggestCount] = useState<number>(0);
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);

  useEffect(() => {
    // 화면 크기 변경 감지
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // 초기 상태 설정
    handleResize();

    // 리스너 등록
    window.addEventListener('resize', handleResize);

    // 리스너 정리
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    loadMeetingCountData();
    loadCouponList('price');
    setMate(mate);
  }, [mate]);

  function goMyMateProfile() {
    if (
      user?.mate?.verify_status === MateVerifyStatus.WAIT ||
      user?.mate?.verify_status === MateVerifyStatus.TEMP
    ) {
      toast('error', 'Mate registration is pending review.', false);
      return;
    }
    const nation = nationData.find(
      (e) => e.use == true && e.mateAvailability == true && e.abb == user!.country_code
    );
    if (nation === undefined) {
      toast('error', 'Not available country');
      return;
    }

    if (user!.mate === null || user!.mate === undefined) {
      setOpenBottomSheet(true);
    } else {
      navigate(`/mate/detail/${user?.mate?.id}`);
    }
  }

  function goFAQPage() {
    navigate('/setting');
    navigate('/cs/customerService');
  }

  const loadMeetingCountData = async () => {
    try {
      const meetingCountData = await MeetingService.getMeetingCount();
      if (meetingCountData) {
        setMeetingCount(meetingCountData['meetingCount']);
        setSuggestCount(meetingCountData['suggestCount']);
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function loadCouponList(type: string) {
    try {
      const couponListData = await userService.couponList(type);
      if (couponListData && couponListData.length > 0) {
        setCouponData(couponListData);
      }
    } catch (err) {
      console.log(err);
    }
  }
  function goManageReview() {
    if (user?.mate!.verify_status === MateVerifyStatus.WAIT) {
      toast('error', 'Mate registration is pending review.', false);
      return;
    }
    navigate('/my/manageReview');
  }

  return (
    <>
      <div
        className={styles['container']}
        style={{ position: 'relative' }}
        id='container'
      >
        <div className={styles['profile-container']}>
          <img
            className={styles['profile-thumbnail']}
            src={
              user?.mate?.profile_image
                ? `${process.env.REACT_APP_STORE_ADDRESS}/${user.mate?.profile_image}`
                : user?.profile_image
                ? `${process.env.REACT_APP_STORE_ADDRESS}/${user.profile_image}`
                : profileDefaultIcon
            }
            alt=''
            width='5.6rem'
            height='5.6rem'
          />
          <div className={styles['welcome-text']}>
            Hello,
            <br />
            {user?.nickname}
          </div>
        </div>

        {user?.mate?.verify_status === MateVerifyStatus.APPROVE ? (
          <div className={styles['mate-active-status-container']}>
            <div className={styles['mate-active-text']}>The wand chooses you!</div>
          </div>
        ) : (
          <div
            className={styles['mate-active-status-container']}
            onClick={() => goMyMateProfile()}
          >
            <div className={styles['mate-active-text']}>
              {user?.mate?.verify_status === MateVerifyStatus.WAIT ||
              mate?.verify_status === MateVerifyStatus.TEMP
                ? 'Mate registration is pending review'
                : `Tap to Join the Mate's Quest`}
            </div>
          </div>
        )}

        <div
          className={styles['card-row']}
          style={{
            marginTop: mate?.verify_status === MateVerifyStatus.APPROVE ? '0.7rem' : '2.1rem',
            marginLeft: '1.6rem',
            marginRight: '1.6rem',
          }}
        >
          <div
            className={styles['card-column-box']}
            onClick={() => navigate('/meeting/myMeeting')}
          >
            <div className={styles['card-row']}>
              <div className={styles['card-count-text']}>{meetingCount}</div>
              {suggestCount > 0 && (
                <div className={styles['card-meeting-requests']}>+ {suggestCount} Requests</div>
              )}
            </div>
            <div
              className={styles['card-row']}
              style={{ marginTop: '0.2rem' }}
            >
              <div className={styles['card-text']}>Upcoming Meetings</div>
              <img
                className={styles['arrow-right-icon']}
                src={arrowRightIcon}
                alt=''
              />
            </div>
          </div>

          <div
            className={styles['card-column-box']}
            style={{ marginLeft: '0.7rem' }}
          >
            <div className={styles['card-count-text']}>{couponData.length}</div>
            <div
              className={styles['card-row']}
              onClick={() =>
                navigate('/my/coupon', {
                  state: couponData,
                })
              }
            >
              <div className={styles['card-text']}>My Coupons</div>
              <img
                className={styles['arrow-right-icon']}
                src={arrowRightIcon}
                alt=''
              />
            </div>
          </div>
        </div>
        {/* 심사중 카드 ? */}
        {user?.mate?.verify_status === MateVerifyStatus.APPROVE && (
          <>
            <div
              className={styles['card-menu-container']}
              style={{ marginTop: '1.6rem' }}
              onClick={() => goMyMateProfile()}
            >
              <img
                src={menuMateProfileIcon}
                alt=''
              />
              <div>Mate Profile</div>
            </div>

            <div
              className={styles['card-menu-container']}
              onClick={() => {
                if (
                  mate?.verify_status === MateVerifyStatus.WAIT ||
                  mate?.verify_status === MateVerifyStatus.TEMP
                ) {
                  toast('error', 'Mate registration is pending review.', false);
                  return;
                }

                // verify_status가 WAIT이 아니면 navigate 실행
                navigate('/product/manage');
              }}
            >
              <img
                src={menuMyProductIcon}
                alt=''
              />
              <div>Manage my items</div>
              <div className={styles['card-menu-add-product']}>Add more items</div>
            </div>

            <div
              className={styles['card-menu-container']}
              onClick={() => goManageReview()}
            >
              <img
                src={menuReviewManageIcon}
                alt=''
              />
              <div>Manage my reviews</div>
            </div>

            <div
              className={styles['card-menu-container']}
              onClick={() => {
                if (mate?.verify_status === MateVerifyStatus.WAIT) {
                  toast('error', 'Mate registration is pending review.', false);
                  return;
                }
                navigate('/calculation');
              }}
            >
              <img
                src={menuSettlementIcon}
                alt=''
              />
              <div>Manage my payout</div>
            </div>

            <div className={styles['divider']} />
          </>
        )}
        {user?.mate === null ||
          (user?.mate?.verify_status === MateVerifyStatus.WAIT && (
            <div className={styles['bannerBox']}>
              <Swiper
                pagination={{
                  type: 'fraction',
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className={styles['bannerSwiper']}
              >
                <SwiperSlide className={styles['banner1']}>
                  Coffee Chat / Interview Practice with Mate
                </SwiperSlide>
                <SwiperSlide className={styles['banner2']}>
                  Earn money through Nailedit Mate activities
                </SwiperSlide>
              </Swiper>
            </div>
          ))}

        <div
          className={styles['card-menu-container']}
          onClick={() => navigate('/my/myinfo')}
        >
          <img
            src={menuMyProfileIcon}
            alt=''
          />
          <div>My Profile</div>
        </div>

        <div
          className={styles['card-menu-container']}
          onClick={() => navigate('/meeting/myReview')}
        >
          <img
            src={menuMyReviewIcon}
            alt=''
          />
          <div>My Reviews</div>
        </div>

        <div
          className={styles['card-menu-container']}
          onClick={() => navigate('/meeting/myMeeting/payment')}
        >
          <img
            src={menuPurchaseHistoryIcon}
            alt=''
          />
          <div>Order History</div>
        </div>

        <div
          className={styles['card-menu-container']}
          style={{ marginBottom: '4.0rem' }}
          onClick={() => goFAQPage()}
        >
          <img
            src={menuFaqIcon}
            alt=''
          />
          <div>FAQ</div>
        </div>
        <Sheet
          isOpen={isOpenBottomSheet}
          detent='content-height'
          mountPoint={document.getElementById('container')!}
          style={{
            position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
          }}
          onClose={() => setOpenBottomSheet(false)}
        >
          <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '18.5rem' }}>
            <Sheet.Content>
              <div className={styles['sheet-terms-container']}>
                <div className={styles['sheet-terms-title']}>No available Nailedit profile</div>
                <img
                  src={closeIcon}
                  alt=''
                  onClick={() => setOpenBottomSheet(false)}
                />
              </div>
              <div
                className={styles['sheet-terms-content']}
                // onClick={() => selectThumbnail()}
              >
                {`To work as a mate, create a mate profile.\r\nAdd your profile now to get started.`}
              </div>
            </Sheet.Content>
            <Button
              active={true}
              marginHorizontalRem={1.6}
              marginBottom={2}
              onClick={() => navigate('/mate/agreement')}
            >
              Create a profile
            </Button>
          </Sheet.Container>
          <Sheet.Backdrop
            style={{ position: 'relative' }}
            onTap={() => setOpenBottomSheet(false)}
          />
        </Sheet>
      </div>
      <Footer />
    </>
  );
}

export default Menu;
