import { useEffect, useRef, useState } from 'react';
import Header from '../../components/layout/Header';
import styles from './ListByJob.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import categoryAllIcon from '../../assets/images/ic_category_all.png';
import jobENData from '../../assets/data/job_en.json';
import MateService from '../../services/mateService';
import { Mate } from '../../models/mate';
import UserService from '../../services/userService';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import bookmarkOnIcon from '../../assets/images/ic_bookmark_on.png';
import bookmarkOffIcon from '../../assets/images/ic_bookmark_off.png';
import { Sheet } from 'react-modal-sheet';
import closeIcon from '../../assets/images/ic_close.png';

const ListByJob = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState<number>(10);
  const { category } = location.state || {}; // state에서 type과 search 값을 받아
  const [selectedTitleJob, setSelectedTitleJob] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  // const [isLastPage, setLastPage] = useState<boolean>(false);
  const [mateProfileList, setMateProfileList] = useState<Mate[]>([]);
  const [newDataLength, setNewDataLength] = useState(0); // 새로 추가된 데이터의 길이 상태
  const loaderRef = useRef<HTMLDivElement | null>(null); // 로딩 요소 참조
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);

  useEffect(() => {
    // 카테고리나 직업이 변경될 때 데이터를 새로 로드
    if (selectedCategory || selectedTitleJob) {
      loadMateProfileList(1, count, selectedCategory, selectedTitleJob);
      setPage(1); // 페이지 초기화
    }
  }, [selectedCategory, selectedTitleJob]);

  useEffect(() => {
    if (mateProfileList.length < 5) return; // 데이터가 적을 때는 관찰 설정을 생략
    if (!loaderRef.current) return; // 로딩 참조가 없을 경우 생략

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && page && newDataLength >= 10) {
            handlePage(page + 1); // 새로운 페이지 로드
          }
        });
      },
      { root: null, threshold: 0.8 }
    );
    const timer = setTimeout(() => {
      if (loaderRef.current) {
        observer.observe(loaderRef.current); // 로딩 요소를 관찰
      }
    }, 1000); // 1초 지연

    const target = loaderRef.current;
    observer.observe(target);

    return () => {
      clearTimeout(timer); // 타이머가 설정된 경우 클리어
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current); // observer 해제
      }
    };
  }, [page, newDataLength]);

  useEffect(() => {
    if (category) {
      setSelectedCategory(category);
      setSelectedTitleJob(jobENData[0].items[0]);
      loadMateProfileList(page, count, category, jobENData[0].items[0]); // job 추가
    } else {
      setSelectedTitleJob(jobENData[0].items[0]);
      loadMateProfileList(page, count, selectedCategory, jobENData[0].items[0]); // job 추가
    }
  }, [category]);

  const handlePage = (newPage: number) => {
    if (newPage > 0 && newPage !== page) {
      // newPage가 유효한지 확인
      setSelectedTitleJob(jobENData[0].items[0]);
      loadMateProfileList(page + 1, count, selectedCategory, selectedTitleJob);
      setPage(newPage);
    }
  };

  function handleCategoryClick(category: string, job: string) {
    setSelectedTitleJob(job);
    setMateProfileList([]); // 상태 초기화
    setSelectedCategory(category);
    loadMateProfileList(1, count, category, selectedTitleJob);
    // setIsOpenBottomSheet(true);
  }
  function openAllCategory() {
    // setSelectedTitleJob(job);
    // setSelectedCategory(category);
    // loadMateProfileList(1, category, selectedTitleJob);
    setIsOpenBottomSheet(true);
  }

  async function loadMateProfileList(page: number, count: number, category: string, job: string) {
    const list = await MateService.getListByJob(
      page,
      count,
      category === '' ? '' : category,
      job === '' ? '' : job
    );
    if (list && list.length > 0) {
      if (page === 1) {
        setMateProfileList(list);
      } else {
        setMateProfileList((prevData) => [
          ...prevData,
          ...list.filter(
            (newItem: Mate) => !prevData.some((prevItem) => prevItem.id === newItem.id)
          ),
        ]);
      }
      setNewDataLength(list.length);
      // setPage(page);
    }
  }

  function getProfileImageFile(mate: Mate) {
    if (mate.profile_image !== '') {
      //메이트 프로필 이미지가 존재하는 경우.
      return mate.profile_image
        ? `${process.env.REACT_APP_STORE_ADDRESS}/${mate.profile_image}`
        : profileDefaultIcon;
    } else if (mate.user?.profile_image !== '') {
      //유저 프로필 이미지가 존재하는 경우.
      return mate.user?.profile_image
        ? `${process.env.REACT_APP_STORE_ADDRESS}/${mate.user?.profile_image}`
        : profileDefaultIcon;
    }

    return undefined;
  }

  function goMateProfilePage(id: number) {
    navigate(`/mate/detail/${id}`);
  }

  async function toggleBookmark(mate: Mate) {
    if (mate.favorite === true) {
      await UserService.deleteMateFavorite(mate.id!);
      mate.favorite = false;
    } else {
      await UserService.addMateFavorite(mate.id!);
      mate.favorite = true;
    }
    const newMateProfileList = [...mateProfileList];
    newMateProfileList.find((item) => item.id === mate.id)!.favorite = mate.favorite;
    setMateProfileList(newMateProfileList);
  }

  return (
    <div
      className={styles['container']}
      style={{ position: 'relative' }}
      id='container'
    >
      <Header
        useBackButton={true}
        title='Meetings by Field'
        onBack={() => navigate(-1)}
      />
      <div className={styles['category-bar']}>
        <img
          src={categoryAllIcon}
          alt=''
          onClick={() => openAllCategory()}
        />
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('', '')}
        >
          <div className={selectedCategory === '' ? styles['category-item-active'] : ''}>
            {'' === '' && 'All'}
            <div className={selectedCategory === '' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Development', '')}
        >
          <div className={selectedCategory === 'Development' ? styles['category-item-active'] : ''}>
            {'Development'}
            <div className={selectedCategory === 'Development' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Medical/Bio', '')}
        >
          <div className={selectedCategory === 'Medical/Bio' ? styles['category-item-active'] : ''}>
            {'Medical/Bio'}
            <div className={selectedCategory === 'Medical/Bio' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Design', '')}
        >
          <div className={selectedCategory === 'Design' ? styles['category-item-active'] : ''}>
            {'Design'}
            <div className={selectedCategory === 'Design' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Finance/VC', '')}
        >
          <div className={selectedCategory === 'Finance/VC' ? styles['category-item-active'] : ''}>
            {'Finance/VC'}
            <div className={selectedCategory === 'Finance/VC' ? styles['category-active'] : ''} />
          </div>
        </div>
        <div
          className={`  ${styles['category-item']}`}
          // onClick={() => selectJob(category.category)}
          onClick={() => handleCategoryClick('Data/AI', '')}
        >
          <div className={selectedCategory === 'Data/AI' ? styles['category-item-active'] : ''}>
            {'Data/AI'}
            <div className={selectedCategory === 'Data/AI' ? styles['category-active'] : ''} />
          </div>
        </div>
      </div>
      <div className={styles['profile-list-container']}>
        {mateProfileList.map((e) => (
          <div
            className={styles['profile-list-item']}
            onClick={(ev) => {
              ev.stopPropagation();
              goMateProfilePage(e.id!);
            }}
          >
            <div className={styles['profile-item-thumbnail']}>
              <img
                src={getProfileImageFile(e) ?? profileDefaultIcon}
                alt=''
              />
            </div>
            <div className={styles['profile-item-container']}>
              <div className={styles['flex']}>
                <div className={styles['profile-item-tag']}>{e.user?.annual}years</div>
                <div className={styles['profile-item-tag']}>{e.user?.job}</div>

                <div className={styles['profile-item-tag']}>{e.user?.country_name}</div>
              </div>

              <div className={styles['profile-item-nickname']}>{e.user?.nickname}</div>
              <div className={styles['profile-item-company']}>{e.user?.company}</div>
              <div className={styles['profile-item-message']}>{e.user?.introduce_message}</div>
            </div>
            <div className={styles['profile-item-bookmark']}>
              <img
                src={e.favorite === true ? bookmarkOnIcon : bookmarkOffIcon}
                alt=''
                onClick={(ev) => {
                  ev.stopPropagation();
                  toggleBookmark(e);
                }}
              />
            </div>
            {/* <div className={styles['profile-item-image-group']}>
              <div className={styles['profile-item-thumbnail']}>
                <img
                  src={getProfileImageFile(e) ?? profileDefaultIcon}
                  alt=''
                />
              </div>
              <div className={styles['profile-item-bookmark']}>
                <img
                  src={e.favorite === true ? bookmarkOnIcon : bookmarkOffIcon}
                  alt=''
                  onClick={(ev) => {
                    ev.stopPropagation();
                    toggleBookmark(e);
                  }}
                />
              </div>
            </div>
            <div className={styles['profile-item-tag']}>
              {e.user?.annual}years
            </div>
            <div className={styles['profile-item-tag']}>{e.user?.job}</div>
            <div className={styles['profile-item-nickname']}>
              {e.user?.nickname}
            </div>
            <div className={styles['profile-item-company']}>
              {e.user?.company}
            </div> */}
          </div>
        ))}
        <div
          style={{ height: '0.1rem' }}
          ref={loaderRef}
        ></div>
      </div>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setIsOpenBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.6rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              {/* <button onClick={() => setIsOpenBottomSheet(false)}>Close</button>
               */}
              <div className={styles['sheet-terms-title']}>Select Job</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setIsOpenBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              <div className={styles['sheet-terms-content-container']}>
                {jobENData.map((category) => (
                  <div
                    key={category.category}
                    className={`${styles['sheet-terms-content']} ${
                      selectedCategory === category.category ? styles['active'] : ''
                    }`}
                    onClick={() =>
                      handleCategoryClick(category.category === 'None' ? '' : category.category, '')
                    }
                  >
                    {category.category === 'None' ? 'All' : category.category}
                  </div>
                ))}
                {/* {selectedItems.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles['sheet-terms-content']} ${
                      selectedTitleJob === item ? styles['active'] : ''
                    }`}
                    onClick={() => selectJob(item)}
                  >
                    <div className={styles['circle']}></div>

                    <span>{item || 'All'}</span>
                  </div>
                ))} */}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setIsOpenBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
};

export default ListByJob;
