import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './ProductSuspend.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import { Product } from '../../models/product';
import ProductService from '../../services/productService';

function ProductSuspend() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | undefined>(undefined);

  useEffect(() => {
    if (id) {
      loadTargetProduct(Number(id));
    }
  }, []);

  async function loadTargetProduct(id: number) {
    //상품 목록 요청.
    const data = await ProductService.getTargetProduct(id);
    if (data) {
      setProduct(data);
    } else {
      console.log('에러');
    }
  }

  return (
    <div className={styles['container']}>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
      />
      <div className={styles['title-text']}>
        {"The item has been removed\r\nunder Nailedit's policies"}
      </div>
      <div className={styles['reason-container']}>
        <div className={styles['reason-title']}>Admin Note</div>
        <div className={styles['reason-content']}>
          {/* Admin Note 내용을 tempProduct에서 가져옵니다 */}
          {product?.reject_message || 'No reason provided.'}
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={600}
          active={true}
          onClick={() => navigate(`/`)}
        >
          Check
        </Button>
      </div>
      <div className={styles['customer-center-text']}>Contact Support</div>
    </div>
  );
}

export default ProductSuspend;
