import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Header from '../../../components/layout/Header';
import { UserWithdrawal } from '../../../models/userWithdrawal';
import styles from './WithdrawWaiting.module.css';
import settlementService from '../../../services/settlementService';
import { format } from 'date-fns';

// state의 타입 정의
interface State {
  status: 'waiting' | 'fail' | 'complete';
}

function WithdrawWaiting() {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState<State>({
    status: 'waiting', // 상태 초기값
  });
  const [withdrawData, setWithdrawData] = useState<UserWithdrawal | null>(null);
  const withdrawId = location.state.withdrawId;

  useEffect(() => {
    getWithdrawalData(withdrawId);
  }, [withdrawId]);

  // const handleFail = (reason: string) => setState({ status: 'fail', reason });
  // const handleComplete = () => setState({ status: 'complete', reason: '' }); // 빈 문자열로 초기화

  const getWithdrawalData = async (id: number) => {
    try {
      const withdrawData = await settlementService.getWithdrawalData(id);
      if (withdrawData) {
        setWithdrawData(withdrawData);
      } else {
        console.log('error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles['container']}>
      <Header
        onBack={() => navigate(-1)}
        title='Withdrawal Details'
      />
      <div className={styles['title']}>
        Withdrawal Request on{' '}
        {withdrawData && format(new Date(withdrawData.request_date || ''), 'MMM dd, yyyy')}
      </div>
      {withdrawData?.status === 'APPROVING' ||
      withdrawData?.status === 'COMPLETE' ||
      withdrawData?.status === 'DENIED' ? (
        <>
          <div className={styles['detail-container']}>
            <div className={styles['detail-title']}>
              <span>Request Time</span>
              <div className={styles['detail-text']}>
                <span>
                  {' '}
                  {withdrawData &&
                    format(new Date(withdrawData.request_date || ''), 'MMM/dd/yyyy HH:mm a')}
                </span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Expected Deposit Date</span>
              <div className={styles['detail-text']}>
                <span>
                  {' '}
                  {format(new Date(withdrawData.expected_deposit_start_date), 'MMM dd')} -{' '}
                  {format(new Date(withdrawData.expected_deposit_end_date), 'MMM dd, yyyy')}
                </span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Payout Method</span>
              <div className={styles['detail-text']}>
                <span>
                  {(withdrawData.withdrawal_method === 'PAYPAL' && 'PayPal') ||
                    (withdrawData.withdrawal_method === 'SWIFT' && 'SWIFT Transfer') ||
                    (withdrawData.withdrawal_method === 'LOCAL_TRANSFER' && 'Local Transfer')}
                </span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Account Name </span>
              <div className={styles['detail-text']}>
                <span>{withdrawData.name}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Email</span>
              <div className={styles['detail-text']}>
                <span>{withdrawData.email}</span>
              </div>
            </div>
            <div className={styles['detail-title']}>
              <span>Invoice Number</span>
              <div className={styles['detail-text']}>
                <span>{withdrawData.invoice_number}</span>
              </div>
            </div>
          </div>
          {withdrawData.status !== 'DENIED' ? (
            <>
              <div className={styles['title']}>{'Withdrawal Details'}</div>
              <div className={styles['sale-container']}>
                <div className={styles['detail-title']}>
                  <span>Withdrawal Request Amount</span>
                  <div className={styles['detail-text-black']}>
                    <span>${withdrawData.amount}</span>
                  </div>
                </div>
                <div className={styles['detail-title']}>
                  <span>Currency</span>
                  <div className={styles['detail-text-black']}>
                    <span>{withdrawData.currency}</span>
                  </div>
                </div>
                {withdrawData.withdrawal_method === 'SWIFT' && (
                  <>
                    <div className={styles['detail-title']}>
                      <span>SWIFT Transfer Option</span>
                      <div className={styles['detail-text-black']}>
                        <span>{withdrawData.swift_transfer_option}</span>
                      </div>
                    </div>
                  </>
                )}
                {withdrawData.withdrawal_method === 'LOCAL_TRANSFER' && (
                  <>
                    <div className={styles['detail-title']}>
                      <span>Exchange Rate</span>
                      <div className={styles['detail-text']}>
                        <span>
                          {withdrawData.exchange_rate}USD = 0{withdrawData.currency}
                        </span>
                      </div>
                    </div>
                    <div className={styles['detail-title']}>
                      <span>Transfer tax rate</span>
                      <div className={styles['detail-text']}>
                        <span>{withdrawData.transfer_tax_rate}%</span>
                      </div>
                    </div>
                  </>
                )}
                <div className={styles['detail-title']}>
                  <span>Transfer fee </span>
                  {withdrawData.withdrawal_method === 'PAYPAL' ? (
                    <div className={styles['detail-text']}>
                      <span>{'Automatically Determined by Paypal'}</span>
                    </div>
                  ) : (
                    <div className={styles['detail-text']}>
                      <span>${withdrawData.transfer_fee || 0}</span>
                    </div>
                  )}
                </div>
                <div className={styles['detail-title']}>
                  <span>Other Expenses</span>
                  <div className={styles['detail-text']}>
                    <span>${withdrawData.other_expenses || 0}</span>
                  </div>
                </div>

                <div className={styles['detail-title']}>
                  <span>Transaction ID </span>
                  <div className={styles['detail-text']}>
                    <span>{withdrawData.transaction_id}</span>
                  </div>
                </div>
                <div className={styles['detail-title']}>
                  <span>Transaction Date</span>
                  <div className={styles['detail-text']}>
                    <span>
                      {withdrawData.transaction_date
                        ? format(new Date(withdrawData.transaction_date), 'dd/MM/2024')
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles['title']}>{'Request Details'}</div>
              <div className={styles['sale-container']}>
                <div className={styles['detail-title']}>
                  <span>Withdrawal Request Amount</span>
                  <div className={styles['detail-text-black']}>
                    <span>${withdrawData.amount}</span>
                  </div>
                </div>
                <div className={styles['detail-title']}>
                  <span>Currency</span>
                  <div className={styles['detail-text-black']}>
                    <span>{withdrawData.currency}</span>
                  </div>
                </div>
                {withdrawData.withdrawal_method === 'SWIFT' && (
                  <>
                    <div className={styles['detail-title']}>
                      <span>SWIFT Transfer Option</span>
                      <div className={styles['detail-text-black']}>
                        <span>{withdrawData.swift_transfer_option}</span>
                      </div>
                    </div>
                  </>
                )}
                {withdrawData.withdrawal_method === 'LOCAL_TRANSFER' && (
                  <>
                    <div className={styles['detail-title']}>
                      <span>Exchange Rate</span>
                      <div className={styles['detail-text']}>
                        <span>
                          {withdrawData.exchange_rate}USD = 0{withdrawData.currency}
                        </span>
                      </div>
                    </div>
                    <div className={styles['detail-title']}>
                      <span>Transfer tax rate</span>
                      <div className={styles['detail-text']}>
                        <span>{withdrawData.transfer_tax_rate}%</span>
                      </div>
                    </div>
                  </>
                )}
                <div className={styles['detail-title']}>
                  <span>Transfer fee </span>
                  <div className={styles['detail-text']}>
                    <span>${withdrawData.transfer_fee || 0}</span>
                  </div>
                </div>
                <div className={styles['detail-title']}>
                  <span>Other Expenses</span>
                  <div className={styles['detail-text']}>
                    <span>${withdrawData.other_expenses || 0}</span>
                  </div>
                </div>
                <div className={styles['detail-title']}>
                  <span>Status</span>
                  <div className={styles['detail-text']}>
                    <span>{withdrawData.status === 'DENIED' && 'Denied'}</span>
                  </div>
                </div>
                <div className={styles['detail-title']}>
                  <span>Message</span>
                  <div className={styles['detail-text']}>
                    <span>{withdrawData.status_message}</span>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={styles['profit-container']}>
            {(withdrawData.withdrawal_method === 'LOCAL_TRANSFER' &&
              'The transfer tax rate applies only to remittances to Argentina, Bolivia, and Brazil.') ||
              (withdrawData.withdrawal_method === 'SWIFT' &&
                'OUR option : Naileditapp Limited covers the intermediary and correspondent bank fees for recipient to get full amount')}
          </div>
        </>
      ) : (
        ''
      )}
      {/* 
      {withdrawData?.status === 'DENIED' && (
        <>
          <div className={styles['title']}>{'인출 신청 거절'}</div>
          <div className={styles['reason-container']}></div>
        </>
      )} */}

      {/* {withdrawData?.status === 'COMPLETE' && (
        <>
          <div className={styles['title']}>{'인출 완료'}</div>
          <div className={styles['complete-container']}></div>
        </>
      )} */}
    </div>
  );
}

export default WithdrawWaiting;
