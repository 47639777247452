import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import { VerifyStatus } from '../../constants/constants';
import TextField from '../../components/element/TextField';
import Button from '../../components/element/Button';
import styles from './PasswordReset.module.css';
import authService from '../../services/authService';
import useToast from '../../hook/useToast';

function PasswordReset() {
  const navigate = useNavigate();
  const toast = useToast();
  const [emailVerifyStatus, setEmailVerifyStatus] = useState<VerifyStatus>(VerifyStatus.NONE);

  const [formData, setFormData] = useState<any>({
    email: '',
    emailVerifyCode: '',
    password: '',
  });

  const inputRules: any = {
    email: {
      maxLength: 30,
      pattern:
        /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
    },
    emailVerifyCode: {
      minLength: 6,
      maxLength: 6,
    },
    password: {
      minLength: 8,
      maxLength: 20,
      pattern: /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{}|;:'",.<>?]).{8,20}$/,
    },
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const rule = inputRules[name];
    let formattedValue = value;

    if (rule.maxLength && value.length > rule.maxLength) {
      formattedValue = value.slice(0, rule.maxLength); //글자 수 초과하지 않게.
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const validateField: any = {
    email: (value: string) => inputRules['email'].pattern.test(value),
    emailVerifyCode: (value: string) =>
      value.length >= inputRules['emailVerifyCode'].minLength &&
      value.length <= inputRules['emailVerifyCode'].maxLength &&
      emailVerifyStatus === VerifyStatus.COMPLETE,
    password: (value: string) =>
      inputRules['password'].pattern.test(value) &&
      value.length >= inputRules['password'].minLength &&
      value.length <= inputRules['password'].maxLength,
  };

  async function requestEmailVerify() {
    if (formData.email === '' || emailVerifyStatus !== VerifyStatus.NONE) return;

    const isDuplicate: boolean = await authService.checkDuplicateEmail(formData.email);

    if (!isDuplicate) {
      toast('error', 'No matching account found', false);
    } else {
      //이메일 인증 번호 전송.
      navigate('/auth/emailVerification', {
        state: { email: formData.email },
      });
      setEmailVerifyStatus(VerifyStatus.WAIT);
      toast('success', 'Check your email for the verification code', false);
      await authService.requestEmailVerify(formData.email);
    }
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['label-container']}>
        {'Enter your email\r\nto reset your password'}
      </div>
      <label className={styles['label']}>Email</label>
      <div className={styles['input-field-container']}>
        <TextField
          name='email'
          type='email'
          value={formData.email}
          // enable={emailVerifyStatus === VerifyStatus.NONE}
          // actionButtonTitle='중복 확인'
          // onAction={() => requestEmailVerify()}
          actionButtonEnable={
            formData.email !== '' &&
            validateField['email'](formData['email']) &&
            emailVerifyStatus === VerifyStatus.NONE
          }
          placeholder='Enter your email'
          onChange={(event) => handleChange(event)}
        />
      </div>

      <Button
        marginHorizontalRem={2.0}
        active={true}
        onClick={() => requestEmailVerify()}
      >
        Send Password Reset Link
      </Button>
    </div>
  );
}

export default PasswordReset;
