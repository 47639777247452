import { TermsUpdate } from '../models/termsUpdate';
import { ToNailedit } from '../models/toNailedit';
import { get, post } from '../utils/apiClient';
import { ReferenceText } from '../models/referenceText';

class EtcService {
  public async toNailedit(toNailedit: ToNailedit): Promise<ToNailedit | undefined> {
    try {
      // const response = await post('/etc/toNailedit', { toNailedit });
      const response = await post('/etc/toNailedit', {
        to_nailedit_message: toNailedit.to_nailedit_message,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async sendContact(subject: string, content: string, receive_email: string) {
    try {
      const response = await post('/etc/contact', {
        subject,
        content,
        receive_email,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  //변경된 약관 동의 처리.
  public async agreeChangeTerms() {
    try {
      const response = await post('/etc/agreeChangeTerms');
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 약관 변경 상태 체크
  public async checkUpdateTerms(): Promise<TermsUpdate | undefined> {
    try {
      const response = await get('/etc/checkUpdateTerms');
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return undefined;
  }
  // 참조 문구 요청
  // public async getReferenceText(requestTypes: string): Promise<ReferenceText[] | undefined> {
  //   try {
  //     const response = await get(`/etc/referenceText?requestTypes=${requestTypes}`);
  //     return response.data;
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   return undefined;
  // }
}

const etcService = new EtcService();
export default etcService;
