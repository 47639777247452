import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styles from './ProductReject.module.css';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import TextField from '../../components/element/TextField';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import ProductService from '../../services/productService';
import useGlobalStore from '../../stores/globalStore';
import { Product } from '../../models/product';

function ProductReject() {
  const { id } = useParams();
  const { tempProduct } = useGlobalStore();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | undefined>(undefined);

  useEffect(() => {
    if (id) {
      loadTargetProduct(Number(id));
    }
  }, []);

  async function loadTargetProduct(id: number) {
    //상품 목록 요청.
    const data = await ProductService.getTargetProduct(id);
    if (data) {
      setProduct(data);
    } else {
      console.log('에러');
    }
  }

  return (
    <div className={styles['conatiner']}>
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
      />
      <div className={styles['title-text']}>{'Unfortunately,\r\nthis item cannot be sold'}</div>
      <div className={styles['title-sub-text']}>
        {'Check the reason, update your item, and resubmit'}
      </div>
      <div className={styles['reject-reason-container']}>
        <div className={styles['reject-reason-title']}>Admin Note</div>
        <div className={styles['reject-reason-content']}>
          {!product ? tempProduct?.reject_message : product.reject_message}
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          height={'5.6rem'}
          radius={12}
          activeFontSize='1.6rem'
          activeFontWeight={600}
          active={true}
          onClick={() =>
            navigate(
              `/product/price/modify?productType=${
                !product ? tempProduct?.type : product?.type
              }&id=${!product ? tempProduct?.id : product?.id}`
            )
          }
        >
          Edit Item Information
        </Button>
      </div>
    </div>
  );
}

export default ProductReject;
