import React, { useEffect, useState } from 'react';
import bellIcon from '../../../assets/images/ic_alarm.png';
import styles from './Alarm.module.css';
import { format, formatDistanceToNow, isToday } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Meeting } from '../../../models/meeting';
import meetingService from '../../../services/meetingService';

interface AlarmProps {
  category: string; // 알람 타입 (예: 커피챗 신청 결과 등)
  message: string;
  checked: boolean; // 읽음 여부
  created_date: Date;
  redirect_url: any;
  onClick?: () => void; // 클릭 시 호출할 콜백 함수
}

const Alarm: React.FC<AlarmProps> = ({
  category,
  message,
  checked,
  created_date,
  redirect_url,
  onClick,
}) => {
  const navigate = useNavigate();

  const formatDate = (date: Date) => {
    if (isToday(date)) {
      return formatDistanceToNow(date, { addSuffix: true }); // "50 mins ago", "1 hour ago" 등
    }
    return format(date, 'MMM dd'); // "Jul 23" 형식
  };

  const goSuggest = (redirect: number) => {
    navigate('/meeting/myMeeting', {
      state: { redirect, meetingVisible: false }, // 추가 상태 전달
    });
  };

  const goMeetingResult = (redirect: any) => {
    // navigate('/meeting/confirmDetail', {
    //   state: redirect,
    // });
    navigate('/meeting/myMeeting', {
      state: { meetingResult: redirect },
    });
  };

  const goProductResult = (redirect: string) => {
    const [status, id] = redirect.split(',');
    navigate(`/product/${id}/reject`);
  };

  const goProductSuspend = (redirect: number) => {
    navigate(`/product/${redirect}/suspend`);
  };

  const goPurchaseConfirm = async (redirectId: number) => {
    navigate(`/meeting/${redirectId}/review`);
  };

  const autoPurchaseConfirm = (redirect: number) => {
    navigate('/meeting/myMeeting', {
      state: redirect,
    });
  };
  const goMeetingUrl = (redirect: number) => {
    navigate(`/meeting/confirmDetail/${redirect}`, {
      state: { meetingId: redirect },
    });
  };

  const goManageReview = () => {
    navigate('/my/manageReview');
  };

  const goMateApply = (redirect: string) => {
    if (redirect === 'rejected') {
      navigate(`/my/myinfo`);
    } else if (redirect === 'accepted') {
      navigate(`/menu`);
    }
  };
  const goMateModify = (redirect: number) => {
    navigate(`/mate/detail/${redirect}`);
  };

  return (
    <>
      <div
        className={styles['alarm-container']}
        onClick={onClick}
        style={{ backgroundColor: checked ? 'white' : 'var(--color-green50)' }} // 예시로 white와 gray 색상을 사용했습니다.
      >
        {' '}
        <div className={styles['alarm-top']}>
          <img
            src={bellIcon}
            alt='Alarm Icon'
          />
          <div className={styles['flex']}>
            <span className={styles['alarm-type']}>
              {category === 'suggest_meeting'
                ? 'Suggest'
                : category === 'meeting_result'
                ? 'Meeting Result'
                : category === 'meeting_url'
                ? 'Meeting URL'
                : category === 'product_result'
                ? 'Product Result'
                : category === 'suspended_product'
                ? 'Suspended Product'
                : category === 'purchase_confirm'
                ? 'Purchase Confirm'
                : category === 'auto_purchase_confirm'
                ? 'Auto Purchase Confirm'
                : category === 'review'
                ? 'Review'
                : category === 'mate_apply'
                ? 'Mate Apply'
                : category === 'mate_modify'
                ? 'Mate Modify'
                : category}
            </span>
            <span className={styles['alarm-date']}>{formatDate(new Date(created_date))}</span>
          </div>
        </div>
        <div className={styles['alarm-item']}>
          <span className={styles['alarm-text']}>{message}</span>
          {category === 'suggest_meeting' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goSuggest(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'meeting_result' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goMeetingResult(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'product_result' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goProductResult(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'suspended_product' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goProductSuspend(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'purchase_confirm' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goPurchaseConfirm(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'auto_purchase_confirm' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => autoPurchaseConfirm(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'review' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goManageReview()}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'mate_apply' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goMateApply(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'mate_modify' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goMateModify(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : category === 'meeting_url' ? (
            <span
              className={styles['alarm-link']}
              onClick={() => goMeetingUrl(redirect_url)}
            >
              {redirect_url && <>{'View'}</>}
            </span>
          ) : (
            <span className={styles['alarm-link']}>
              {
                // 정해진 카테고리가 없으면 view안보임
              }
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Alarm;
