import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SignIn.module.css';
import Header from '../../components/layout/Header';
import TextField from '../../components/element/TextField';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';
import authService from '../../services/authService';
import useGlobalStore from '../../stores/globalStore';

import naileditLogoIcon from '../../assets/images/nailedit_logo.png';

function SignIn() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { setAuthenticated, setUser } = useGlobalStore();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoginActive, setLoginActive] = useState<boolean>(false);
  const [isEmailError, setEmailError] = useState<boolean>(false);
  const [isPasswordError, setPasswordError] = useState<boolean>(false);
  const emailRegExp = useRef(
    new RegExp(
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    )
  );

  useEffect(() => {
    if (emailRegExp.current.test(email) && password.length >= 8) {
      //이메일 형식 정상, 암호 8자 이상.
      setLoginActive(true);
      setEmailError(false);
      setPasswordError(false);
    } else {
      setLoginActive(false);
    }
  }, [email, password]);

  async function login() {
    let error: boolean = false;

    if (!emailRegExp.current.test(email)) {
      setEmailError(true);
      error = true;
    } else {
      setEmailError(false);
    }

    if (password.length < 8) {
      setPasswordError(true);
      error = true;
    } else {
      setPasswordError(false);
    }

    if (error === false) {
      setLoading(true); // 로딩 시작

      // 로그인 요청
      const data: any = await authService.login(email, password);

      setLoading(false); // 로딩 종료

      if (!data) {
        toast('error', 'An issue occurred while signing in.\r\nPlease try again.');
        return;
      }

      if (data['type'] === 'success') {
        // 로그인 성공
        localStorage.setItem('authToken', data['token']);
        setUser(data['user']);
        setAuthenticated(true);
        toast('success', "You're signed in!");
        if (data.user.country_code === '') {
          // 프로필 작성 페이지로 이동
          navigate('/auth/profileInput');
        } else {
          // 홈화면으로 이동
          navigate('/');
        }
      } else {
        if (data['message'] === 'not exists email') {
          toast('error', 'No account found. Please try again.');
        } else if (data['message'] === 'wrong password') {
          toast('error', 'Incorrect ID or Password');
        } else {
          toast('error', 'There was a problem during sign-in');
        }
      }
    }
  }

  function findId() {
    navigate('/auth/findId');
  }
  function passwordReset() {
    navigate('/auth/passwordReset');
  }

  return (
    <div>
      <Header
        onBack={() => navigate(-1)}
        actionType={'signup'}
        actionTitle='Sign Up'
        onAction={() => navigate('/auth/signup')}
      />
      <div className={styles['title-text']}>
        <img
          src={naileditLogoIcon}
          alt=''
        />
      </div>
      <div className={styles['sub-text']}>Move forward with New People</div>

      {isLoading ? (
        <div className={styles['loading-spinner']} />
      ) : (
        <>
          <div className={styles['label-container']}>
            <label className={styles['label']}>Email</label>
            {isEmailError === true && <div className={styles['label-error']}>Invalid email</div>}
          </div>
          <div className={styles['input-field-container']}>
            <TextField
              type='email'
              value={email}
              error={isEmailError}
              placeholder='Enter your email'
              onChange={(event) => setEmail(event.target.value)}
              onClear={() => setEmail('')}
            />
          </div>
          <div className={styles['label-container']}>
            <label className={styles['label']}>Password</label>
            {isPasswordError === true && (
              <div className={styles['label-error']}>Enter your password (min 8 characters)</div>
            )}
          </div>
          <div className={styles['input-field-container']}>
            <TextField
              type='password'
              value={password}
              error={isPasswordError}
              placeholder='Enter your password (min 8 characters)'
              onChange={(event) => setPassword(event.target.value)}
              onClear={() => setPassword('')}
            />
          </div>
          <Button
            marginHorizontalRem={2.0}
            active={isLoginActive}
            onClick={() => login()}
          >
            Sign In
          </Button>
          <div className={styles['account-container']}>
            <div
              className={styles['account-label']}
              onClick={() => findId()}
            >
              Find My Account
            </div>
            <div className={styles['account-label-separate']} />
            <div
              className={styles['account-label']}
              onClick={() => passwordReset()}
            >
              Reset Password
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SignIn;
