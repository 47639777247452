import { get, post, put } from '../utils/apiClient';
import { User } from '../models/user';
import { Mate } from '../models/mate';
import { ProductType } from '../constants/constants';
import { Meeting, MeetingRequestInfo } from '../models/meeting';

//메이트 관련 API
class MateService {
  public async getListByJob(page: number, count: number, category: string, job: string) {
    //직무 기준으로 메이트 프로필 목록 가져오기.
    try {
      const response = await get(
        `/mate/list?page=${page}&count=${count}&category=${category}&job=${job}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return [];
  }

  public async profile(mateId: number): Promise<Mate | null> {
    try {
      const response = await get(`/mate/profile?id=${mateId}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  }

  public async search(text: string): Promise<Mate[]> {
    try {
      const response = await get(`/mate/search?text=${text}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return [];
  }
  public async searchBy(
    page: number,
    count: number,
    type: string,
    search: string
  ): Promise<Mate[]> {
    try {
      const response = await get(
        `/mate/searchBy?page=${page}&count=${count}&type=${type}?search=${search || ''}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return [];
  }
  // 검색 결과
  public async getSearchResult(
    text: string,
    page: number,
    count: number,
    productType: ProductType,
    countryCode: string,
    job: string,
    language: string,
    sortType: string
  ) {
    try {
      const response = await get(
        `/mate/searchResult?text=${text}&page=${page}&count=${count}&productType=${productType}&countryCode=${countryCode}&job=${job}&language=${language}&sortType=${sortType}`
      );

      return response.data;
    } catch (err) {
      console.error(err); // 구체적인 에러 메시지 출력
    }
    return undefined;
  }

  public async saveMateProfile(mate: Mate): Promise<Mate | undefined> {
    try {
      const response = await post('/mate/saveMateProfile', {
        mate,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async updateMateProfile(mate: Mate): Promise<Mate | undefined> {
    try {
      const response = await put('/mate/updateMateProfile', {
        mate,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async requestMeeting(
    mate_user_id: number,
    product_id: number,
    schedule1: string,
    schedule2: string,
    schedule3: string,
    selectedSubjects: string[],
    userWrittenSubject: string,
    file_url: string,
    link_url: string,
    use_coupon_id: number,
    intent_id: string
  ): Promise<Mate | undefined> {
    try {
      const response = await post('/mate/requestMeeting', {
        mate_user_id,
        product_id,
        schedule1,
        schedule2,
        schedule3,
        selectedSubjects,
        userWrittenSubject,
        file_url,
        link_url,
        use_coupon_id,
        intent_id,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
}

const mateService = new MateService();
export default mateService;
