import React, { useEffect, useState } from 'react';
import Button from '../../components/element/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import nationData from '../../assets/data/nation.json';
import Header from '../../components/layout/Header';
import styles from './CalculationRequestSelect.module.css';
import SelectBox from '../../components/element/SelectBox';
import infoIcon from '../../assets/images/ic_info.png';
import useToast from '../../hook/useToast';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import closeIcon from '../../assets/images/ic_close.png';
import settlementService from '../../services/settlementService';
import { UserWithdrawalMethod } from '../../models/userWithdrawalMethod';
import useGlobalStore from '../../stores/globalStore';

function CalculationRequestSelect() {
  const { id } = useParams<{ id?: string }>();

  const toast = useToast();
  const [isOpenNationBottomSheet, setOpenNationBottomSheet] = useState(false);
  const [selectedNation, setSelectedNation] = useState<{ nation: string; abb: string } | null>(
    null
  ); // 객체로 설정
  const navigate = useNavigate();
  const { user, tempUserWithdrawalMethod, setTempUserWithdrawalMethod } = useGlobalStore();
  // user_id 추출
  const userId = user?.id; // 또는 user?.user_id

  // 로드 되면 유저의 기본 나라로 렌더링
  useEffect(() => {
    if (user?.country_name) {
      const country = nationData.find((e) => e.nation === user.country_name);
      if (country) {
        setSelectedNation({ nation: user.country_name, abb: country.abb });
      }
    }
  }, [user]);

  function selectNation(nation: string, abb: string) {
    setSelectedNation({ nation, abb }); // 객체 형태로 저장
    setOpenNationBottomSheet(false);
  }

  const handleNext = () => {
    if (!selectedNation) {
      toast('error', 'Choose your country.', false);
    } else if (id) {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        user_id: userId,
        country_code: selectedNation.abb,
        nationality: selectedNation.nation,
        created_date: new Date(),
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });
      navigate(`/calculation/request/add/${id}`, {
        state: { methodData: tempUserWithdrawalMethod },
      }); // 선택된 국가를 state로 전달
    } else {
      const tempUserWithdrawalMethod: UserWithdrawalMethod = {
        user_id: userId,
        country_code: selectedNation.abb,
        nationality: selectedNation.nation,
        created_date: new Date(),
      };
      setTempUserWithdrawalMethod({ ...tempUserWithdrawalMethod! });
      navigate('/calculation/request/add', { state: { methodData: tempUserWithdrawalMethod } }); // 선택된 국가를 state로 전달
    }
  };

  return (
    <>
      <div
        className={styles['container']}
        style={{ position: 'relative' }}
        id='container'
      >
        <Header onBack={() => navigate(-1)} />
        <div className={styles['title']}>{'Select your tax-resident country'}</div>
        <div className={styles['text']}>{'The available methods may vary by country'}</div>
        <div className={styles['select-container']}>
          <SelectBox
            // 유저 정보를 받아와서 기본 디폴트 값은 유저국가로?
            value={selectedNation ? selectedNation.nation : 'Choose Your Country'} // 선택된 나라 표시
            onAction={() => setOpenNationBottomSheet(true)}
          />
        </div>{' '}
        <div className={styles['button-container']}>
          <div className={styles['info-container']}>
            <div className={styles['info-item']}>
              <img
                src={infoIcon}
                alt=''
              />
              {/* {'Notice'} */}
            </div>
            {`Payout options in your country: - Local Transfer: Best\r\noption - receive in local currency - SWIFT:\r\nInternational transfer (higher fees) - PayPal: Available\r\nworldwide`}
          </div>
          <Button
            width='unset'
            marginHorizontalRem={1.6}
            active={true}
            onClick={() => handleNext()}
          >
            Next
          </Button>
        </div>
        <Sheet
          isOpen={isOpenNationBottomSheet}
          detent='content-height'
          onClose={() => setOpenNationBottomSheet(false)}
          mountPoint={document.getElementById('container')!}
          style={{ position: 'absolute' }}
        >
          <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.7rem' }}>
            <Sheet.Content>
              <div className={styles['sheet-terms-container']}>
                <span>Choose country</span>
                <img
                  src={closeIcon}
                  alt=''
                  onClick={() => setOpenNationBottomSheet(false)}
                />
              </div>
              <Sheet.Scroller>
                {nationData
                  .filter((e) => e.use === true && e.disableWhenDuplicate === false)
                  .map((e) => (
                    <div
                      key={e.nation}
                      className={styles['sheet-terms-content']}
                      onClick={() => selectNation(e.nation, e.abb)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={selectedNation?.nation === e.nation ? selectedIcon : unselectedIcon}
                          alt=''
                          style={{ marginRight: '0.5rem' }}
                        />
                        <div>{e.nation}</div>
                      </div>
                    </div>
                  ))}
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            onTap={() => setOpenNationBottomSheet(false)}
            style={{ position: 'relative' }}
          />
        </Sheet>
      </div>
    </>
  );
}

export default CalculationRequestSelect;
