// 카카오톡 삭제

import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import styles from './ProfileInput.module.css';
import Header from '../../components/layout/Header';
import TextField from '../../components/element/TextField';
import ComboBoxButton from '../../components/element/ComboBoxButton';
import JobSelect from '../my/components/JobSelect';
import SelectBox from '../../components/element/SelectBox';
import Button from '../../components/element/Button';
import Popup from '../../components/popup/Popup';
import CountryVerify from '../my/components/CountryVerify';
import EditTimezone from '../my/components/EditTimezone';
import LanguageSelect from '../my/components/LanguageSelect';
import useToast from '../../hook/useToast';
import { NotificationTypes } from '../../constants/constants';
import authService from '../../services/authService';
import uploadService from '../../services/uploadService';
import timezoneData from '../../assets/data/timezone.json';
import useGlobalStore from '../../stores/globalStore';
import nationData from '../../assets/data/nation.json';
import selectedIcon from '../../assets/images/ic_radio_selected.png';
import unselectedIcon from '../../assets/images/ic_radio_unselected.png';
import uncheckedIcon from '../../assets/images/ic_unchecked.png';
import checkedIcon from '../../assets/images/ic_checked.png';
import cameraIcon from '../../assets/images/ic_camera.png';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import closeIcon from '../../assets/images/ic_close.png';

function ProfileInput() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user, setUser } = useGlobalStore();
  const [isRegisterActive, setRegisterActive] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isOpenBottomSheet, setOpenBottomSheet] = useState(false);
  const [showCountryVerify, setShowCountryVerify] = useState(false);
  const [showTimeZone, setShowTimeZone] = useState(false);
  const [showJobSetting, setShowJobSetting] = useState(false);
  const [showLanguageSetting, setShowLanguageSetting] = useState(false);
  const [jobDirectly, setJobDirectly] = useState<boolean>(false); //직무 직접 입력
  const [jobName, setJobName] = useState<string>(''); //직접 입력한 직무
  const [selectedTimezoneIndex, setSelectedTimezoneIndex] = useState(0);
  const thumbnailRef = useRef<HTMLInputElement | null>(null);
  const [formData, setFormData] = useState<any>({
    countryCode: '', //거주 국가코드.
    countryName: '', //거주 국가명.
    timezoneName: '', //시간대 텍스트.
    language: [], //언어.
    category: '', //직군.
    job: '', //직무/직업.
    company: '', //회사.
    annual: '', //연차.
    profileImage: '', //프로필 이미지 경로.
    notificationTypes: [NotificationTypes.EMAIL, NotificationTypes.SMS], //알림 방식.('email', 'sms')
  });
  const [file, setFile] = useState<any>(null);
  const [displayLanguages, setDisplayLanguages] = useState<string[]>([]);
  const [selectedNation, setSelectedNation] = useState<{
    nation: string;
    abb: string;
  } | null>(null);
  const [isOpenNationBottomSheet, setOpenNationBottomSheet] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let formattedValue = value;

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  useEffect(() => {
    if (formData.language.length <= 0) {
      //기본적으로 출력해줄 디스플레이용 언어 목록.
      setDisplayLanguages([
        'Korean',
        'English (US)',
        'Chinese (Mandarin)',
        'Vietnamese',
        'Japanese',
        'Indonesian',
      ]);
    }
  }, []);

  useEffect(() => {
    //모든 필드가 유효한 값인지 체크.
    if (formData.language.length <= 0) {
      setRegisterActive(false);
      return;
    }
    if (formData.job === '') {
      setRegisterActive(false);
    }
    setRegisterActive(true);
  }, [formData]);

  function goCountryVerifyPage() {
    setShowCountryVerify(true);
  }

  function goLanguagePage() {
    setShowLanguageSetting(true);
  }

  function goTimeZonePage() {
    setShowTimeZone(true);
  }

  function selectLanguages(languages: string[]) {
    //언어 설정 창에서 설정한 언어를 가지고 넘어오는 곳.
    setFormData({
      ...formData,
      language: languages,
    });
    setShowLanguageSetting(false);
  }

  function selectLanguage(language: string) {
    if (formData.language.includes(language)) {
      setFormData({
        ...formData,
        language: formData.language.filter((value: string) => value !== language),
      });
    } else {
      setFormData({
        ...formData,
        language: [...formData.language, language],
      });
    }
  }

  function selectNation(nation: string, abb: string) {
    // 선택된 국가 정보를 업데이트
    setSelectedNation({ nation, abb });

    // 사용자 데이터에도 국가 정보 저장
    setFormData({
      ...formData!,
      countryCode: abb,
      countryName: nation,
    });

    // 국가 선택 바텀 시트를 닫음
    setOpenNationBottomSheet(false);
  }

  function selectCountry(countryCode: string, countryName: string) {
    setFormData({
      ...formData,
      countryCode: countryCode,
      countryName: countryName,
    });
    setShowCountryVerify(false);
  }

  function selectTimezone(timezoneIndex: number) {
    setFormData({
      ...formData,
      timezoneName: timezoneData[timezoneIndex]['label'],
    });
    setSelectedTimezoneIndex(timezoneIndex);
    setShowTimeZone(false);
  }

  function selectJob(category: string, job: string) {
    if (job === '-') {
      //직접 입력.
      setJobDirectly(true);
      setJobName('');
    } else {
      setJobDirectly(false);
      setJobName('');
      setFormData({
        ...formData,
        category: category,
        job: job,
      });
    }
    setShowJobSetting(false);
  }

  async function uploadFile() {
    let formData = new FormData();
    formData.append('file', file);
    const uploadFileName: string = await uploadService.addImage('user', formData);
    return uploadFileName;
  }

  const onChangeImageUpload = (e: any) => {
    setFile(e.target.files[0]);

    if (thumbnailRef.current?.files != null) {
      setFormData({
        ...formData,
        profileImage: URL.createObjectURL(thumbnailRef.current?.files[0]),
      });
    }
  };

  function selectThumbnail() {
    thumbnailRef.current?.click();
    setOpenBottomSheet(false);
  }

  function deleteProfileThumbnail() {
    setFormData({
      ...formData,
      profileImage: '',
    });
    setOpenBottomSheet(false);
  }

  function selectNotificationTypes(notificationTypes: NotificationTypes) {
    if (formData.notificationTypes.includes(notificationTypes)) {
      setFormData({
        ...formData,
        notificationTypes: formData.notificationTypes.filter(
          (value: NotificationTypes) => value !== notificationTypes
        ),
      });
    } else {
      setFormData({
        ...formData,
        notificationTypes: [...formData.notificationTypes, notificationTypes],
      });
    }
  }

  async function modifyProfile() {
    if (formData.countryName === '') {
      toast('error', 'Select Country name');
      return;
    }
    if (formData.timezoneName === '') {
      toast('error', 'Select Time Zone');
      return;
    }

    if (formData.language.length <= 0) {
      toast('error', 'Select meeting languages');
      return;
    }
    if ((jobDirectly == false && formData.job === '') || (jobDirectly == true && jobName === '')) {
      toast('error', 'Select your professional field.');
      return;
    }

    //프로필 이미지가 있으면 업로드 처리.
    let uploadFileName = '';
    if (file != null) {
      uploadFileName = await uploadFile();
    }

    //프로필 저장 처리.
    const data: any = await authService.modifyProfile(
      user!.email,
      formData.countryCode,
      formData.countryName,
      formData.timezoneName,
      formData.language,
      formData.category !== '' ? formData.category : jobName,
      formData.job !== '' ? formData.job : jobName,
      formData.company,
      formData.annual,
      formData.introduce_message,
      uploadFileName, //formData.profileImage,
      formData.notificationTypes
    );

    if (!data || data['type'] !== 'success') {
      toast('error', 'An error occurred during sign-up.\r\nPlease try again.', false);
      return;
    }

    setUser(data['user']);
    navigate('/my/profileComplete');
  }

  console.log(formData);

  return (
    <div
      className={styles['container']}
      style={{ position: 'relative' }}
      id='container'
    >
      <Header
        title='Account Information'
        onBack={() => navigate(-1)}
      />
      <div className={styles['form-container']}>
        <div className={styles['container-title']}>
          {'Complete your profile\r\nto better connect with others!'}
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Country</label>
        </div>
        <div className={styles['select-container']}>
          <SelectBox
            // 유저 정보를 받아와서 기본 디폴트 값은 유저국가로?
            value={formData?.countryName ? formData?.countryName : 'Choose Your Country'} // 선택된 나라 표시
            onAction={() => setOpenNationBottomSheet(true)}
          />
        </div>
        {/* <div className={styles['input-field-container']}>
          <TextField
            name='countryName'
            type='text'
            enable={false}
            value={formData.countryName}
            actionButtonTitle='Change'
            actionButtonEnable={true}
            onAction={() => goCountryVerifyPage()}
          />
        </div> */}
        <div className={styles['label-container']}>
          <label className={styles['label']}>Time Zone</label>
        </div>
        <ComboBoxButton
          marginLeft='2.0rem'
          marginRight='2.0rem'
          paddingLeft='1.6rem'
          paddingRight='1.4rem'
          type='combobox'
          text={formData.timezoneName ? formData.timezoneName : 'Choose your timezone'}
          onAction={() => goTimeZonePage()}
        />
        <div className={styles['label-container']}>
          <label className={styles['label']}>Language</label>
        </div>
        <label className={styles['label-description']}>Preferred languages for meeting</label>
        {/* 언어 선택*/}
        <div className={styles['language-container']}>
          {formData.language.map((e: string) => (
            <div
              className={styles['language-item']}
              onClick={() => selectLanguage(e)}
            >
              <img
                src={checkedIcon}
                alt=''
              />
              {e}
            </div>
          ))}
          {displayLanguages.map((e: string) =>
            !formData.language.includes(e) ? (
              <div
                className={styles['language-item']}
                onClick={() => selectLanguage(e)}
              >
                <img
                  src={uncheckedIcon}
                  alt=''
                />
                {e}
              </div>
            ) : (
              <span />
            )
          )}
        </div>
        <div
          className={styles['language-item-others']}
          onClick={() => goLanguagePage()}
        >
          Select More
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Job category</label>
        </div>
        <ComboBoxButton
          marginLeft='2.0rem'
          marginRight='2.0rem'
          paddingLeft='1.6rem'
          paddingRight='1.4rem'
          type='combobox'
          text={
            jobDirectly === true
              ? 'Add Custom'
              : formData.job === ''
              ? 'Select your professional field'
              : formData.job
          }
          onAction={() => setShowJobSetting(true)}
        />
        {jobDirectly === true && <div style={{ marginTop: '1.2rem' }} />}
        {jobDirectly === true && (
          <div className={styles['input-field-container']}>
            <TextField
              name='job'
              type='text'
              value={jobName}
              onChange={(event) => setJobName(event.target.value)}
              onClear={() => setJobName('')}
            />
          </div>
        )}
        <div className={styles['label-container']}>
          <label className={styles['label']}>Company (Optional)</label>
        </div>
        <div className={styles['input-field-container']}>
          <TextField
            name='company'
            type='text'
            value={formData.company}
            placeholder='Current workplace.'
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Years of experience (Optional)</label>
          <div className={styles['label-prefix']}>
            {formData.annual != '' && `${formData.annual} years`}
          </div>
        </div>
        <div className={styles['input-field-container']}>
          <TextField
            name='annual'
            type='text'
            value={formData.annual}
            placeholder='Work experience (years)'
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div className={styles['label-container']}>
          <label className={styles['label']}>Add Profile Picture</label>
        </div>
        <div className={styles['profile-image-container']}>
          <div
            className={styles['profile-image-thumbnail']}
            onClick={() => setOpenBottomSheet(true)}
          >
            <img
              src={formData.profileImage !== '' ? formData.profileImage : profileDefaultIcon}
              alt=''
            />
            <form
              encType='multipart/form-data'
              onSubmit={uploadFile}
            >
              <input
                type='file'
                style={{ display: 'none' }}
                ref={thumbnailRef}
                accept='image/*'
                onChange={onChangeImageUpload}
              />
            </form>
            {formData.profileImage === '' && (
              <div className={styles['profile-image-camera-icon']}>
                <img
                  src={cameraIcon}
                  alt=''
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles['label-container']}>
          <label className={styles['label']}>Notification method</label>
        </div>
        <label className={styles['label-description']}>
          Choose preferred method for updates and information
        </label>
        <div className={styles['notification-container']}>
          <div
            className={`${styles['notification-item']} ${
              formData.notificationTypes.includes(NotificationTypes.EMAIL) &&
              styles['notification-item-active']
            }`}
            onClick={() => selectNotificationTypes(NotificationTypes.EMAIL)}
          >
            Email
          </div>
          <div
            className={`${styles['notification-item']} ${
              formData.notificationTypes.includes(NotificationTypes.SMS) &&
              styles['notification-item-active']
            }`}
            onClick={() => selectNotificationTypes(NotificationTypes.SMS)}
          >
            SMS
          </div>
        </div>
        <div style={{ marginBottom: '4.8rem' }} />
      </div>
      <div className={styles['button-container']}>
        <Button
          width={'100%'}
          marginHorizontalRem={2.0}
          active={isRegisterActive}
          onClick={() => modifyProfile()}
        >
          Submit
        </Button>
      </div>
      <Sheet
        isOpen={isOpenNationBottomSheet}
        detent='content-height'
        onClose={() => setOpenNationBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.7rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Choose country</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenNationBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {nationData
                .filter((e) => e.use === true && e.disableWhenDuplicate === false)
                .map((e) => (
                  <div
                    key={e.nation}
                    className={styles['sheet-terms-content']}
                    onClick={() => selectNation(e.nation, e.abb)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={selectedNation?.nation === e.nation ? selectedIcon : unselectedIcon}
                        alt=''
                        style={{ marginRight: '0.5rem' }}
                      />
                      <div>{e.nation}</div>
                    </div>
                  </div>
                ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenNationBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
      <Sheet
        isOpen={isOpenBottomSheet}
        detent='content-height'
        onClose={() => setOpenBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '17.3rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-terms-title']}>Edit Profile Image</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheet(false)}
              />
            </div>
            <div
              className={styles['sheet-terms-content']}
              onClick={() => selectThumbnail()}
            >
              Choose from Library
            </div>
            <div
              className={styles['sheet-terms-content']}
              onClick={() => deleteProfileThumbnail()}
            >
              Remove Profile Photo
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
      <Popup
        show={showPopup}
        width={'27.4rem'}
        height={'34rem'}
        onClose={() => setShowPopup(false)}
      >
        <div>You're all set!</div>
        <div>Expand your horizons with new people.</div>
      </Popup>
      {showCountryVerify == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <CountryVerify
            onVerify={(countryCode, countryName) => selectCountry(countryCode, countryName)}
            onCancel={() => setShowCountryVerify(false)}
          />
        </div>
      )}
      {showTimeZone == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100%',
            height: '100vh',
          }}
        >
          <EditTimezone
            onSelect={(timeZoneIndex) => selectTimezone(timeZoneIndex)}
            onCancel={() => setShowTimeZone(false)}
          />
        </div>
      )}
      {showJobSetting == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100%',
            height: '100vh',
          }}
        >
          <JobSelect
            onSelect={(category, job) => selectJob(category, job)}
            onCancel={() => setShowJobSetting(false)}
          />
        </div>
      )}
      {showLanguageSetting == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100%',
            height: '100vh',
          }}
        >
          <LanguageSelect
            languages={formData.language}
            onSelect={(languages) => selectLanguages(languages)}
            onCancel={() => setShowLanguageSetting(false)}
          />
        </div>
      )}
    </div>
  );
}

export default ProfileInput;
