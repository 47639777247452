import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
import styles from './Agreement.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import useToast from '../../hook/useToast';

import uncheckedIcon from '../../assets/images/ic_unchecked.png';
import checkedIcon from '../../assets/images/ic_checked.png';
import mateExampleImage from '../../assets/images/mate/img_mate_example.png';
import closeIcon from '../../assets/images/ic_close.png';
import useGlobalStore from '../../stores/globalStore';

function MateAgreement() {
  const navigate = useNavigate();
  const toast = useToast();
  const { mate, setMate } = useGlobalStore();
  const [agreeMateServiceTerms, setAgreeMateServiceTerms] = useState<boolean>(false);
  const [agreeDonate, setAgreeDonate] = useState<boolean>(false);
  const [agreeDiscountTerms, setAgreeDiscountTerms] = useState<boolean>(false);
  const [openBottomSheetType, setOpenBottomSheetType] = useState<string>(''); //약관 바텀시트.

  function viewMateServiceTerms() {
    setOpenBottomSheetType('mate_service');
  }

  function viewDiscountTerms() {
    setOpenBottomSheetType('discount_policy');
  }

  function agreeTerms(type: string) {
    if (type === 'mate_service') {
      setAgreeMateServiceTerms(true);
    } else if (type === 'discount_policy') {
      setAgreeDiscountTerms(true);
    }
    setOpenBottomSheetType('');
  }

  function next() {
    if (!agreeMateServiceTerms) {
      toast('error', 'Please agree to the terms and conditions.');
      return;
    }

    if (!agreeDiscountTerms) {
      toast('error', 'Please agree to the terms and conditions.');
      return;
    }

    setMate({ ...mate!, donate_user: agreeDonate });
    navigate('/mate/emailInput?type=mate');
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title-text']}>Join us as a Nailedit Mate!</div>
      <div className={styles['sub-text']}>{'Share your experience with our community.'}</div>
      <div className={styles['mate-example-image']}>
        <img
          src={mateExampleImage}
          alt=''
        />
      </div>
      <div className={styles['description-text']}>
        {
          'Create your professional profile\r\nShowcase your skills and experience\r\nNailedit team will review and get back to you soon!'
        }
      </div>
      <div className={styles['agreement-title-text']}>Terms of Service</div>
      <div
        className={styles['agreement-row']}
        onClick={() => setAgreeMateServiceTerms(!agreeMateServiceTerms)}
      >
        <img
          src={agreeMateServiceTerms === true ? checkedIcon : uncheckedIcon}
          alt=''
        />
        <div className={styles['agreement-text']}>Mate Terms of Service</div>
        <div
          className={styles['agreement-view']}
          onClick={() => viewMateServiceTerms()}
        >
          View
        </div>
      </div>
      <div
        className={styles['agreement-row']}
        onClick={() => setAgreeDiscountTerms(!agreeDiscountTerms)}
      >
        <img
          src={agreeDiscountTerms === true ? checkedIcon : uncheckedIcon}
          alt=''
        />
        <div className={styles['agreement-text']}>Promotion Agreement</div>
        <div
          className={styles['agreement-view']}
          onClick={() => viewDiscountTerms()}
        >
          View
        </div>
      </div>
      <div
        className={styles['agreement-row']}
        onClick={() => setAgreeDonate(!agreeDonate)}
      >
        <img
          src={agreeDonate === true ? checkedIcon : uncheckedIcon}
          alt=''
        />
        <div className={styles['agreement-text']}>
          I agree to donate Mate earnings to Nailedit (optional)
        </div>
      </div>
      <Button
        marginHorizontalRem={2.0}
        marginTop={2.4}
        active={true}
        onClick={() => next()}
      >
        Create your Mate profile
      </Button>
      <Sheet
        isOpen={openBottomSheetType !== ''}
        detent='content-height'
        onClose={() => setOpenBottomSheetType('')}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.7rem' }}>
          <Sheet.Content style={{ marginBottom: '1.4rem' }}>
            <div className={styles['sheet-terms-container']}>
              <div className={styles['sheet-terms-title']}>Terms of Service</div>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenBottomSheetType('')}
              />
            </div>
            <Sheet.Scroller>
              <div
                className={styles['sheet-terms-content']}
                style={{ height: '41.4rem' }}
              >
                <iframe
                  src={
                    openBottomSheetType === 'mate_service'
                      ? `/terms/terms_mate_service_en.html`
                      : `/terms/terms_discount_policy_en.html`
                  }
                  style={{ width: '100%', border: 'none' }}
                ></iframe>
              </div>
            </Sheet.Scroller>
            <div className={styles['sheet-terms-button-container']}>
              <div
                className={styles['sheet-terms-button']}
                onClick={() => agreeTerms(openBottomSheetType)}
              >
                I Agree
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setOpenBottomSheetType('')} />
      </Sheet>
    </div>
  );
}

export default MateAgreement;
