// src/components/CommunityPosts.tsx

import React, { useEffect, useRef, useState } from 'react';
import CommunityPost from './CommunityPost';
import styles from './CommunityPosts.module.css';
import communityService from '../../../services/communityService';
import { Community, CommunityFavorite } from '../../../models/community';
import CommunityNotice from './CommunityNotice';

interface CommunityPostsProps {
  selectedCategory: string;
  selectedTab: string;
}

const CommunityPosts: React.FC<CommunityPostsProps> = ({ selectedCategory, selectedTab }) => {
  const [list, setList] = useState<Community[]>([]);
  const [newList, setNewList] = useState(0); // 새로 추가된 데이터의 길이 상태
  const [favoriteList, setFavoriteList] = useState<CommunityFavorite[]>([]);
  const [notice, setNotice] = useState<Community[]>([]);
  const [count, setCount] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const loaderRef = useRef<HTMLDivElement | null>(null); // 로딩 요소 참조

  useEffect(() => {
    if (list.length < 5) return; // 데이터가 적을 때는 관찰 설정을 생략
    if (!loaderRef.current) return; // 로딩 참조가 없을 경우 생략

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && page && newList >= 10) {
            handlePage(page + 1); // 새로운 페이지 로드
          }
        });
      },
      { root: null, threshold: 0.8 }
    );
    const timer = setTimeout(() => {
      if (loaderRef.current) {
        observer.observe(loaderRef.current); // 로딩 요소를 관찰
      }
    }, 1000); // 1초 지연

    const target = loaderRef.current;
    observer.observe(target);

    return () => {
      clearTimeout(timer); // 타이머가 설정된 경우 클리어
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current); // observer 해제
      }
    };
  }, [page, newList]);

  useEffect(() => {
    setPage(1); // 페이지 초기화
    getCommunityList(1, count, selectedCategory, selectedTab); // 첫 페이지부터 다시 로드
    if (selectedTab === 'Bookmark') {
      getCommunityFavoriteList(); // 북마크 탭의 경우 북마크 리스트 로드
    }
  }, [selectedCategory, selectedTab]);
  // selectedTab 변경 시마다 필터링된 리스트 가져오기

  async function getCommunityList(
    page: number,
    count: number,
    selectedCategory: string,
    selectedTab: string
  ) {
    try {
      const response = await communityService.getList(page, count, selectedCategory, selectedTab);

      if (response) {
        // 공지사항 처리
        if (response.noticeList) {
          setNotice(response.noticeList);
        }

        // 리스트 업데이트
        if (response.list && response.list.length > 0) {
          if (page === 1) {
            setList(response.list); // 새로 로드
          } else {
            setList((prevData) => [
              ...prevData,
              ...response.list.filter(
                (newItem: Community) => !prevData.some((prevItem) => prevItem.id === newItem.id)
              ),
            ]);
          }
          setNewList(response.list.length);
        } else if (page === 1) {
          setList([]); // 첫 페이지인데 데이터가 없을 경우 빈 배열로 설정
        }
      }
    } catch (error) {
      console.error('Error fetching community list:', error);
    }
  }

  const handlePage = (newPage: number) => {
    if (newPage > 0 && newPage !== page) {
      getCommunityList(newPage, count, selectedCategory, selectedTab);
      setPage(newPage);
    }
  };

  async function getCommunityFavoriteList() {
    try {
      const response = await communityService.getFavoriteList();

      if (response) {
        setFavoriteList(response); // 북마크 리스트 설정
      }
    } catch (error) {
      console.error('Error fetching favorite list:', error);
    }
  }

  return (
    <>
      {notice?.map((text: Community, index: number) => (
        <CommunityNotice
          id={text.id!}
          key={index}
          text={text.content}
        />
      ))}
      {selectedTab === 'Bookmark' ? (
        <>
          <div className={styles['communityPosts']}>
            {favoriteList.length > 0 ? (
              favoriteList.map((post: CommunityFavorite, index: number) => (
                <CommunityPost
                  key={index}
                  id={post.community?.id!}
                  title={post.community?.subject || '???'}
                  content={post.community?.content || '???'}
                  date={post.created_date}
                  views={post.community?.count || 0}
                  category={post.community?.category || '???'}
                  replies={post.community?.reply?.length || 0}
                  favorite={true}
                />
              ))
            ) : (
              <>{''}</>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles['communityPosts']}>
            {list?.map((post: Community, index: number) => (
              <CommunityPost
                key={index}
                id={post.id!} // Ensure the `id` is being passed correctly
                title={post.subject}
                content={post.content}
                date={post.created_date}
                views={post.count}
                category={post.category}
                replies={post.reply.length}
                favorite={post.favorite}
              />
            ))}
          </div>
          <div
            style={{ height: '0.1rem' }}
            ref={loaderRef}
          ></div>
        </>
      )}

      <div className={styles.bottomSpacer} />
    </>
  );
};

export default CommunityPosts;
