import React, { useEffect, useState } from 'react';
import CountryDialog from './components/CountryDialog';
import TopicDialog from './components/TopicDialog';
import styles from './page.module.css';
import Tabs from './components/Tabs';
import { Sheet } from 'react-modal-sheet';
import { MdChevronRight } from 'react-icons/md';
import TextEditor from './components/TextEditor';
import nationData from '../../../assets/data/nation.json';
import topicData from '../../../assets/data/topicExample.json';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import useGlobalStore from '../../../stores/globalStore';
import closeIcon from '../../../assets/images/ic_close.png';
import selectedIcon from '../../../assets/images/ic_radio_selected.png';
import unselectedIcon from '../../../assets/images/ic_radio_unselected.png';
import useToast from '../../../hook/useToast';
import communityService from '../../../services/communityService';
import { Community } from '../../../models/community';

const Page: React.FC = () => {
  const toast = useToast();
  const { user } = useGlobalStore();
  const { id } = useParams();
  const [list, setList] = useState<Community | null>(null); // 초기값을 null로 변경

  const navigate = useNavigate();
  const [isOpenNationBottomSheet, setOpenNationBottomSheet] = useState(false);
  const [isOpenTopicsBottomSheet, setOpenTopicsBottomSheet] = useState(false);

  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);

  const [selectedNation, setSelectedNation] = useState<{ nation: string; abb: string } | null>(
    null
  ); // 객체로 설정

  const [title, setTitle] = useState(id ? list?.subject! : ''); // 제목 상태 추가
  const [content, setContent] = useState(''); // 내용 상태 추가

  useEffect(() => {
    if (user?.country_name) {
      const country = nationData.find((e) => e.nation === user.country_name);
      if (country) {
        setSelectedNation({ nation: user.country_name, abb: country.abb });
      }
    }

    if (id) {
      getCommunityList(Number(id));
    }
  }, [user, id]);
  useEffect(() => {
    if (list) {
      setTitle(list.subject); // list가 업데이트되면 제목 설정
      setContent(list.content); // list가 업데이트되면 내용 설정
      setSelectedTopic(list.category); // 카테고리 설정
      setSelectedNation({ nation: list?.country_name!, abb: list?.country_code! });
    }
  }, [list]);

  async function getCommunityList(id: number) {
    try {
      const response = await communityService.getTargetList(id);
      if (response) {
        setList(response);
      } else {
        console.log('No data found');
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }

  const postModify = async () => {
    // 필요한 데이터를 미리 정의하거나 상태에서 가져오기
    const postId = Number(id);
    const countryCode = selectedNation?.abb || '';
    const countryName = selectedNation?.nation || '';
    const category = selectedTopic || '';
    const subject = 'Your subject here'; // 주제를 사용자 입력 또는 상태에서 가져오기
    const contents = 'Your content here'; // 내용을 사용자 입력 또는 상태에서 가져오기

    // 데이터 검증
    if (!countryCode || !countryName || !category || !subject || !content) {
      toast('error', 'Please fill in all the fields.', false);
      return;
    }

    try {
      // API 호출
      const response = await communityService.modifyPost(
        postId,
        countryCode,
        countryName,
        category,
        title, // 제목 전달
        content // 내용 전달
      );

      if (response) {
        toast('success', 'Post Modified!', false);
        navigate(-1);
      } else {
        toast('error', `Can't Post Modified`, false);
      }
    } catch (error) {
      console.log('오류 발생:', error);
    }
  };

  const publish = async () => {
    // 필요한 데이터를 미리 정의하거나 상태에서 가져오기
    const countryCode = selectedNation?.abb || '';
    const countryName = selectedNation?.nation || '';
    const category = selectedTopic || '';
    const subject = 'Your subject here'; // 주제를 사용자 입력 또는 상태에서 가져오기
    const contents = 'Your content here'; // 내용을 사용자 입력 또는 상태에서 가져오기

    // 데이터 검증
    if (!countryCode || !countryName || !category || !subject || !content) {
      toast('error', 'Please fill in all the fields.', false);
      return;
    }

    try {
      // API 호출
      const response = await communityService.addPost(
        countryCode,
        countryName,
        category,
        title, // 제목 전달
        content // 내용 전달
      );

      if (response) {
        toast('success', 'Post published', false);
        navigate(-1);
      } else {
        toast('error', `Can't Post published`, false);
      }
    } catch (error) {
      console.log('오류 발생:', error);
    }
  };
  function selectNation(nation: string, abb: string) {
    setSelectedNation({ nation, abb }); // 객체 형태로 저장
    setOpenNationBottomSheet(false);
  }
  function selectTopics(topic: string) {
    setSelectedTopic(topic); // 단일 문자열 저장
    setOpenTopicsBottomSheet(false);
  }

  return (
    <>
      <div
        className={styles.pageContainer}
        style={{ position: 'relative' }}
        id='container'
      >
        {/* Fixed header */}
        <Header
          useBackButton={true}
          onBack={() => navigate(-1)}
          title='New Post'
          actionType={'publish'}
          actionTitle={id ? 'modify' : 'Publish'}
          onAction={() => (id ? postModify() : publish())}
        />
        <div className={styles.contentContainer}>
          {/* Country Selection Tabs with dynamic country text */}
          <Tabs
            icon={<MdChevronRight />}
            title='Select a country'
            text={selectedNation?.nation || ''}
            onClick={() => setOpenNationBottomSheet(true)}
          />

          {/* Topic Selection Tabs with dynamic topic text */}
          <Tabs
            icon={<MdChevronRight />}
            title='Select a topic'
            text={selectedTopic || 'Select a topic'}
            onClick={() => setOpenTopicsBottomSheet(true)}
          />

          {/* Title and Content Inputs */}
          <TextEditor
            title={title}
            content={content}
            onTitleChange={setTitle}
            onContentChange={setContent}
          />
        </div>
      </div>
      <Sheet
        isOpen={isOpenNationBottomSheet}
        detent='content-height'
        onClose={() => setOpenNationBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '54.7rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Choose country</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenNationBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {nationData
                .filter((e) => e.use === true && e.disableWhenDuplicate === false)
                .map((e) => (
                  <div
                    key={e.nation}
                    className={styles['sheet-terms-content']}
                    onClick={() => selectNation(e.nation, e.abb)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={selectedNation?.nation === e.nation ? selectedIcon : unselectedIcon}
                        alt=''
                        style={{ marginRight: '0.5rem' }}
                      />
                      <div>{e.nation}</div>
                    </div>
                  </div>
                ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenNationBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>

      <Sheet
        isOpen={isOpenTopicsBottomSheet}
        detent='content-height'
        onClose={() => setOpenTopicsBottomSheet(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '39.1rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Select a topic</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenTopicsBottomSheet(false)}
              />
            </div>
            <Sheet.Scroller>
              {topicData[0].topics.map((topic, index) => (
                <div
                  key={index} // 고유한 index 사용
                  className={styles['sheet-terms-content']}
                  onClick={() => selectTopics(topic)} // 각 주제 클릭 시 호출
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={selectedTopic === topic ? selectedIcon : unselectedIcon}
                      alt=''
                      style={{ marginRight: '0.5rem' }}
                    />
                    <div>{topic}</div> {/* 주제 표시 */}
                  </div>
                </div>
              ))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenTopicsBottomSheet(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </>
  );
};

export default Page;
