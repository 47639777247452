import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import useGlobalStore from '../../stores/globalStore';
import styles from './MyInfo.module.css';
import { Sheet } from 'react-modal-sheet';
import closeIcon from '../../assets/images/ic_close.png';
import profileDefaultIcon from '../../assets/images/ic_profile_default.png';
import cameraIcon from '../../assets/images/ic_camera.png';
import ReactCrop, { Crop } from 'react-image-crop';
import './ReactCrop.css';
import useToast from '../../hook/useToast'; // 토스트 훅 추가
import uploadService from '../../services/uploadService';
import { User } from '../../models/user';
import authService from '../../services/authService';
import { MateVerifyStatus } from '../../constants/constants';

function MyInfo() {
  const navigate = useNavigate();
  const { user, setUser } = useGlobalStore();
  const [openUpload, setOpenUpload] = useState(false);
  const toast = useToast(); // 토스트 훅 초기화
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const openUploadSheet = () => {
    setOpenUpload(!openUpload); // 현재 상태의 반대로 변경
  };
  const [file, setFile] = useState<any>(null);
  const [tempUser, setTempUser] = useState<User | null>(null);

  const [src, setSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });

  useEffect(() => {
    setTempUser(JSON.parse(JSON.stringify(user)));
  }, []);

  async function uploadFile() {
    let formData = new FormData();
    formData.append('file', file);
    const uploadFileName: string = await uploadService.addImage('user', formData);
    return uploadFileName;
  }

  function deleteProfileThumbnail() {
    if (!tempUser) {
      toast('error', 'Temporary user data is missing.', false);
      return;
    }
    const updatedUser = { ...tempUser!, profile_image: '' };
    setTempUser(updatedUser);
    setUser(updatedUser); // 전역 상태 업데이트
    setOpenUpload(false);
  }

  async function done() {
    if (!tempUser) {
      toast('error', 'User data is missing', false);
      return;
    }

    // 프로필 이미지가 있으면 업로드 처리.
    let uploadFileName = '';
    if (file != null) {
      uploadFileName = await uploadFile();
    }

    // 프로필 저장 처리.
    const data: any = await authService.modifyProfile(
      tempUser!.email,
      tempUser!.country_code,
      tempUser!.country_name,
      tempUser!.timezone,
      tempUser!.language,
      tempUser!.category,
      tempUser!.job,
      tempUser!.company,
      tempUser!.annual.toString(),
      tempUser!.introduce_message,
      uploadFileName, //tempUser!.profileImage,
      tempUser!.notification_type
    );
    // console.log('Sending profile data:', requestData);
    if (!data || data['type'] !== 'success') {
      toast('error', 'Something went wrong while signing up', false);
      return;
    }

    setUser(data['user']);
    navigate(-2);
  }

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (file) {
      setTempUser({
        ...tempUser!,
        profile_image: URL.createObjectURL(file),
      });
    }
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(e.target.files[0]);

      if (file.size > 200 * 1024 || !['image/jpeg', 'image/png'].includes(file.type)) {
        // 200KB 체크
        toast(
          'error',
          'Only JPEG or PNG files under 200KB are allowed. Please check the file format.',
          false
        );
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result as string));
      reader.readAsDataURL(e.target.files[0]);
      setShowPopup(true);
    }
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop: Crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (newCrop: Crop) => {
    setCrop(newCrop);
  };

  const makeClientCrop = async (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const fileName = file ? file.name : 'newFile.jpeg'; // 파일 이름이 없으면 기본 파일명 설정
      const croppedBlob = await getCroppedBlob(imageRef.current, crop, fileName);
      setCroppedImageUrl(URL.createObjectURL(croppedBlob)); // 미리보기용 URL 생성
      setFile(croppedBlob); // Blob을 파일로 설정
    }
  };

  const getCroppedBlob = (image: HTMLImageElement, crop: Crop, fileName: string): Promise<File> => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        const file = new File([blob], fileName, { type: 'image/jpeg' });
        resolve(file);
      }, 'image/jpeg');
    });
  };

  const uploadSuccess = () => {
    done();
    setShowPopup(false);
    setOpenUpload(false);
    toast('success', 'Profile updated successfully', false);
  };

  return (
    <>
      {showPopup ? (
        <>
          <div className={styles['image-header']}>
            <Header
              useBackButton={false}
              useCloseButton={true}
              onBack={() => navigate(-1)}
              actionType='signup'
              actionTitle={'Done'}
              onAction={uploadSuccess}
            />
          </div>

          <div className={styles['image-container']}>
            {src && (
              <ReactCrop
                crop={crop}
                ruleOfThirds
                onComplete={onCropComplete}
                onChange={onCropChange}
              >
                <img
                  src={src}
                  alt='Source'
                  onLoad={(e) => onImageLoaded(e.currentTarget)}
                />
              </ReactCrop>
            )}
          </div>
        </>
      ) : (
        <div
          className={styles['container']}
          style={{ position: 'relative' }}
          id='container'
        >
          <Header
            onBack={() => navigate(-1)}
            title='My profile'
            actionType='signup'
            actionTitle={'Modify'}
            onAction={() => navigate('/my/myInfoAuth')}
          />
          {user?.mate != undefined && user?.mate!.verify_status != MateVerifyStatus.WAIT && (
            <div className={styles['mate-tag']}>Mate</div>
          )}
          <div className={styles['profile-image-container']}>
            <div className={styles['profile-image-thumbnail']}>
              <img
                onClick={openUploadSheet}
                src={
                  croppedImageUrl // 크롭된 이미지를 사용
                    ? croppedImageUrl
                    : user?.profile_image !== ''
                    ? `${process.env.REACT_APP_STORE_ADDRESS}/${user?.profile_image}`
                    : profileDefaultIcon
                }
                alt=''
              />
              <div
                className={styles['image-upload']}
                onClick={openUploadSheet}
              >
                <img
                  src={cameraIcon}
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className={styles['profile-nickname']}>{user?.nickname}</div>
          {user?.introduce_message !== '' && (
            <div className={styles['profile-introduce-message']}>{user?.introduce_message}</div>
          )}
          <div style={{ marginBottom: '2.8rem' }} />
          <div className={styles['divider']} />
          <div className={styles['label-container']}>
            <div className={styles['label-title']}>Country</div>
            <div className={styles['label-content']}>{user?.country_name}</div>
          </div>
          <div className={styles['label-container']}>
            <div className={styles['label-title']}>Time Zone</div>
            <div className={styles['label-content']}>{user?.timezone}</div>
          </div>
          <div className={styles['label-container']}>
            <div className={styles['label-title']}>Job category</div>
            <div className={styles['label-content']}>{user?.job}</div>
          </div>
          <div className={styles['label-container']}>
            <div className={styles['label-title']}>Language</div>
            <div className={styles['label-content']}>{user?.language.join(', ') || '-'}</div>
          </div>
          <div className={styles['label-container']}>
            <div className={styles['label-title']}>Company</div>
            <div className={styles['label-content']}>{user?.company || '-'}</div>
          </div>
          <div className={styles['label-container']}>
            <div className={styles['label-title']}>Years of Experience</div>
            <div className={styles['label-content']}>
              {user?.annual !== 0 ? `${user?.annual} Years` : '-'}
            </div>
          </div>
          <input
            type='file'
            accept='image/*'
            onChange={onSelectFile}
            ref={fileInputRef} // ref를 연결
            style={{ display: 'none' }} // input은 숨김 처리
          />
          <Sheet
            isOpen={openUpload}
            detent='content-height'
            onClose={() => setOpenUpload(false)}
            mountPoint={document.getElementById('container')!}
            style={{ position: 'absolute' }}
          >
            <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '17.3rem' }}>
              <Sheet.Content>
                <div className={styles['sheet-terms-container']}>
                  <span className={styles['sheet-terms-title']}>Edit Profile Photo</span>
                  <img
                    src={closeIcon}
                    alt=''
                    onClick={() => setOpenUpload(false)}
                  />
                </div>
                <div
                  className={styles['sheet-terms-content']}
                  onClick={() => fileInputRef.current?.click()} // 텍스트 클릭 시 input 클릭
                >
                  {'Choose from Album'}
                </div>
                <div
                  className={styles['sheet-terms-content']}
                  onClick={() => deleteProfileThumbnail()}
                >
                  {'Delete Profile Photo'}
                </div>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop
              onTap={() => setOpenUpload(false)}
              style={{ position: 'relative' }}
            />
          </Sheet>
          {/* {croppedImageUrl && (
            <img
              alt='Crop'
              style={{ maxWidth: '100%' }}
              src={croppedImageUrl}
            />
          )} */}
        </div>
      )}
    </>
  );
}

export default MyInfo;
