import { ReportType } from '../constants/constants';
import { Coupon } from '../models/coupon';
import { NotificationModel } from '../models/notification';
import { Mate } from '../models/mate';
import { get, post, put, destroy } from '../utils/apiClient';
import { PurchaseHistory } from '../models/purchaseHistory';

//사용자 관련 API (프로필, 내 정보 수정 등)
class UserService {
  public async readNotification(id: number): Promise<string> {
    //모든 알림 읽기 처리
    try {
      const response = await put('/user/readAllNotification', { id });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return '';
  }

  public async readAllNotification(): Promise<string> {
    //모든 알림 읽기 처리
    try {
      const response = await put('/user/readAllNotification');
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return '';
  }

  public async getNotificationCount(): Promise<number> {
    //알림 카운트 가져오기
    try {
      const response = await get('/user/getNotificationCount');
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return 0;
  }

  public async getNotificationList(): Promise<NotificationModel[]> {
    //알림 목록 가져오기
    try {
      const response = await get('/user/notificationList');
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return [];
  }

  public async getPurchaseHistory(
    page: number,
    count: number,
    startDate: Date | string,
    endDate: Date | string
  ): Promise<PurchaseHistory[]> {
    //구매내역 목록 가져오기
    try {
      const response = await get(
        `/user/purchaseHistory?page=${page}&count=${count}&startDate=${startDate}&endDate=${endDate}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return [];
  }

  public async updateNotification(type: string, on: boolean): Promise<string> {
    //타입에 따른 알림 업데이트.
    try {
      const response = await put('/user/updateNotification', {
        type,
        on,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return '';
  }

  public async updateNotificationType(notificationType: string[]): Promise<string> {
    //알림 방식 업데이트.
    try {
      const response = await put('/user/updateNotificationType', { notificationType });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return '';
  }

  public async updateTranslateLanguageCode(languageCode: string): Promise<string> {
    //번역 언어 업데이트.
    try {
      const response = await put('/user/updateTranslateLanguageCode', { languageCode });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return '';
  }

  public async getHistory(): Promise<string[]> {
    //최근 검색어 요청.
    try {
      const response = await get(`/user/searchHistory`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return [];
  }

  public async addHistory(text: string): Promise<void> {
    //최근 검색어 추가.
    try {
      const response = await post(`/user/searchHistory`, { text: text });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  public async deleteHistory(text: string): Promise<void> {
    //특정 최근 검색어 지우기.
    try {
      const response = await destroy(`/user/searchHistory`, { data: { text: text } });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  public async deleteHistoryAll(): Promise<void> {
    //최근 검색어 모두 지우기.
    try {
      const response = await destroy(`/user/searchHistoryAll`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
  // 북마크 추가
  public async addMateFavorite(mateId: number): Promise<boolean> {
    try {
      const response = await post(`/user/addMateFavorite`, { mateId });

      return response.data;
    } catch (err) {
      console.error('북마크 추가 오류:', err); // 구체적인 오류 메시지 출력
      return false; // 실패 시 false 반환
    }
  }
  // 북마크 제거
  public async deleteMateFavorite(mateId: number): Promise<boolean> {
    try {
      const response = await destroy(`/user/deleteMateFavorite`, {
        data: { mateId },
      });

      return response.data;
    } catch (err) {
      console.error('북마크 제거 오류:', err); // 구체적인 오류 메시지 출력
      return false; // 실패 시 false 반환
    }
  }
  //쿠폰 리스트
  public async couponList(sortType: string): Promise<Coupon[] | undefined> {
    try {
      const response = await get(`/user/couponList?sortType=${sortType}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return undefined;
  }
  // 신고 하기
  public async profileReport(
    reportType: ReportType,
    referenceId: number,
    reason: string[]
  ): Promise<ReportType | undefined> {
    try {
      const response = await post(`/user/profile-report`, { reportType, referenceId, reason });
      return response.data;
    } catch (err) {
      return undefined; // 실패 시 false 반환
    }
  }
}

const userService = new UserService();
export default userService;
