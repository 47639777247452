import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LinkedinInput.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import TextField from '../../components/element/TextField';
import useToast from '../../hook/useToast';
import useGlobalStore from '../../stores/globalStore';
import mateService from '../../services/mateService';
import { MateVerifyStatus } from '../../constants/constants';

function MateLinkedinInput() {
  const toast = useToast();
  const navigate = useNavigate();
  const { mate, setMate } = useGlobalStore();
  const [linkedinUrl, setLinkedinUrl] = useState<string>('');

  useEffect(() => {
    if (mate!.linkedin_url !== undefined) {
      setLinkedinUrl(mate!.linkedin_url);
    }
  }, []);

  function skip() {
    setMate({ ...mate!, linkedin_url: '' });
    navigate('/mate/introduce');
    //navigate('/mate/timezone');
  }
  async function next() {
    if (linkedinUrl === '') {
      toast('error', 'Enter your LinkedIn.');
      return;
    }

    setMate({ ...mate!, linkedin_url: linkedinUrl });
    mate!.linkedin_url = linkedinUrl;

    //프로필 저장 처리.
    let data: any;
    if (mate!.verify_status === undefined || mate!.verify_status === MateVerifyStatus.TEMP) {
      mate!.verify_status = MateVerifyStatus.WAIT;
    }
    data = await mateService.saveMateProfile(mate!);

    if (!data || data['type'] !== 'success') {
      toast('error', 'Profile creation failed. Please try again', false);
      return;
    }

    setMate(data['mate']); //업데이트된 데이터로 변경.

    navigate('/mate/profileComplete');
    //navigate('/mate/timezone');
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigate(-1)} />
      <div className={styles['title-text']}>Let Nailedit Create Mate Profile</div>
      <div className={styles['description-text']}>
        {
          'Share your LinkedIn profile then Nailedit will\r\ncreate your Mate profile! You can edit it anytime'
        }
      </div>
      <div style={{ marginTop: '3.2rem' }} />
      <div className={styles['label-text']}>Linkedin link</div>
      <div className={styles['input-field-container']}>
        <TextField
          name='address'
          type='text'
          maxLength={60}
          value={linkedinUrl}
          enable={true}
          placeholder='Add url'
          onChange={(event) => setLinkedinUrl(event.target.value)}
        />
      </div>
      <div style={{ marginTop: '3.2rem' }} />
      <Button
        marginHorizontalRem={2.0}
        active={true}
        onClick={() => next()}
      >
        Next
      </Button>
      <div
        className={styles['skip-text']}
        onClick={() => skip()}
      >
        I’ll write my own
      </div>
    </div>
  );
}

export default MateLinkedinInput;
