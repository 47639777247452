import React, { useState } from 'react';
import { Sheet } from 'react-modal-sheet';
import { useLocation, useNavigate } from 'react-router-dom';
import closeIcon from '../../assets/images/ic_close.png';
import Header from '../../components/layout/Header';
import styles from './MateProfile.module.css';
import check from '../../assets/images/ic_checked.png';
import unCheck from '../../assets/images/ic_unchecked.png';
import Button from '../../components/element/Button';
import profile from '../../assets/images/ic_profile_default.png';
import userService from '../../services/userService';
import { ReportType } from '../../constants/constants';
import { Report } from '../../models/Report';
import useToast from '../../hook/useToast'; // 토스트 훅 추가

const reasons = [
  'Sexual harassment or assault',
  'Abusive language or behavior',
  'Fraud or financial demands',
  'Excessive invasion of privacy',
  'Threatening behavior or coercion',
  'Promotion of illegal substances',
  'Behavior unrelated to meeting',
  'Continuous inappropriate contact',
  'Providing false information or false identity',
  'Violence or hate speech',
  'Service refusal based on discrimination',
  'Prefer not to specify',
  'Other',
];

function MateProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const [openReport, setOpenReport] = useState(false);
  // const [selected, setSelected] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const openReportSheet = () => {
    setOpenReport(!openReport); // 현재 상태의 반대로 변경
  };
  const toast = useToast(); // 토스트 훅 초기화

  const toggleReason = (reason: string) => {
    setSelectedReasons((prev) =>
      prev.includes(reason) ? prev.filter((r) => r !== reason) : [...prev, reason]
    );
  };

  const mateData = location.state;
  const profileReport = async () => {
    try {
      const response = await userService.profileReport(
        ReportType.MATE,
        mateData.id,
        selectedReasons
      );
      if (response) {
        navigate('/mate/reportComplete');
      } else {
        toast('error', 'already reported.', false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={styles['container']}
      style={{ position: 'relative' }}
      id='container'
    >
      <Header
        useBackButton={true}
        onBack={() => navigate(-1)}
        title={'Profile'}
        useReportButton={true}
        onReportAction={openReportSheet}
      />
      <div className={styles['profile-container']}>
        <img
          src={
            mateData?.mate?.profile_image
              ? `${process.env.REACT_APP_STORE_ADDRESS}/${mateData.mate.profile_image}`
              : mateData?.profile_image
              ? `${process.env.REACT_APP_STORE_ADDRESS}/${mateData.profile_image}`
              : profile
          }
          alt='Profile'
        />
        <div className={styles['profile-nickname']}>
          {mateData?.user?.nickname || mateData?.nickname}
        </div>
        <div className={styles['profile-info']}>{mateData.introduce_message}</div>
      </div>
      <div className={styles['profile-type-container']}>
        <div className={styles['profile-type']}>
          <span>Country</span>
          <span>Time Zone</span>
          <span>Field/Role</span>
          <span>Language</span>
          <span>Current Workplace</span>
          <span>Years of experience</span>
        </div>
        <div className={styles['profile-type-data']}>
          <span>{mateData?.user?.country_name || mateData?.country_name}</span>
          {/* 리뷰 디테일 버전 생성 해야함 */}
          <span>{mateData?.user?.timezone || mateData?.timezone}</span>
          <span>{mateData?.user?.job || mateData?.job}</span>
          <span>{mateData?.user?.language || mateData?.language}</span>
          <span>{mateData?.user?.company || mateData?.company}</span>
          <span>{mateData?.user?.annual || mateData?.annual} years</span>
        </div>
      </div>

      <Sheet
        isOpen={openReport}
        detent='content-height'
        onClose={() => setOpenReport(false)}
        mountPoint={document.getElementById('container')!}
        style={{ position: 'absolute' }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '62.7rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Stop seeing content from this user?</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenReport(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <div className={styles['report-title']}>
                Reporting Reasons (Select all that apply)
              </div>
              {reasons.map((reason) => (
                <div
                  key={reason}
                  className={styles['report-select']}
                  onClick={() => toggleReason(reason)}
                >
                  <img
                    src={selectedReasons.includes(reason) ? check : unCheck}
                    alt=''
                  />
                  {reason}
                </div>
              ))}
              <div className={styles['report-description']}>
                {
                  'Reporting will automatically block the user and\r\nmay lead to service restrictions after review.'
                }
                <li>"Prefer not to specify" selection: Simple block only</li>
              </div>
              <div className={styles['button-container']}>
                <Button
                  width='unset'
                  marginHorizontalRem={0.4}
                  active={true}
                  onClick={profileReport}
                >
                  Report and Block
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenReport(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
    </div>
  );
}

export default MateProfile;
