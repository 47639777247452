import { request } from 'http';
import { destroy, get, post, put } from '../utils/apiClient';

class CommunityService {
  // 커뮤니티 게시물 불러오기
  public async getList(page: number, count: number, countryCode?: string, category?: string) {
    try {
      const response = await get(
        `/community/list?page=${page}&count=${count}&${
          countryCode ? `countryCode=${countryCode}&` : ''
        }${category ? `category=${category}` : ''}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 북마크게시물
  public async getFavoriteList() {
    try {
      const response = await get(`/community/favoriteList`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 특정 커뮤니티 게시물 불러오기
  public async getTargetList(id: number) {
    try {
      const response = await get(`/community/get?id=${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 커뮤니티 게시물 작성
  public async addPost(
    countryCode: string,
    countryName: string,
    category: string,
    subject: string,
    content: string
  ) {
    try {
      const response = await post(`/community/add`, {
        countryCode,
        countryName,
        category,
        subject,
        content,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 커뮤니티 댓글 작성
  public async addReply(
    user_nickname: string,
    community_id: number,
    reply: string,
    mention_user_id?: number,
    mention_user_nickname?: string
  ) {
    try {
      const response = await post(`/community/addReply`, {
        user_nickname,
        community_id,
        reply,
        mention_user_id,
        mention_user_nickname,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 커뮤니티 게시물 수정
  public async modifyPost(
    id: number,
    countryCode: string,
    countryName: string,
    category: string,
    subject: string,
    content: string
  ) {
    try {
      const response = await put(`/community/modify`, {
        id,
        countryCode,
        countryName,
        category,
        subject,
        content,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 커뮤니티 게시물 삭제
  public async deletePost(id: number) {
    try {
      const response = await destroy(`/community/delete`, { data: { id } });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  // 커뮤니티 게시물 삭제
  public async deleteReply(id: number) {
    try {
      const response = await destroy(`/community/deleteReply`, { data: { id } });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  // 커뮤니티 게시글/댓글 신고
  public async report(reportType: string, referenceId: number, reason: string[]) {
    try {
      const response = await post(`/community/report`, {
        reportType: reportType,
        referenceId: referenceId,
        reason: reason,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  // 커뮤니티 북마크 추가
  public async addFavorite(communityId: number): Promise<boolean> {
    try {
      const response = await post(`/community/addFavorite`, { communityId });
      return response.data;
    } catch (err) {
      console.error('북마크 추가 오류:', err);
      return false; // 실패 시 false 반환
    }
  }

  // 커뮤니티 북마크 삭제
  public async deleteFavorite(communityId: number): Promise<boolean> {
    try {
      const response = await destroy(`/community/deleteFavorite`, { data: { communityId } });
      return response.data;
    } catch (err) {
      console.error('북마크 삭제 오류:', err);
      return false; // 실패 시 false 반환
    }
  }
}

const communityService = new CommunityService();
export default communityService;
