import styles from './UpcomingMeetingCard.module.css';
import Button from '../../../components/element/Button';

import pinIcon from '../../../assets/images/ic_pin.png';
import dateIcon from '../../../assets/images/ic_date.png';
import { addMinutes, differenceInDays, differenceInMinutes, format, startOfDay } from 'date-fns';
import timezoneData from '../../../assets/data/timezone.json';
import dayjs from 'dayjs';
import useGlobalStore from '../../../stores/globalStore';

export enum MeetingType {
  COFFEECHAT,
  INTERVIEW,
  REVIEW,
}

interface UpcomingMeetingCardProps {
  time: number;
  type: string;
  name: string;
  productTime: number;
  mateTimezone: string;
  company: string;
  nickName: string;
  meetingTime: Date;
  link: string;
  onAction?: () => void;
  onSecondaryAction?: () => void;
  onthirdAction?: () => void;
}

const UpcomingMeetingCard: React.FC<UpcomingMeetingCardProps> = ({
  type,
  name,
  time,
  company,
  mateTimezone,
  nickName,
  productTime,
  meetingTime,
  link,
  onthirdAction,
  onAction,
  onSecondaryAction,
}) => {
  const {user} = useGlobalStore()
  const isJoinActive = (): boolean => {
    if (!meetingTime) return false;

    const currentTime = new Date();
    const meetingStartTime = new Date(meetingTime);

    const tenMinutesBefore = addMinutes(meetingStartTime, -10);

    return currentTime >= tenMinutesBefore && currentTime < meetingStartTime;
  };

  // meetingTime을 "MM/DD(요일)" 형식에서 Date 객체로 변환하여 날짜 차이를 계산
  const getMeetingDayDifference = (meetingTime: Date): string => {
    const today = startOfDay(new Date()); // 오늘 날짜의 00:00:00
    const meetingDate = startOfDay(meetingTime); // 미팅 날짜의 00:00:00

    // 날짜 차이를 계산
    const diffDays = differenceInDays(meetingDate, today);

    if (diffDays === 0) {
      return 'Today!';
    } else if (diffDays === 1) {
      return 'Tomorrow!';
    } else if (diffDays === 2) {
      return '2 days!';
    } else {
      return `${diffDays} days left`;
    }
  };

  const transformTimezone = (tzCode: string): string => {
    if (!tzCode.includes(', ')) return tzCode;
    const parts = tzCode.split(', ');
    return `${parts[1]}/${parts[0]}`;
  };

  const matchedUserTimezone = timezoneData.find((tz) => tz.label === user?.timezone)!;
  const transUserTimezone = transformTimezone(matchedUserTimezone.tzCode);

  return (
    <div>
      <div className={styles['card-container']}>
        <div className={styles['card-row']}>
          <div className={styles['card-title']}>
            {time}min {type}
          </div>
          <div className={styles['card-tag']}>
            {/* meeting_date의 시간이 오늘이면 오늘이에요! 출력 */}
            <span>{getMeetingDayDifference(meetingTime)}</span>
          </div>
        </div>
        <div className={styles['card-row']}>
          <div className={styles['card-profile-name']}>{nickName}</div>
          <div className={styles['card-company']}>{company}</div>
        </div>
        <div className={styles['card-row']}>
          <img
            src={dateIcon}
            alt=''
          />
          <div className={styles['card-description']}>
            {meetingTime ? (
              `${dayjs(meetingTime).tz(transUserTimezone).format('MMM DD (ddd) HH:mm A')} ~ ${dayjs(
                meetingTime
              )
                .tz(transUserTimezone)
                .add(productTime, 'minute')
                .format('HH:mm A')}`
            ) : (
              <span style={{ color: 'gray' }}>No Meeting Scheduled</span>
            )}
          </div>
        </div>
        <div className={styles['card-row']}>
          <img
            src={pinIcon}
            alt=''
          />
          <div className={styles['card-description']}>Meet Link</div>
          {/*
          <div
            className={styles['card-copy']}
            onClick={onthirdAction}
          >
            Copy Link
          </div>
          */}
          <div className={styles['card-copy']}>{link === '' ? 'Waiting' : 'Ready'}</div>
        </div>
        <div className={styles['card-row']}>
          <Button
            width={'100vw'}
            height={'4rem'}
            activeFontSize='1.4rem'
            activeFontWeight={600}
            active={true}
            onClick={onAction}
          >
            Check Details
          </Button>
          <div style={{ marginLeft: '0.7rem' }} />
          <Button
            width={'100vw'}
            height={'4rem'}
            activeFontSize='1.4rem'
            activeFontWeight={600}
            active={link !== '' && isJoinActive()}
            onClick={() => (link !== '' && isJoinActive() ? onSecondaryAction!() : null)}
          >
            Join
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpcomingMeetingCard;
