import React, { useEffect, useState } from 'react';
import styles from './Comment.module.css';
import MdDeleteIcon from '../../../../assets/images/ic_clear.png';
import MdReplyIcon from '../../../../assets/images/ic_reply_outline.svg';
import MdReportIcon from '../../../../assets/images/ic_report.svg';
import useGlobalStore from '../../../../stores/globalStore';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CommunityReply } from '../../../../models/communityReply';
import { format } from 'date-fns';
import useToast from '../../../../hook/useToast';
import communityService from '../../../../services/communityService';
import { Sheet } from 'react-modal-sheet';
import Button from '../../../../components/element/Button';
import Popup from '../../../../components/popup/Popup';
import { ReportType } from '../../../../constants/constants';
import closeIcon from '../../../../assets/images/ic_close.png';
import reportCompleted from '../../../../assets/images/ic_reportCompleted.png';
import check from '../../../../assets/images/ic_checked.png';
import unCheck from '../../../../assets/images/ic_unchecked.png';
import MdReport from '../../../../assets/images/ic_report.svg';
const reasons = ['Offensive content', 'Disclosure of personal info', 'Unauthorized ads/spam'];

type CommentProps = {
  replies: CommunityReply[]; // replies 배열 타입
  setMentionUserId: (userId: number) => void;
  deleteReply: (deletedReplyId: number) => void;
};

function Comment({ replies, setMentionUserId, deleteReply }: CommentProps) {
  const { id } = useParams();
  const { user } = useGlobalStore();
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [openReport, setOpenReport] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [targetReplyId, setTargetReplyId] = useState<number | null>(null); // 삭제 대상 댓글 ID

  useEffect(() => {
    // 화면 크기 변경 감지
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // 초기 상태 설정
    handleResize();

    // 리스너 등록
    window.addEventListener('resize', handleResize);

    // 리스너 정리
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleReason = (reason: string) => {
    setSelectedReasons((prev) =>
      prev.includes(reason) ? prev.filter((r) => r !== reason) : [...prev, reason]
    );
  };

  // 커뮤니티 댓글글 삭제
  const deleteReplyBtn = async () => {
    if (!targetReplyId) return; // id가 없으면 삭제하지 않음

    try {
      const response = await communityService.deleteReply(targetReplyId);
      if (response) {
        deleteReply(targetReplyId);
        toast('success', 'Reply deleted successfully', false);
        setOpenDelete(false);
      } else {
        toast('error', 'Failed to delete reply', false);
      }
    } catch (error) {
      console.error('Failed to delete reply:', error);
      toast('error', 'An error occurred while deleting the reply', false);
    }
  };

  // 신고 처리 함수
  const handleReport = async (reportType: ReportType, referenceId: number) => {
    if (selectedReasons.length === 0) {
      alert('Please select a reason for reporting.');
      return;
    }

    try {
      const response = await communityService.report(reportType, referenceId, selectedReasons);
      if (response) {
        toast('success', 'Your report has been submitted.', false);
        setOpenReport(false);
        setShowPopup(true);
      } else {
        toast('error', 'This is a duplicate report.', false);
      }
    } catch (error) {
      console.error('Error while reporting:', error);
    }
  };

  return (
    <>
      {replies.map((reply, index) => (
        <div
          key={reply.id}
          className={styles.commentContainer}
        >
          <div className={`${styles.group}`}>
            <span className={styles.nickname}>
              {user?.id === reply.user_id
                ? `${reply.user_nickname} = Me` // 현재 사용자의 댓글
                : replies.find((r) => r.user_id === reply.user_id && r.id! < reply.id!)
                ? `${reply.user_nickname} = ${
                    replies.find((r) => r.user_id === reply.user_id && r.id! < reply.id!)
                      ?.user_nickname
                  }`
                : `${reply.user_nickname}`}
            </span>
            <span className={styles.information}>
              {format(new Date(reply.created_date), 'MMM dd, yyyy')}
            </span>
          </div>

          <div className={`${styles.article}`}>
            {reply.blocked ? (
              <>
                <div className={styles['reply']}>{'This comment was hidden due to a report.'}</div>
              </>
            ) : (
              <>
                <div className={styles['reply']}>
                  {reply.mention_user_nickname && (
                    <span className={styles['mention']}>{`@${reply.mention_user_nickname}`}</span>
                  )}
                  {reply.reply}
                </div>
              </>
            )}
          </div>
          {user !== null && (
            <>
              <div className={`${styles.iconGroup}`}>
                <span
                  className={styles.iconwrapper}
                  onClick={() => setMentionUserId(index + 1 || 0)} // 멘션 설정
                >
                  <img
                    src={MdReplyIcon}
                    alt='Reply'
                    className={styles.icon}
                  />
                  Add a comment
                </span>
                <span
                  className={styles.iconwrapper}
                  onClick={() => setOpenReport(true)}
                >
                  <img
                    src={MdReport}
                    alt='Report'
                    className={styles.icon}
                  />
                  Report
                </span>

                {user?.id === reply.user_id && (
                  <span
                    className={styles.iconwrapper}
                    onClick={() => {
                      setTargetReplyId(reply.id!); // 삭제 대상 댓글 ID 설정
                      setOpenDelete(true); // 삭제 모달 열기
                    }}
                  >
                    <img
                      src={MdDeleteIcon}
                      alt='Report'
                      className={styles.icon}
                    />
                    Delete
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      ))}
      <Sheet
        isOpen={openDelete}
        detent='content-height'
        onClose={() => setOpenDelete(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container
          style={{
            borderRadius: '12px 12px 0px 0px',
            height: '15.3rem',
          }}
        >
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Want to delete the comment?</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenDelete(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <div className={styles['button-container']}>
                <Button
                  width='100%'
                  inactiveTextColor='black'
                  height='4.8rem'
                  onClick={() => setOpenDelete(false)}
                >
                  Close
                </Button>
                <Button
                  active={true}
                  width='100%'
                  height='4.8rem'
                  onClick={deleteReplyBtn} // 삭제 함수 호출
                >
                  Delete
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenDelete(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>

      <Sheet
        isOpen={openReport}
        detent='content-height'
        onClose={() => setOpenReport(false)}
        mountPoint={document.getElementById('container')!}
        style={{
          position: isMobile ? 'fixed' : 'absolute', // 화면 크기에 따라 동적으로 변경
        }}
      >
        <Sheet.Container style={{ borderRadius: '12px 12px 0px 0px', height: '30.5rem' }}>
          <Sheet.Content>
            <div className={styles['sheet-terms-container']}>
              <span>Block this post?</span>
              <img
                src={closeIcon}
                alt=''
                onClick={() => setOpenReport(false)}
              />
            </div>
            <Sheet.Scroller className={styles['sheet-terms-content']}>
              <div className={styles['text']}>{'Choose a reason (multiple choices allowed)'}</div>
              {reasons.map((reason) => (
                <div
                  key={reason}
                  className={styles['report-select']}
                  onClick={() => toggleReason(reason)}
                >
                  <img
                    src={selectedReasons.includes(reason) ? check : unCheck}
                    alt=''
                  />
                  {reason}
                </div>
              ))}
              <div className={styles['button-container']}>
                <Button
                  width='100%'
                  inactiveTextColor='black'
                  height='4.8rem'
                  onClick={() => setOpenReport(false)}
                >
                  Cancel
                </Button>
                <Button
                  active={true}
                  width='100%'
                  height='4.8rem'
                  // onClick={() => deletePostBtn()}
                  onClick={() => handleReport(ReportType.COMMUNITY_REPLY, Number(id))}
                >
                  Report and Block
                </Button>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => setOpenReport(false)}
          style={{ position: 'relative' }}
        />
      </Sheet>
      <Popup
        show={showPopup}
        width={'27.4rem'}
        height={'34rem'} /*onClose={() => setShowPopup(false)}*/
      >
        <div className={styles['popup-container']}>
          <img
            className={styles['popup-image']}
            src={reportCompleted}
            alt=''
          />
          <div className={styles['popup-title']}>Reported</div>
          <div className={styles['popup-content']}>
            {
              'Repeated reports of the same issue\r\nwill hide the post and may ban the\r\nuser according to community rules.'
            }
          </div>
          <div
            className={styles['popup-button']}
            onClick={() => setShowPopup(false)}
          >
            Okay
          </div>
        </div>
      </Popup>
    </>
  );
}

export default Comment;
