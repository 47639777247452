import { get, post, put, destroy } from '../utils/apiClient';
import { User } from '../models/user';

//인증 관련 API (로그인, 회원가입 등)
class AuthService {
  public async changePassword(email: string, password: string): Promise<any | undefined> {
    try {
      const response = await put('/auth/account/changePassword', {
        email,
        password,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async modifyProfile(
    email: string,
    countryCode: string,
    countryName: string,
    timezoneName: string,
    languages: string[],
    category: string,
    job: string,
    company: string,
    annual: string,
    introduceMessage: string,
    profileImage: string,
    notificationTypes: string[]
  ): Promise<User | undefined> {
    try {
      const response = await put('/auth/account/modifyProfile', {
        email,
        countryCode,
        countryName,
        timezoneName,
        languages,
        category,
        job,
        company,
        annual,
        introduceMessage,
        profileImage,
        notificationTypes,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async register(
    email: string,
    password: string,
    nickName: string,
    birth: string,
    gender: string,
    internationalNumber: string,
    cellPhone: string,
    agreeMarketing: boolean
  ): Promise<User | undefined> {
    try {
      const response = await post('/auth/account/register', {
        email,
        password,
        nickName,
        birth,
        gender,
        internationalNumber,
        cellPhone,
        agreeMarketing,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async login(email: string, password: string): Promise<User | undefined> {
    try {
      const response = await get('/auth/account/login', {
        params: {
          email,
          password,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }

  public async leave(): Promise<boolean> {
    try {
      const response = await destroy('/auth/account/delete');
      return response.data == 'success';
    } catch (err) {
      console.log(err);
    }

    return false;
  }

  public async checkPassword(password: string): Promise<boolean> {
    try {
      const response = await get('/auth/account/checkPassword', {
        params: {
          password,
        },
      });
      return response.data['type'] == 'success';
    } catch (err) {
      console.log(err);
    }

    return false;
  }

  public async checkDuplicateNickName(nickName: string): Promise<boolean> {
    //닉네임 중복 확인.
    try {
      const response = await get('/auth/account/checkDuplicateNickName', {
        params: {
          nickName,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return true;
  }

  public async checkDuplicateEmail(email: string): Promise<boolean> {
    //이메일 중복 확인.
    try {
      const response = await get('/auth/account/checkDuplicateEmail', {
        params: {
          email,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return false;
  }

  public async checkDuplicateCellPhone(
    internationalNumber: string,
    cellPhone: string
  ): Promise<boolean> {
    //휴대폰번호 중복 확인.
    try {
      const response = await get('/auth/account/checkDuplicateCellPhone', {
        params: {
          internationalNumber,
          cellPhone,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return false;
  }

  public async requestEmailVerify(email: string): Promise<void> {
    //이메일 인증번호 요청.
    try {
      await post('/auth/account/requestEmailVerify', {
        email,
      });
    } catch (err) {
      console.log(err);
    }
  }

  public async requestCellPhoneVerify(
    internationalNumber: string,
    cellPhone: string
  ): Promise<void> {
    //휴대폰 인증번호 요청.
    try {
      await post('/auth/account/requestCellPhoneVerify', {
        internationalNumber,
        cellPhone,
      });
    } catch (err) {
      console.log(err);
    }
  }

  public async updateCellPhone(internationalNumber: string, cellPhone: string): Promise<void> {
    //유저 휴대폰 재설정.
    try {
      await put('/auth/account/updateCellPhone', {
        internationalNumber,
        cellPhone,
      });
    } catch (err) {
      console.log(err);
    }
  }

  public async checkVerifyCode(name: string, verifyCode: string): Promise<boolean> {
    //인증번호 확인.
    try {
      const response = await get('/auth/account/checkVerifyCode', {
        params: {
          name,
          verifyCode,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return false;
  }

  public async requestFindId(
    internationalNumber: string,
    cellPhone: string
  ): Promise<string | undefined> {
    //아이디 찾기.
    try {
      const response = await get('/auth/account/findId', {
        params: {
          internationalNumber,
          cellPhone,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }

    return undefined;
  }
  public async myprofile(): Promise<{ type: string; user: User } | null> {
    try {
      const response = await get('/auth/account/myprofile/');
      return response.data; // { type: 'success', user: {...} } 구조
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  // public async myprofile(): Promise<{ user: User[] }> {
  //   try {
  //     const response = await get('/auth/account/myprofile');
  //     return response.data; // { user: User[] } 반환
  //   } catch (err) {
  //     console.log(err);
  //     return { user: [] };
  //   }
  // }
}

const authService = new AuthService();
export default authService;
