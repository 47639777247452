import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Timezone.module.css';
import Header from '../../components/layout/Header';
import Button from '../../components/element/Button';
import ComboBoxButton from '../../components/element/ComboBoxButton';
import EditTimezone from '../my/components/EditTimezone';
import useToast from '../../hook/useToast';
import useGlobalStore from '../../stores/globalStore';
import timezoneData from '../../assets/data/timezone.json';

function MateTimezone() {
  const toast = useToast();
  const navigator = useNavigate();
  const { user, mate, setMate } = useGlobalStore();
  const [selectedTimezoneIndex, setSelectedTimezoneIndex] = useState<number>(0);
  const [showTimeZone, setShowTimeZone] = useState(false);

  useEffect(() => {
    let idx = 0;
    if (mate!.timezone !== undefined && mate!.timezone !== '') {
      idx = timezoneData.findIndex((element) => element['label'] == mate!.timezone);
    } else {
      idx = timezoneData.findIndex((element) => element['label'] == user!.timezone);
    }
    setSelectedTimezoneIndex(idx !== -1 ? idx : 0);
  }, []);

  function goTimeZonePage() {
    setShowTimeZone(true);
  }

  function selectTimezone(timezoneIndex: number) {
    setSelectedTimezoneIndex(timezoneIndex);
    setShowTimeZone(false);
  }

  async function next() {
    setMate({ ...mate!, timezone: timezoneData[selectedTimezoneIndex]['label'] });
    navigator('/mate/meetingTime');
  }

  return (
    <div className={styles['container']}>
      <Header onBack={() => navigator(-1)} />
      <div className={styles['title-text']}>
        {'Please check if\r\nthis is the time zone you prefer'}
      </div>
      <div className={styles['description-text']}>
        {'If not, click to change it.\r\nYour profile will update automatically.'}
      </div>
      <div style={{ marginTop: '3.2rem' }} />
      <div className={styles['label-text']}>
        Time zone / {timezoneData[selectedTimezoneIndex]['label']}
      </div>
      <ComboBoxButton
        marginLeft='2.0rem'
        marginRight='2.0rem'
        paddingLeft='1.6rem'
        paddingRight='0.6rem'
        type='button'
        text={timezoneData[selectedTimezoneIndex]['tzCode']}
        actionButtonTitle='Change'
        onAction={() => goTimeZonePage()}
      />
      <div style={{ marginTop: '3.2rem' }} />
      <Button
        marginHorizontalRem={2.0}
        active={true}
        onClick={() => next()}
      >
        Next: Choose meeting time
      </Button>
      {showTimeZone == true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            width: '100%',
            height: '100vh',
          }}
        >
          <EditTimezone
            onSelect={(timeZoneIndex) => selectTimezone(timeZoneIndex)}
            onCancel={() => setShowTimeZone(false)}
          />
        </div>
      )}
    </div>
  );
}

export default MateTimezone;
